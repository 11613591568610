import React from 'react';
import { useMount } from 'react-use';
import '@/assets/css/global.scss';
import { userModel } from '@/model/user';
import util from '@/util/index';
import WX from '@/util/weChat';
import { useHistory } from 'react-router-dom';

export default function Layout(props: any) {
  /**
   * 注册实例时globalState混入用户信息
   */
  const [model, setModel] = userModel();
  const user = JSON.parse(localStorage.getItem('user') as string);

  useMount(() => {
    if (util.isWechat()) {
      WX.init();
    }
    setModel({ ...model, user })
  })

  const history = useHistory();
  history.listen((location, action) => {
    const unPathName = ['/bbs/detail/', '/article/detail'];
    const isUnPathName = unPathName.some(item => {
      return location.pathname.indexOf(item) > -1;
    });

    if (action === 'POP') {
      if (!isUnPathName) {
        WX.shire();
      }
    }
    // location就是window.location的一个子集
    // action可能的值，"PUSH", "REPLACE", "POP"
  });
  // unlisten();
  return <div id="app">
    {props.children}
  </div>
}

