import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { Header } from '@component/index';
import { Tabs } from 'antd-mobile';
import Celebrity from './Celebrity';
import Reward from './Reward';
import Superior from './Superior';
import { listModel, listProps } from '@/model/list';

const tabs = [
    { title: '名人堂' },
    { title: '高回报' },
    { title: '高手榜' },
];

const ListPage = (props: ConnectProps) => {
    const [value, setValue] = listModel();

    return (
        <div className='page'>
            <Header title='榜单' />
                <Tabs tabs={tabs}
                    tabBarUnderlineStyle={{ backgroundColor: '#ec5d38', height: 1, transform: 'scaleX(0.6)' }}
                    tabBarInactiveTextColor='#9b9b9b'
                    tabBarActiveTextColor='#3c3c3c'
                    initialPage={0}
                    swipeable={false}
                    page={value?.pageIndex}
                    onTabClick={(tab, index: number) => { setValue({ ...value as listProps, pageIndex: index }) }}
                    onChange={(from, index: number) => setValue({ ...value as listProps, pageIndex: index })}
                    renderTab={(tab: { title: React.ReactNode; }) => <span className="font14">{tab.title}</span>}
                >
                    <Celebrity  {...props} />
                    <Reward {...props} />
                    <Superior  {...props} />
                </Tabs>
        </div>
    )
}

export default ListPage;
