import React from 'react';
import { gradeLatest } from '@/services/userServices';
import { useMount, useList } from 'react-use';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { Flex } from 'antd-mobile';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/visualMap';

function UserGrade({ userId }: { userId: string }) {
    const [list, { set }] = useList<any>([]);

    useMount(async () => {
        const result: any = await gradeLatest(userId);
        if (result != null) {
            set(result);
        }
    })

    const _renderCircle = () => {
        const { length }: { length: number } = list;
        if (!list || length === 0) {
            return null
        }
        let red: number = 0;
        let black: number = 0;
        let zou: number = 0;
        for (let i = 0; i < length; i++) {
            if (list[i].result === 1) {
                red++;
            }
            if (list[i].result === 2) {
                black++;
            }
            if (list[i].result === 3) {
                zou++;
            }
        }
        const option = {
            tooltip: {
                show: false,
            },
            //   animation : false,
            color: ['#ed7055', '#7fc47c', '#7e7e7e'],
            series: [
                {
                    type: 'pie',
                    radius: ['80%', '67%'],
                    // avoidLabelOverlap: false,
                    hoverAnimation: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: [
                        { value: red, name: '红' },
                        { value: zou, name: '走' },
                        { value: black, name: '黑' }
                    ]
                }
            ]
        };
        const styles: { wrap: React.CSSProperties, bar: React.CSSProperties } = {
            wrap: {
                position: 'absolute',
                top: '52px',
                left: '38%'
            },
            bar: {
                width: '2px',
                height: '10px',
                marginRight: '3px'
            }
        }
        return <div className='relative'>
            <ReactEchartsCore echarts={echarts} option={option} style={{ height: '150px' }} />
            <div style={styles.wrap}>
                <Flex align='center'>
                    <div style={{ ...styles.bar, backgroundColor: '#ed7055', }}></div>
                    <span className='font9'>红 {parseInt((red / length * 100).toString())}%</span>
                </Flex>
                <Flex align='center' className='mt2 mb2'>
                    <div style={{ ...styles.bar, backgroundColor: '#7fc47c' }}></div>
                    <span className='font9'>走 {parseInt((zou / length * 100).toString())}%</span>
                </Flex>
                <Flex align='center'>
                    <div style={{ ...styles.bar, backgroundColor: '#7e7e7e' }}></div>
                    <span className='font9'>黑 {parseInt((black / length * 100).toString())}%</span>
                </Flex>
                <Flex align='center' className='mr10 mt5'>
                    <span className='font10'>{length}场</span>
                </Flex>
            </div>
        </div>
    }

    const _lineCharts = () => {
        if (list.length <= 0) {
            return null;
        }
        let currentList = list.map((item: any) => {
            if (item.result === 1) {
                // 赢
                return 3;
            }
            if (item.result === 2) {
                // 输
                return 1;
            }
            if (item.result === 3) {
                // 走
                return 2;
            }
            return 0;
        });
        const y = currentList.filter(item => item === 3).length;
        const s = currentList.filter(item => item === 1).length;
        const z = currentList.filter(item => item === 2).length;
        let option: any = {
            //   animation : false,
            xAxis: {
                type: 'category',
                data: currentList,
                axisLabel: '',
                axisTick: {
                    inside: false,
                    length: 0,
                },
                axisLine: {
                    show: false
                }
            },
            yAxis: [{ // 纵轴标尺固定
                type: 'value',
                scale: false,
                max: 4,
                min: 0,
                splitNumber: 3,
                axisTick: {
                    inside: false,
                    length: 0,
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    formatter: function (a: string | number) {
                        const map: any = { 0: '', 1: '输', 2: '走', 3: '赢' }
                        return map[a]
                    }
                }
            }],
            series: [{
                data: currentList,
                type: 'line',
                itemStyle: {
                    normal: {
                        //   color: "pink",//折线点的颜色
                        lineStyle: {
                            color: "#ed7055"//折线的颜色
                        }
                    }
                },
                symbol: 'circle',//折线点设置为实心点
                symbolSize: 8,   //设定实心点的大小
            }],
            visualMap: {
                show: false,
                dimension: 1,
                pieces: [
                    { lte: 1, color: '#7e7e7e' },
                    { lte: 2, color: '#7fc47c' },
                    { lte: 3, color: '#ed7055' },
                ]
            },
            grid: {
                y: 10,
                y2: 20
            },
        }
        return <div>
            <ReactEchartsCore echarts={echarts} option={option} style={{ height: '100px' }} />
            <Flex align='center' justify='between' style={{ transform: 'translateY(-5px)', margin: 0 }}>
                <span className='font11 text-right ml20'>
                    最近{currentList.length}场：{y}赢，{s}输，{z}走
            </span>
                <span className='font11 text-right mr20'>最新</span>
            </Flex>
        </div>
    }

    return (
        <Flex className='c6'>
            <div className='w30'>
                {_renderCircle()}
            </div>
            <div className='w70'>
                {_lineCharts()}
            </div>
        </Flex>
    )
}

export default UserGrade
