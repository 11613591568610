/**
 * @description 用户模块状态
 * @author hayho
 */
import { createGlobalState } from 'react-use';

interface userProps {
  user: any;
}

const user =  JSON.parse(localStorage.getItem('user') || '{}')

const userModel = createGlobalState<userProps>({
  user,
});


export { userModel };
export type { userProps };
