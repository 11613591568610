import React from 'react';


export default function IconElement(url: string) {
    return (<div style={{
      width: '22px',
      height: '22px',
      background: `url(${url}) center center /  21px 21px no-repeat`
    }}
    ></div>)
  }