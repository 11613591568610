import React, { useState } from 'react'
import { getCommentList, getCommentSubList, addCommentSub, addComment, articleApprove } from '@/services/homeServices';
import { useMount, useBoolean } from 'react-use';
import { Flex, Toast, Modal, Button } from 'antd-mobile';
import styles from './ArticleComment.module.scss';
import moment from 'moment';
import util from '@/util/index';

function ArticleComment(props: { id: string }) {
    const id = props.id;
    const [list, setList] = useState<any>([]);
    const [isBottom, setBottom] = useBoolean(false);
    const [focus, setFocus] = useBoolean(false);
    const [text, setText] = useState<any>('');

    useMount(() => {
        getList(null);
    });

    const getList = async (createdAt: string | null) => {
        const result: any = await getCommentList(id, createdAt);
        setList(createdAt ? [...list, ...result] : result);
        setBottom(result.length < 20)
    };

    const subadd = (id: string, index: number) => {
        Modal.prompt(
            '评论回复',
            '请输入评论内容',
            [{ text: '取消' }, {
                text: '确定', onPress: async (comment) => {
                    if (comment === '') return Toast.info('内容不能为空');
                    const result: any = await addCommentSub(id, comment)
                    if (result != null) {
                        let arr = [...list];
                        if (arr[index].comment_sublist) {
                            arr[index].comment_sublist = [result, ...arr[index].comment_sublist];
                        } else {
                            arr[index].comment_sublist = [result];
                        }
                        setList(arr)
                        Toast.info('回复成功', 1);
                    }
                }
            }],
            'default')
    };

    const commentSublist = async (id: string, articleId: string, index: number) => {
        const result: any = await getCommentSubList(id, articleId);
        if (result != null) {
            if (result.length === 0) {
                Toast.info('暂无回复', 1);
                return;
            }
            let l = [...list];
            l[index].comment_sublist = result;
            setList(l);
        }

    };

    const commentAdd = async () => {
        if (text.length <= 0) {
            return Toast.info('评论内容不能为空', 100);
        }
        let result: any = await addComment(id, text);
        if (result != null) {
            let arr = [{
                articleId: result.userInfo._id,
                comment: result.comment,
                createdAt: new Date(),
                status: 200,
                updatedAt: new Date(),
                userId: result.userInfo._id,
                userInfo: result.userInfo,
                zan: [],
                __v: 0,
                _id: result.userInfo._id,
            }, ...list];
            setList(arr);
            setText('');
            Toast.info('回复成功');
        }
    };

    const approve = async () => {
        const result = await articleApprove(id);
        if (result != null) {
            Toast.success('点赞成功')
        }
    }

    return (
        <div style={{ paddingBottom: '45px' }}>
            {list.length === 0 ? noneComment() : <div>
                <Flex align="center" className="mt20 mb20">
                    <div className={styles.row_line}></div>
                    <span className="ml15 mr15 c3 font16 w500">热门评论</span>
                    <div className={styles.row_line}></div>
                </Flex>
                {list.map((item: any, index: number) => {
                    return <Flex key={index} className="mt10" align="start">
                        <div className="w15">
                            <img className={styles.avatar} src={item.userInfo.avatar} alt="avatar" />
                        </div>
                        <div className="ml5 mr20 w85" style={{ flex: 8.5 }}>
                            <Flex justify="between" align="start">
                                <span className="c3 mt5 w500 font14">{item.userInfo.nick.substr(0, 11)}</span>
                                <Flex justify="start">
                                    <div onClick={() => { subadd(item._id, index) }}>
                                        <img className={styles.icon_msg} src="/images/newlist_ico_msg@2x.png" alt="" />
                                    </div>
                                    <div onClick={() => { Toast.success('点赞成功', 1) }}>
                                        <img className={styles.ico_fabulous} src="/images/article_foot_ico_fabulous@2x.png" alt="" />
                                    </div>
                                </Flex>
                            </Flex>
                            <div className="font14 c3 mt5" style={{ letterSpacing: 1, lineHeight: '16px' }}>{item.comment}</div>
                            <div className="c9 font11 mt5">{moment(item.createdAt).fromNow().replace(' ', '')}</div>
                            <div className={(item.comment_sublist && item.comment_sublist.length > 0) ? styles.wrapComment : ''}>
                            {item.comment_sublist ? item.comment_sublist.map((item2: any, i: number) => {
                                return <div key={i} className='mt5' style={{ color: '#333', fontSize: '14px' }}>
                                  <span className='w500'>{item2.userInfo.nick}</span> 回复:{item2.comment}</div>
                            }) : null}
                            </div>
                            <div onClick={() => {
                                commentSublist(item._id, item.articleId, index)
                            }}>
                                <span style={{ fontSize: 11, color: '#6484BF', marginTop: 5 }}>查看回复&gt;</span>
                            </div>
                        </div>
                    </Flex>
                })}

                <div onClick={() => {
                    isBottom ? Toast.info('没有更多评论了~', 1) : getList(list[list.length - 1].createdAt)
                }}
                    className="text-center mt10 mb10 c9 font14">
                    查看更多评论 &gt;
                </div>
            </div>}
            <footer className={styles.footer}>
                <input
                    placeholder="请输入评论"
                    defaultValue={text}
                    onChange={e => {
                        e.persist();
                        setText(e.target.value)
                    }}
                    onFocus={() => setFocus(true)}
                    onBlur={() => { setTimeout(() => setFocus(false), 500); util.kickBack(); }}
                    type="text"></input>
                {focus && <Button onClick={() => { commentAdd() }} className="ml10" type="ghost" inline size="small" >发布</Button>}
                {!focus && <img onClick={() => approve()} src="/images/article_foot_ico_fabulous@2x.png" alt="" />}
            </footer>
        </div>
    )
}

function noneComment() {
    return (
        <div className="text-center mb30 font13 c3">
            <span>暂无评论~</span>
        </div>
    )
}


export default ArticleComment
