import React from 'react';
import { InputItem, WingBlank, Flex, Button, Toast } from 'antd-mobile';
import styles from './Login.module.scss';
import { useGetSetState } from 'react-use';
import { loginByMobile } from '@/services/userServices';
import { ConnectProps } from '@/react-app-env';
import WX from '@/util/weChat';
import qs from 'qs';

const Login = ({ history }: ConnectProps) => {

  const [state, setState] = useGetSetState({
    phone: '',
    password: ''
  });
  var inWX = /micromessenger\s*\//i.test(navigator.userAgent); // true 则是微信打开

  const login = async () => {
    const result = await loginByMobile(state().phone, state().password);
    if (result != null) {
      Toast.info('登录成功');
      localStorage.setItem('user', JSON.stringify(result));
      window.location.href = '/';
      // history.go(-1)
  }
}

const wechatLogin = async () => {
    const data = {
      appid: WX.appId,
      redirect_uri: window.location.origin + '/redirect',
      response_type: 'code',
      scope: 'snsapi_userinfo',
      state: 'wechatlogin',
    }
    const uri = 'https://open.weixin.qq.com/connect/oauth2/authorize?' + qs.stringify(data) + '#wechat_redirect';
    window.location.href = uri;
}

return (
  <WingBlank size='md'>
    <div className='text-right mt20'>
      <img onClick={() => history.goBack()} className={styles.close_img} src="/images/login_ico_Close@2x.png" alt="" />
    </div>
    <div className='text-center mt20 mb30'>
      <img className='mt20' width='30%' src="/images/login_ico_logo@2x.png" alt="" />
    </div>

    {!inWX && <Flex align='center' direction='column' className='text-center'>
      <div className='w80'>
        <InputItem onChange={val => setState({ phone: val })} maxLength={11} type='text' className={styles.input} clear placeholder="请输入手机号"></InputItem>
      </div>
      <div className='w80 mt20'>
        <InputItem onChange={val => setState({ password: val })} value={state().password} type='password' className={styles.input} clear placeholder="请输入密码"></InputItem>
      </div>
      <div className='w80 mt20'>
        <Button onClick={() => login()} className={styles.button} type='primary'>登录</Button>
      </div>
    </Flex>}

    {inWX && <Flex align='center' direction='column' className='text-center'>
        <span onClick={() => wechatLogin()} className={styles.wechatLogin} >微信登录</span>
    </Flex>}

    {!inWX &&<Flex align='center' justify='center' className='mt20'>
      <div onClick={() => history.replace('/register')}>
        <span className='c6 font13'>账号注册</span>
      </div>
      <span className='c6 font13 ml5 mr5'>|</span>
      <div onClick={() => history.push('/quickLogin')}>
        <span className='c6 font13'>手机快捷登录</span>
      </div>
    </Flex>}

    {false && <div className={styles.bottom}>
      <Flex align='center'>
        <div className={styles.line}></div>
        <span className={styles.text}>其他方式登录</span>
        <div className={styles.line}></div>
      </Flex>
      <Flex justify='center' className='pt20'>
        <div className='text-center' onClick={() => wechatLogin()}>
          <img className={styles.bottomImage} src='/images/login_ico_wechat@2x.png' alt='' />
          <p className={styles.bottomText}>微信</p>
        </div>
      </Flex>
    </div>}
  </WingBlank>
);
}

export default Login;
