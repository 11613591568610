/**
 * @description 全局变量
 * @author hayho
 */

import { createGlobalState } from 'react-use';

interface appProps {
  showTabBar: boolean;
}

const appModel = createGlobalState<appProps>({
  showTabBar: true
});


export { appModel };
export type { appProps };
