import React, { ReactNode, ReactEventHandler, CSSProperties } from 'react';
import { NavBar } from 'antd-mobile';
import styles from './header.module.scss';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

interface HeaderProps {
  title: string,
  mode?: 'dark' | 'light';
  leftComponent?: ReactNode,
  back?: boolean,
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  onLeftClick?: () => ReactEventHandler;
  outerContainerStyles?: CSSProperties;
}
const data = {
  mode: 'dark',
  title: '球掌门',
  back: false,
  leftContent: null,
  rightContent: null,
  outerContainerStyles: {}
}
function Header(props: HeaderProps) {
  const history = useHistory();
  props = Object.assign(cloneDeep(data), props);
  const { back, onLeftClick } = props;

  const leftClickHandler = () => {

    if (back) {
      const { length } = history;
      if (length <= 1) {
        history.replace('/');
      } else {
        history.goBack();
      }
    }
    if (onLeftClick != null) {
      onLeftClick();
    }
  };

  return <header className={styles.header}>
    <NavBar
      style={props.outerContainerStyles}
      mode={props.mode}
      icon={
        props.back ? <i className='iconfont iconarrowleft font17 bold'></i> : null
      }
      onLeftClick={() => leftClickHandler()}
      leftContent={
        props.leftContent
      }
      rightContent={
        props.rightContent
      }
    >{props.title}</NavBar>
  </header>
}

export default Header;