import React from 'react';
import { Header } from '@/component';
import {useGetSetState} from 'react-use';
import { TextareaItem, List, Flex, Button, Toast } from 'antd-mobile';
import { encrypt, decrypt } from '@/util/crypto';

const Crypto = () => {
  const [state, setState] = useGetSetState({
    text: '',
    value: ''
  })

  const onEncrypt = () => {
    const value = encrypt(state().text);
    setState({value});
  }
 
  const onDecrypt = () => {

    const value = decrypt(state().text);
    try {
    console.log(JSON.parse(value));

    setState({value: JSON.parse(value)});
      
    } catch (error) {
      Toast.info('参数错误')
    }
  }

  return (
    <div className='page'>
        <Header title='加密解密'></Header>
        <List renderHeader={() => 'come on!!'}>
        <TextareaItem
            onChange={(text) => setState({text})}
            title="参数"
            defaultValue={state().text}
            placeholder="请输入参数"
            autoHeight
            rows={5}
          />
          <TextareaItem
            title="输出"
            value={JSON.stringify(state().value,   null,2)}
            placeholder="请输入参数"
            autoHeight
            rows={5}
          />
          </List>
          <Flex justify='between' className='mt10'>
             <Button className='w45' type='primary' onClick={() => onEncrypt()}>加密</Button>
             <Button className='w45' type='primary'  onClick={() => onDecrypt()}>解密</Button>
          </Flex>
    </div>
  );
}

export default Crypto;
