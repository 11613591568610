/**
 * @description 论坛模块状态
 * @author hayho
 */
import { createGlobalState } from 'react-use';

interface BbsProps {
  tabBar: {name: string, id: number}[]
  publishId: number | null,
  squareData: {
    id: number | null;
    articleId: number;
    type: '' | 'likes' | 'focus';
    value: string | number | any;
  }
}
const BbsModel = createGlobalState<BbsProps>({
    tabBar: [],
    publishId: null,  // 圈子id, 做发布返回刷新数据用
    squareData: { id: null, type: '', value: '', articleId: 0 } // 详情页返回刷新数据用
});


export { BbsModel };
export type { BbsProps };
