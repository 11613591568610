import React, { useEffect, Fragment } from 'react'
import { ConnectProps } from '@/react-app-env';
import { Flex, Toast } from 'antd-mobile';
import styles from './ArticleCell.module.scss';
import Line from '../common/Line';
import ImageQueue from './ImageQueue';
import { likeAdd } from '@/services/bbsServices';
import { useMap } from 'react-use';
import qs from 'qs';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import util from '@/util';
import { focusRequest, unfocusRequest } from '@/services/baseService';

SwiperCore.use([Autoplay]);

interface ArticleCellProps extends ConnectProps {
  data: any,
  index: number,
  setState: Function,
  state: {
    list: any[]
  },
  onDelete?: Function,
  page: string,
  ids?: number
}
// type CommentType = {
//   reply_id: string | number | undefined;
//   user_info: { user_name: React.ReactNode; };
//   reply_content: React.ReactNode;
// }

function ArticleCell(props: ArticleCellProps) {
  const { history, data, onDelete, index, page, ids } = props;
  const [state, { set, setAll }] = useMap<any>({});
  const match_info = data?.match_info?.match_info ? JSON.parse(data?.match_info?.match_info) : null;
  const type = match_info?.matchInfo?.select?.type;
  // const commentList: Array<CommentType> = data?.comment_info?.article_id ? [data?.comment_info] : [];
  useEffect(() => {
    setAll(data)
  }, [data, setAll]);

  const focus = async () => {
    const is_focus = state.is_focus;
    let result = null;
    if (is_focus) {
      result = await unfocusRequest(state?.user_info?.app_user_id);
    } else {
      result = await focusRequest(state?.user_info?.app_user_id);
    }
    if (result != null) {
      Toast.info(is_focus ? '已取消关注' : '关注成功');
      set('is_focus', is_focus ? 0 : 1);
    }
  }

  const liked = async () => {
    const result = await likeAdd(state.article_id);
    if (result != null) {
      Toast.info(state.is_liked ? '取消点赞成功' : '点赞成功');
      setAll({
        ...state,
        liked: state.is_liked ? --state.liked : ++state.liked,
        is_liked: state.is_liked ? 0 : 1
      });
    }
  }

  const goDetail = () => {
    history.push('/bbs/detail/' + state.article_id + '?id=' + ids);
  }

  // const shire = () => {
  //   WX.shire({
  //     title: data.article_title || data.user_info.user_name,
  //     desc: data.artile_content_text,
  //     link: window.location.origin + '/bbs/detail/' + data.article_article_id,
  //     imgUrl: data.user_info.user_avatar,
  //   });
  // }

  return (
    <div className='p10 bg-white mt10 relative'>
      {data.is_win === 1 && <img className={styles.position} src='/images/commonsuccess.png' alt='' />}
      {/* 头部展示 */}
      <div>
        <img onClick={() => history.push('/user/center?' + qs.stringify({
          _id: state.user_info.app_user_id, // 用户id
          id: state.user_id, // 论坛用户id
        }))
        } className={styles.avatar} src={data?.user_info?.user_avatar} alt="" />
        <div className='inline middle ml10'>
          <Flex align='center' className='c3 w600 font14'>{data?.user_info?.user_name}
            {type != null && <img src={util.outputTypeImg(type)} alt="" className={styles.icon} />}</Flex>
          <p className='font12 c6 mt5'>
            <span>{data.create_time_text}发布</span>
            <span className='ml10'>{data?.user_info?.user_grade_name}</span>
          </p>
        </div>
        {!state.is_focus && <span onClick={() => focus()} className={state.is_focus ? styles.isButton : styles.button}>{state.is_focus ? '已关注' : '关注'}</span>}
      </div>

      <div onClick={() => goDetail()}>
        {/* 内容 */}
        {match_info ? <div className='text-center relative'>
          {/* <span className={styles.badge}>
            {match_info?.currentData?.result?.sh || match_info?.currentData?.code}
          </span> */}
          <Flex align='center' justify='start' className={styles.info}>
            <span className={styles.badge}>
              {match_info?.currentData?.result?.sh || match_info?.currentData?.code}
            </span>
            {/* <img className={styles.cell_middle_img} src={match_info?.currentData?.zhuTeam?.logoUrl} alt='' /> */}
            <span className={styles.country}>{data?.match_info?.zhu_full_name}</span>
            <span className={styles.vs}>vs</span>
            <span className={styles.country}>{data?.match_info?.ke_full_name}</span>
            {/* <img className={styles.cell_middle_img} src={match_info?.currentData?.keTeam?.logoUrl} alt='' /> */}
          </Flex>
        </div> : null}
        <div className={styles.main} >
          {state?.is_original === 1 && <span className='color-primary mr5 w500'>[原创]</span>}
          {state?.is_first === 1 && <span className='color-primary mr5 w500'>[首发]</span>}
          <span className={styles.title}>{state.article_title}</span>
          {state?.artile_content_text}
        </div>
        <ImageQueue images={data.article_images} />
      </div>
      <div className='mt10 hidden font12'>
        <Flex align='center' className='fl-l red'>
          {page === 'bbs' && data.can_delete === 1 &&
            <Fragment>
              <i className='iconfont iconeye-close font18'></i>
              <span className='mr5' onClick={() => onDelete && onDelete(data.article_id, index)}>&nbsp;删除</span>
            </Fragment>
          }
          <span className='color-primary'>#{data.circleName}#</span>
        </Flex>
        <Flex align='center' className='fl-r c9'>
          <span>{state.user_win && '月红'}</span>
          <span className='ml2'>{state.user_win ? state.user_win : ''}</span>
          <i onClick={() => liked()} className={`iconfont iconlike ml10 ${state.is_liked ? 'color-primary' : ''}`}></i>
          <span onClick={() => liked()} className={`ml2 ${state.is_liked ? 'color-primary' : ''}`}>{state.liked}</span>
          {/* <i onClick={() => shire()} className='iconfont iconshare ml10'></i>
          <span onClick={() => shire()}>分享</span> */}
          <i className='iconfont iconmessage ml10'></i>
          <span className='ml2'>{data.reply}评论</span>
        </Flex>
      </div>
      {data?.comment_info?.length > 0 && <Line className='clear mt10 bg-white' />}
      {data?.comment_info[0] &&
        <div onTouchMove={(e) => e.stopPropagation()}>
          <Swiper className='mt10 swiper-no-swiping' autoplay loop={true} direction='vertical' style={{ height: '30px', overflow: 'hidden' }}>
            {data.comment_info.map((item: any, index: number) => {
              return <SwiperSlide key={index}
                onClick={() => goDetail()}
              // onClick={() => history.push('/user/center?' + qs.stringify({
              //   _id: item.user_info.app_user_id, // 用户id
              //   id: item.user_info.id // 论坛用户id
              // }))}
              >
                <p className={styles.huifu}>
                  {item.is_author ? <span className='c9'> 作者</span> : item?.user_info?.user_name}：{item?.reply_content}
                </p>
              </SwiperSlide>
            })}
          </Swiper>
        </div>
      }
    </div>
  )
}

export default ArticleCell;
