import React from 'react';
import { ConnectProps } from '@/react-app-env';
import Header from '@component/layouts/Header';
import { getArticleDetail } from '@/services/homeServices';
import { useMount, useGetSetState } from 'react-use';
import styles from './ArticleDetail.module.scss';
import { WingBlank, Flex } from 'antd-mobile';
import moment from 'moment';
import ArticleMain from '@component/Home/ArticleMain';
import ArticleComment from '@component/Home/ArticleComment';
import WX from '@/util/weChat';
import util from '@/util';

interface Ststeprops {
  detail: any
}

const ArticleDetail = (props: ConnectProps) => {
  const { match } = props;
  const id = match.params.id;

  const data: Ststeprops = {
    detail: {
      showAuthor: {},
      authorGrade: []
    }
  };
  const [get, setState] = useGetSetState(data);

  useMount(async () => {
    const result: any = await getArticleDetail(id);
    if (result != null) {
      setState({
        detail: result.articleInfo
      });

      WX.shire({
        title: result.articleInfo.title || result.articleInfo.showAuthor.name,
        desc: util.getSimpleText(result.articleInfo.context),
        link: window.location.href,
        imgUrl: result.articleInfo.imageUrl || result.articleInfo.showAuthor.avatar,
      });
    }
  })

  return (
    <div className="page">
      <Header title="文章详情" back={true} />
      <WingBlank size="md" className="mt10">
        <h3 className={styles.titleText}>{get().detail.title || ''}</h3>
        <Flex align="center" justify="start" className="mt7">
          <span className="font12 color-b1">{get().detail.showAuthor.name}</span>
          <span className="font12 color-b1 ml10">{moment(get().detail.publishAt).fromNow().replace(/(几秒前|几秒后)/, '刚刚').replace(' ', '')}</span>
          <span className="font12 color-b1 ml10">{get().detail.uv || 0}查看</span>
        </Flex>
        <Flex wrap="wrap" className="mt7">
          <span className="color-b1 font13">作者成绩:</span>
          {get().detail.authorGrade.map((item: boolean, index: number) => {
            if (index >= 10) {
              return null;
            }
            return <img key={index} className={styles.titleImage} src={item ? '/images/article_ico_yes@2x.png' : '/images/article_ico_no@2x.png'} alt="成绩" />
          })}
        </Flex>
        <ArticleMain data={get().detail}/>
        <ArticleComment id={id}/>
      </WingBlank>
    </div>
  );
}

export default ArticleDetail;
