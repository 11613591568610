import React, { createContext, useRef } from 'react';
import { ConnectProps } from 'react-app-env';
import { Header } from '@component/index';
import { InputItem, Button, Popover, Toast } from 'antd-mobile';
import styles from './PublishPage.module.scss';
import { useGetSetState, useMount } from 'react-use';
import Bifen from '@component/Bbs/Bifen';
// import ImageUpload from '@component/Bbs/ImageUpload';
import { cloneDeepWith, throttle } from 'lodash';
import { circleAll, articleAdd } from '@/services/bbsServices';
import { BbsModel, BbsProps } from '@/model/bbs';
import Edit from '@component/common/Edit';
import qs from 'qs';

export const PublishContext = createContext<any>(null);

const Item: any = Popover.Item;
const PublishPage = (props: ConnectProps) => {
  const { match, history, location } = props;
  const id = match.params?.id;
  const { showMatch } = qs.parse(location.search.replace('?', ''));
  const [model, setModel] = BbsModel();
  const [state, setState] = useGetSetState<any>({
    title: '',
    biFenTitle: '',
    biFenData: {},
    biFenIndex: null,
    biFenValue: {},
    selectIndex: null,
    html: '',
    files: [],
    radio: 0,
    open: false
  })
  const [quanzi, setQuanzi] = useGetSetState({
    Popover: false,
    map: new Map(),
    list: [],
    id: ~~id,
    showMatch: 0
  });

  const refEdit = useRef<any>();

  useMount(async () => {
    const result: any = await circleAll(1);
    if (result != null) {
      const map = quanzi().map;
      result.list.forEach((item: { name: string; id: number }) => {
        map.set(item.id, item.name)
      })
      setQuanzi({ map, list: result.list, showMatch: Number(showMatch) || 0 })
    }
  });

  const onSubmit = throttle(async () => {

    const { title, biFenData, biFenValue, selectIndex } = state();
    const html = refEdit?.current?.changeVal();
    const reg = /<img\ssrc=["'](.*?)\s*["']\/>/gi;
    let imagesArray = html.match(reg);
    if (imagesArray == null) {
      imagesArray = [];
    }
    const urlArray = Array.from(imagesArray, (uri: any) => {
      return uri.match(/http.*(?=["']\/>)/)[0]
    })
    if (title.length > 38) {
      return Toast.info('标题不能大于38个字')
    }
    if (html.length < 10) {
      return Toast.info('文章内容不能少于十个字')
    }
    if (quanzi().id === -818) {
      return Toast.info('请选择圈子');
    }
    const isSelectMatch = JSON.stringify(biFenValue) === '{}';
    let list = [];
    let content = html.replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, '');
    content += cloneDeepWith(imagesArray).map((item: any) => {
      return `<img src='${item}' alt=''/>`
    }).join('');

    if (isSelectMatch) {
      list[0] = {
        html: content,
        images: urlArray.splice(0, 4)
      }
    } else {
      list[0] = {
        currentData: biFenData,
        html: content,
        matchInfo: {
          select: biFenValue,
          match_id: biFenData._id,
          code: biFenData.code,
          game_start: biFenData.game_start,
          zhu_full_name: biFenData.zhu_full_name,
          ke_full_name: biFenData.ke_full_name,
          left_icon: biFenData.zhuTeam ? biFenData.zhuTeam.logoUrl : '',
          right_icon: biFenData.keTeam ? biFenData.keTeam.logoUrl : '',
        },
        number: selectIndex,
        images: urlArray.splice(0, 4)
      }
    }
    const data = {
      title: title,
      is_original: (state().radio === 1) ? '1' : '0',
      is_first: (state().radio === 2) ? '1' : '0',
      list: list,
      lon_point: '',
      lat_point: '',
      location_status: '',
      circleId: quanzi().id
    }
    const result = await articleAdd(data);
    if (result != null) {
      Toast.info(
        <span style={{ color: '#ffd633' }}>发表成功,系统经验值
        <span style={{ fontSize: 24, fontWeight: 'bold' }}> +3</span>
        </span>, 1500, () => {
          history.goBack();
        });
      setModel({ ...model as BbsProps, publishId: quanzi().id })
      history.goBack();
    }
  }, 2000);

  const rightContent = <Popover mask
    visible={quanzi().Popover}
    placement='bottom'
    overlay={
      quanzi().list.map((item: any) => {
        return <Item className='font12' style={{ width: '120px' }} key={item.id} id={item.id} showmatch={item.showMatch}>{item.name}</Item>
      })
    }
    onSelect={data => {
      setQuanzi({ id: data.props.id, showMatch: data.props.showmatch });
    }}
  >
    <div style={{
      borderRadius: '20px',
      backgroundColor: '#fff',
      padding: '1px 7px',
      display: 'flex',
      alignItems: 'center',
      color: '#ec5852'
    }}>
      <span className='font12'>{quanzi().map.get(quanzi().id) || '选择圈子'}</span>
      <i className="iconfont iconcaret-down font14 y-1 ml2 mt2"></i>
    </div>
  </Popover>;


  return (
    <div className='page bg-f8 relative'>
      <Header title='发布帖子' back={true} rightContent={rightContent} />
      <InputItem
        className={styles.input}
        value={state().title}
        onChange={text => setState({ title: text })}
        clear
        maxLength={38}
        placeholder="(选填，最多38个汉字)"
      >
        <span className='c3 font14 mr10'>标题</span>
      </InputItem>
      <PublishContext.Provider value={{ state: state(), setState: setState }}>
        <Edit forwardRef={refEdit} cRef={refEdit} />
        {quanzi().showMatch === 1 && <Bifen />}
      </PublishContext.Provider >


      <div className='text-center mt10 pl10 pr10'>
        <Button onClick={() => onSubmit()} type='primary'>发布</Button>
      </div>
    </div>
  )
}

export default PublishPage;
