import React, { createContext } from 'react';
import { Header, Line } from '@/component';
import { useMount, useMap, useList } from 'react-use';
import { articleInfo, forecastList, topList, likeAdd, replyList } from '@/services/bbsServices';
import { ConnectProps } from '@/react-app-env';
import Title from '@component/Bbs/Detail/Title';
import Match from '@component/Bbs/Detail/Match';
import { WingBlank, Flex, Toast } from 'antd-mobile';
import Reward from '@component/Bbs/Detail/Reward';
import ForecastCell from '@component/Score/ForecastCell';
import Footer from '@/component/Bbs/Detail/Footer';
import './bbsArticleMain.scss';
import qs from 'qs';
import { BbsModel, BbsProps } from '@/model/bbs';
import WX from '@/util/weChat';

export const Context = createContext<any>(null);

const BbsDetail = (props: ConnectProps) => {
  const { match, location } = props;
  const id = match.params.id;
  const {id: ids} = qs.parse(location.search.replace('?', ''));
  const [state, { setAll, set }] = useMap({
    article_id: '',
    app_user_id: '',
    match_list: [],
    article_content: '',
    top_list: [],
    flag: false,
    is_liked: 0,
    liked: 0
  });
  const [list, { set: setList, updateAt }] = useList<any>([]);
  const [commentList, { set: setComment }] = useList<any>([]);
  const [model, setModel] = BbsModel();
  
  useMount(async () => {
    const result: any = await articleInfo(id);
    if (result != null) {
      setAll({ ...result, flag: true });
      getBbs(result.app_user_id);
      getComment(result.article_id);
      const tops: any = await topList(result.article_id);
      tops!=null && set('top_list', tops.list);
      WX.shire({
        title: result.article_title || result.user_info.user_name,
        desc: result.artile_content_text,
        link: window.location.href,
        imgUrl: result.user_info.user_avatar,
      });
    }
  });


  const getBbs = async (id: string | number) => {
    const list: any = await forecastList(id);
    if (list != null) {
      setList(list);
    }
  }

  /**
   * 
   *  评论数据
   */
  const getComment = async (id: string | number) => {
    const last_id = commentList.length === 0 ? 0 : commentList[commentList.length - 1].reply_id
    const result: any = await replyList(id, last_id);
    if (result != null) {
      if (commentList.length !== 0 && result.list.length === 0) {
        return Toast.info('没有更多回复了')
      }
      setComment(commentList.concat(result.list));
    }
  }

  const updateBbs = (id: string) => {
    list.forEach((value: any, index) => {
      if (id === value._id) {
        value.isLock = false;
        value.order = { _id: new Date() };
        updateAt(index, value);
      }
    })
  }

  /**
   * 点赞
   */
  const likes = async () => {
    const result = await likeAdd(state.article_id);
    if (result != null) {
      Toast.info(state.is_liked ? '取消点赞成功' : '点赞成功');
      setModel({
        ...model as BbsProps,
        squareData: {
          id: ids != null ? ~~ids : null,
          type: 'likes',
          value: {
            liked: state.is_liked ? state.liked - 1 : state.liked + 1,
            is_liked: state.is_liked ? 0 : 1
          },
          articleId: ~~id
        }
      });
      setAll({
        ...state,
        liked: state.is_liked ? --state.liked : ++state.liked,
        is_liked: state.is_liked ? 0 : 1
      });
    }
  }

  return (
    <div className='page'>
      <Header title='帖子详情' back={true} />
      <WingBlank size='md'>
        <Context.Provider value={{ state, set, setAll, likes, commentList, setComment, getComment }}>
          <Title data={state} {...props}/>
          <Match list={state?.match_list} />
          <div className='bbsArticleMain' 
            dangerouslySetInnerHTML={{ __html: state.article_content }}
            onClick={(e) => {return false;}}></div>
          <Reward data={state} {...props}/>
          {list.length > 0 && <div className='mt10'>
            <Flex align='center' className='bg-f9 pt5 pb5'>
              <span className='bg-primary ml2' style={{ width: '2px', height: '17px' }}></span>
              <p className='c3 font14 w500 ml5'>推荐市场</p>
            </Flex>
            {list.map((item: any) => {
              return <div key={item._id}>
                <ForecastCell refresh={(id: string) => updateBbs(id)} type='forum' data={item} {...props} />
                <Line />
              </div>
            })}
          </div>}
          {state.flag ? <Footer data={state} {...props} /> : null}
        </Context.Provider>
      </WingBlank>
    </div>
  );
}

export default BbsDetail;
