import React, { useContext } from 'react';
import styles from './detail.module.scss';
import { Flex, Toast } from 'antd-mobile';
import moment from 'moment';
import { padEnd, toNumber } from 'lodash';
import { focusRequest, unfocusRequest } from '@/services/baseService';
import { ConnectProps } from '@/react-app-env';
import { BbsModel, BbsProps } from '@/model/bbs';
import { Context } from '@/pages/Bbs/BbsDetail'
import qs from 'qs';
interface TitleProps extends ConnectProps {
  data: any;
}
function Title(props: TitleProps) {
  const { data, history, location } = props;
  const [model, setModel] = BbsModel();
  const { set } = useContext(Context)
  const { id } = qs.parse(location.search.replace('?', ''));

  const focus = async () => {
    const is_focus = data.is_focus;
    let result = null;
    if (is_focus) {
      result = await unfocusRequest(data?.user_info?.app_user_id);
    } else {
      result = await focusRequest(data?.user_info?.app_user_id);
    }
    if (result != null) {
      Toast.info(is_focus ? '已取消关注' : '关注成功');
      set('is_focus', is_focus ? 0 : 1);
      setModel({
        ...model as BbsProps,
        squareData: {
          id: id != null ? ~~id : null,
          type: 'focus',
          value: is_focus ? 0 : 1,
          articleId: data.article_id
        }
      })
    }
  }

  return (
    <div className={styles.title}>
      <h1 className={styles.titleText}>
        {data.article_title || ''}
      </h1>
      <div className='flex mt10'>
        <div onClick={() => {
          history.push('/user/center?' + qs.stringify({
            _id: data.app_user_id, // 用户id
            id: data.user_id, // 论坛用户id
          }))
        }}>
          <img className={styles.title_avator} src={data?.user_info?.user_avatar} alt=''
          />
        </div>
        <div className={styles.auto}>
          <div
            className={styles.title_left}
          >
            <div className='flex'>
              <span className={styles.title_name}>{data?.user_info?.user_name || ''}</span>
              <div className={styles.title_g_f} style={{ backgroundColor: data?.is_focus ? '#ee795d' : 'rgba(0,0,0,0)' }}
                onClick={() => { focus() }}>
                {!data.is_focus ?
                  <i className='iconfont iconplus color-primary font15' /> : null
                }
                <span className={styles.title_guanzhu} style={{ color: data.is_focus ? '#fff' : '#ee795d' }}>{data.is_focus ? '已关注' : '关注'}</span>
              </div>
              <span className={styles.title_yuedu}>{data?.user_info?.user_fans < 100 ? '100+' : data?.user_info?.user_fans}</span>
            </div>
            <Flex align='center'>
              <span className={styles.title_yuedu}>连红勋:</span>
              {data?.user_info?.medal_list.length !== 0 ? data?.user_info?.medal_list.map((item: { medal_id: string | number | undefined; medal_img: string | undefined; }, index: any) => {
                return <img key={item.medal_id} src={item.medal_img} style={{ width: '20px', height: '20px', marginLeft: '2px' }} alt='' />
              }) : <span className='font11 c6'>暂无</span>}
            </Flex>
          </div>
          <div
            className={styles.title_left}
          >
            <Flex align='center' className={styles.title_bottom}>
              <span className={styles.title_bottom_text}>发布时间：{data.create_time_text}</span>
              <span className={styles.title_bottom_text}>等级：{data?.user_info?.user_grade_name || ''}</span>
            </Flex>
            <Flex align='center' onClick={() => { }}>
              {/* <i className='iconfont iconwarning-circle c9 font12'></i> */}
              {/* <span className={styles.title_jubao}>举报</span> */}
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
