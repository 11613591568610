import React, { useState, Fragment } from 'react';
import { ConnectProps } from '@/react-app-env';
import { useMount } from 'react-use'
import { getCarouseIndex, getArticleList } from '@/services/homeServices';
import { WingBlank, PullToRefresh } from 'antd-mobile';
import ArticleListCell from '@component/Home/ArticleListCell';
import Line from '@component/common/Line';
import NoData from '@component/common/NoData';
import '@css/module/carousel.scss'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AdMain from '@component/Ranking/AdMain';

interface MainProps extends ConnectProps {
  name: string;
  isCarouse: boolean;
}

const HomeMain = (props: MainProps) => {
  const { name, isCarouse, history } = props;
  const [refreshing, setRefreshing] = useState(false);
  const [isBottom, setBottom] = useState(false);
  const [carouseList, setCarouseList] = useState([])
  const [list, setList] = useState<any>([])
  const adIndex = 4;
  const lianHongIndex = 9;

  useMount(async () => {
    if (isCarouse) {
      const carouseList: any = await getCarouseIndex(name);
      setCarouseList(carouseList);
    }
    getList(null);
  })

  const getList = async (publishAt?: string | null) => {
    const result: any = await getArticleList(name, publishAt);
    setList(publishAt ? [...list, ...result] : result);
    setRefreshing(false);
    setBottom(result.length < 20)
  };

  const goDetail = (id: string) => {
    history.push(`/article/detail/${id}`)
  };

  const _adMain = (index: number) => {
    if (index === adIndex) {
      return  <AdMain {...props} type='common' />
    }

    if (index === lianHongIndex) {
      return  <AdMain {...props} type='lianhong' />
    }
  }

  return (
    <PullToRefresh
      getScrollContainer={() => {
        return ''
      }}
      distanceToRefresh={50}
      damping={100}
      style={{
        height: document.documentElement.clientHeight - 95 - 43.5,
        overflowY: 'auto',
      }}
      indicator={{}}
      direction={'down'}
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true)
        getList(null);
      }}
    >
      <WingBlank size='md' className="mt10">
        {carouseList.length > 0 && <div onTouchMove={(e) => e.stopPropagation()}>
          <Carousel 
            className=''
            onClickItem={(i) => goDetail(carouseList[i]['href'])}
            infiniteLoop={true}
            autoPlay={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}>
            {carouseList.map((item: { _id: string; imgUrl: string; types: string;href: string; }) => {
              return <img onClick={() => goDetail(item.href)} key={item._id} style={styles.carouselImg} src={item.imgUrl} alt={item.types} />
            })}
          </Carousel>
        </div>}

        {list.map((item: any, index: number) => {
          return <Fragment key={item._id}>
            {isCarouse && _adMain(index)}
            <ArticleListCell goDetail={goDetail} item={item} />
            <Line />
          </Fragment>
        })}

        {!isBottom &&
          <div className="font13 color-blue text-center pt20 pb20"
            onClick={() => { getList(list[list.length - 1].publishAt) }}>展开更多文章</div>
        }

        {list.length === 0 &&
          <NoData/>
        }

      </WingBlank>
    </PullToRefresh>

  )
};

export default HomeMain;

const styles = {
  carouselWrapImg: {
    height: '100%',
    minHeight: '150px',
    borderRadius: '3px',
    overflow: 'hidden'
  },
  carouselImg: {
    width: '100%',
    maxHeight: '160px',
    borderRadius: '3px',
    overflow: 'hidden'
  }
}