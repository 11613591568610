import React from 'react';
import { Flex } from 'antd-mobile';
import styles from './CategoryCell.module.scss';
import { History } from 'history';

function SlideMenu(props: {tabsId: number, history: History, showMatch: number}) {
  const { history, tabsId, showMatch} = props;
  return (
    <Flex className={styles.fix} direction='column'>
     <div onClick={() => history.push('/user/center')}><i className='iconfont iconuser'></i></div>
     <div onClick={() => history.push('/publish/' + tabsId + '?showMatch=' + showMatch)}><i className='iconfont iconedit-square'></i></div>
    </Flex>
  )
}

export default SlideMenu;
