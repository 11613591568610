/**
 * @description 榜单模块
 */
import { request, urls } from '@/services/request';
import { constant } from '@/model/constant';

/**
 * 名人堂榜单
 */
export async function excellentUsers() {
    const url = urls.excellentUsers;

    const result = await request({ url });
    return result;
}

/**
 * 名人堂赛事
 */
export async function excellentList(_id?: string) {
    const url = urls.excellentList;
    const data: { _id?: string, perpage: number } = { perpage: constant.perpage };
    if (_id) {
        data._id = _id;
    }

    const result = await request({ url, data });
    return result;
}

/**
 * 名人堂赛事
 */
export async function highBenefitList(page: number) {
    const url = urls.highBenefitList;
    const data = {page,  perpage: constant.perpage };

    const result = await request({ url, data });
    return result;
}


/**
 * 高手推荐盈利率
 */
export async function yinglilvList() {
    const url = urls.yinglilvList;

    const result = await request({ url });
    return result;
}

/**
 * 高手推荐命中率
 */
export async function mingzhonglvList() {
    const url = urls.mingzhonglvList;

    const result = await request({ url });
    return result;
}

/**
 * 高手推荐连红
 */
export async function lianhongList() {
    const url = urls.lianhongList;

    const result = await request({ url });
    return result;
}

/**
 * 30天连红大神
 */
export async function unlimitedLianhong() {
    const url = urls.unlimitedLianhong;

    const result = await request({ url });
    return result;
}

/**
 * 高回报列表
 */
export async function levelList(type: number | string, timesType: number) {
    const url = urls.levelList;
    const data = {type, timesType};

    const result = await request({ url, data });
    return result;
}

/**
 * 明灯榜
 */
export async function mingdengList(timesType: number) {
    const url = urls.mingdengList;
    const data = {timesType};

    const result = await request({ url, data });
    return result;
}


