import React from 'react';

const FontSizePage = () => {
  return (
    <div>
      <p className='font10 mt10'>字号10px字体大小对照表</p>
      <p className='font11 mt10'>字号11px字体大小对照表</p>
      <p className='font12 mt10'>字号12px字体大小对照表</p>
      <p className='font13 mt10'>字号13px字体大小对照表</p>
      <p className='font14 mt10'>字号14px字体大小对照表</p>
      <p className='font15 mt10'>字号15px字体大小对照表</p>
      <p className='font16 mt10'>字号16px字体大小对照表</p>
      <p className='font17 mt10'>字号17px字体大小对照表</p>
      <p className='font18 mt10'>字号18px字体大小对照表</p>
      <p className='font19 mt10'>字号19px字体大小对照表</p>
      <p className='font20 mt10'>字号20px字体大小对照表</p>
      <hr className='mt10'/>
      <p className='font15 mt10 w500'>字号15px字体加粗500对照表</p>
      <p className='font15 mt10 w600'>字号15px字体加粗600对照表</p>
      <p className='font15 mt10 w700'>字号15px字体加粗700对照表</p>
      <p className='font15 mt10 w800'>字号15px字体加粗800对照表</p>
    </div>
  );
}

export default FontSizePage;
