import React, { FC } from 'react';
import { TabBar } from 'antd-mobile';
import { ConnectProps } from '@/react-app-env';
import IconElement from '@component/common/IconElement';
import { appModel } from '@/model/app';

import HomePage from '@/pages/Home/HomePage';
import ScorePage from '@/pages/Score/ScorePage';
import User from '@/pages/User/UserPage';
import List from '@/pages/List/ListPage';
import Bbs from '@/pages/Bbs/BbsPage';
import WX from '@/util/weChat';

const tabbarConfig = [
  {
    title: '榜单',
    name: '/',
    icons: require('../assets/images/tabbar_ico_List_default@2x.png'),
    selectedIcon: require('../assets/images/tabbar_ico_List_selected@2x.png'),
    container: (props: ConnectProps) => <List {...props} />,
    shire: {
      title: '球掌门高手榜单',
      desc: '职业高手发布方案，红单一次又一次赚嗨，为您把握每一场体育赛事，为精准而生。'
    }
  },
  {
    title: '资讯',
    name: '/refer',
    icons: require('../assets/images/tabbar_ico_home_default@2x.png'),
    selectedIcon: require('../assets/images/tabbar_ico_home_selected@2x.png'),
    container: (props: ConnectProps) => <HomePage {...props} />,
  },
  {
    title: '社区',
    name: '/forum',
    icons: require('../assets/images/tabbar_ico_pk_default@2x.png'),
    selectedIcon: require('../assets/images/tabbar_ico_pk_selected@2x.png'),
    container: (props: ConnectProps) => <Bbs {...props} />,
    shire: {
      title: '球掌门足球社区',
      desc: '全国89%玩家认可喜欢的足球社区，球掌门高手原创方案发布，学习分析交流，让足球红单更简单。'
    }
  },
  {
    title: '比分',
    name: '/score',
    icons: require('../assets/images/tabbar_ico_score_default@2x.png'),
    selectedIcon: require('../assets/images/tabbar_ico_score_selected@2x.png'),
    container: (props: ConnectProps) => <ScorePage {...props} />,
    shire: {
      title: '球掌门比分直播',
      desc: '完整足球数据比分数据，比分指数，胜平负指数，让球指数，大小球指数、精彩数据来自球掌门。'
    }
  },


  {
    title: '我的',
    name: '/users',
    icons: require('../assets/images/tabbar_ico_user_default@2x.png'),
    selectedIcon: require('../assets/images/tabbar_ico_user_selected@2x.png'),
    container: (props: ConnectProps) => <User {...props} />,
    dot: false
  },
];

const TabPage: FC<ConnectProps> = (props) => {
  const { location } = props;
  const pathname = location.pathname;
  const [model] = appModel()

  function renderItem() {
    return tabbarConfig.map((item, index) => {
      return <TabBar.Item
        key={index}
        title={item.title}
        selected={pathname === item.name}
        icon={IconElement(item.icons)}
        selectedIcon={IconElement(item.selectedIcon)}
        dot={item.dot ? item.dot : false}
        onPress={() => {
          WX.shire(item.shire || {});
          props.history.replace(item.name);
        }}
      >
        {item.container(props)}
      </TabBar.Item>
    })
  }
  return (
    <div className="container">
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#ee795d"
        barTintColor="white"
        tabBarPosition="bottom"
        prerenderingSiblingsNumber={0}
        hidden={!model?.showTabBar}
      // noRenderContent={true}
      >
        {renderItem()}
      </TabBar>
    </div>
  )
};

export default TabPage;

