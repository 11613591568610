/* eslint-disable no-new-func */
// 对Date的扩展，将 Date 转化为指定格式的String   
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，   
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)   
// 例子：   
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423   
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18   
const base = {
  format (time, fmt) { //author: meizz
    let date = new Date(time || '');
    let o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "H+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (~~RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  },
  getDateTimeStamp (dateStr) {
    return new Date(dateStr).getTime()
  },
  getDateDiff (dateStr) {
    let publishTime = this.getDateTimeStamp(dateStr) / 1000,
      d_seconds,
      d_minutes,
      d_hours,
      d_days,
      timeNow = parseInt(new Date().getTime() / 1000),
      d,

      date = new Date(publishTime * 1000),
      Y = date.getFullYear(),
      M = date.getMonth() + 1,
      D = date.getDate(),
      H = date.getHours(),
      m = date.getMinutes(),
      s = date.getSeconds();
    //小于10的在前面补0
    if (M < 10) {
      M = '0' + M;
    }
    if (D < 10) {
      D = '0' + D;
    }
    if (H < 10) {
      H = '0' + H;
    }
    if (m < 10) {
      m = '0' + m;
    }
    if (s < 10) {
      s = '0' + s;
    }

    d = timeNow - publishTime;
    d_days = parseInt(d / 86400);
    d_hours = parseInt(d / 3600);
    d_minutes = parseInt(d / 60);
    d_seconds = parseInt(d);

    if (d_days > 0 && d_days < 7) {
      return d_days + '天前';
    } else if (d_days <= 0 && d_hours > 0) {
      return d_hours + '小时前';
    } else if (d_hours <= 0 && d_minutes > 0) {
      return d_minutes + '分钟前';
    } else if (d_seconds < 60) {
      return '刚刚';
    }
    // else if (d_days >= 3 && d_days < 30){
    //     return M + '-' + D + '&nbsp;' + H + ':' + m;
    // }
    else if (d_days >= 7) {
      return Y + '-' + M + '-' + D;
    }
  },
  template: function(html) {
    /* eslint-disable */
    var body = ""
      + "var _escapeHtml=" + this.escapeHtml.toString() + ";"
      + "var s='';s+='"
      + html.replace(/(\n|\r|\t| ){1,}/g, ' ')
        .replace(/<\[-(.*?)\]>/g, "';s+=($1)+'")
        .replace(/<\[=(.*?)\]>/g, "';s+=( _escapeHtml($1) )+'")
        .replace(/<\[(.*?)\]>/g, "';$1;s+='") + "'; return s;";

    return new Function('data', 'data=data||{};with(data) { '+body+'; }');
    /* eslint-enable */
  },
  escapeHtml (html) {
    if (typeof html !== 'string') {
      return html;
    }

    let map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      '\'': '&#x27;',
      '`': '&#x60;'
    };
    let regexp = new RegExp('(?:' + Object.keys(map).join('|') + ')', 'g');

    return html.replace(regexp, function (match) {
      return map[match];
    });
  },
  getNowDateStr (param) {
    let arr = param.value.split(/\s+/);
    let yyyy = new Date().getFullYear()
    let mm = arr[0].split('-')[0];
    let dd = arr[0].split('-')[1];
    let hh = arr[1].split(':')[0];
    let ss = arr[1].split(':')[1];

    let date = `${yyyy}/${mm}/${dd} ${hh}:${ss}:00`;
    let end = this.dateEnd(param.type === 'str' ? date : param.value);
    return {
      date,
      value: end.d !== undefined ? `${end.d}天${end.h}小时${end.m}分${end.s}秒` : false
    }
  },
  dateEnd (str) {
    let date = new Date();
    let now = date.getTime();
    //设置截止时间  
    // "11-08 04:00"
    //    let str="2018/11/7 23:33:10";
    let endDate = new Date(str);
    let end = endDate.getTime();

    //时间差  
    let leftTime = end - now;
    //定义变量 d,h,m,s保存倒计时的时间  
    let d, h, m, s;
    if (leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
      m = Math.floor(leftTime / 1000 / 60 % 60);
      s = Math.floor(leftTime / 1000 % 60);
    }
    return {
      d,
      h,
      m,
      s
    }
    //    console.log(`${d}天${h}小时${m}分${s}秒`)
  },
  getDate (type) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let nowDate = year + "-" + month + "-" + day;
    if (type && typeof type === 'string') {
      return {
        yyyy: year,
        mm: month,
        dd: day
      }
    } else {
      return nowDate
    }
  },
  checkAttribute (obj, type) {
    let value = Object.values(obj);
    if (type == null) {
      for (let index = 0; index < value.length; index++) {
        const item = value[index];
        if (item === '') {
          return false
        }
      }
      return true
    }
  },
  regExp (value = '') {
    return {
      integer: () => {
        let reg = /^[0-9]+$/;
        return reg.test(value)
      }
    }
  },
  setTimeout (fn, time) {
    return setTimeout(() => {
      fn.apply()
    }, time)
  }
}

export default base;