import React, { useState } from 'react';
import qs from 'qs';
import { Header } from '@component/index';
import { Radio, List, Button, Toast } from 'antd-mobile';
import styles from './PayPage.module.scss';
import { ConnectProps } from '@/react-app-env';
import { useMount } from 'react-use';
import { paymentCreate } from '@/services/payServices';
import util from '@/util';
import WX from '@/util/weChat';

const RadioItem = Radio.RadioItem;
const PayPage = (props: ConnectProps) => {
  const { location, history } = props;
  const [value, setValue] = useState(0)
  const param = qs.parse(location.search.replace('?', ''));

  const data = [
    { value: 0, label: '微信', uri: '/images/login_ico_wechat@2x.png' },
    // { value: 1, label: '支付宝', uri: '/images/pay_ico_alipay@2x.png' },
  ];

  useMount(() => {

  });

  const onChange = (value: number) => {
    setValue(value);
  };

  const pay = async () => {
    let type = '';
    switch (value) {
      case 0:
        type = util.isWechat() ? 'jsApiWechat' : 'h5Wechat';
        break;
      case 1:
        type = 'alipay';
        break;
    }
    const data = {
      fen: Number(param.money) * 100,
      title: '充值',
      type: type,
      useageType: (param.useageType as 'zmb' | 'forum') || 'zmb'
    }
    const result: any = await paymentCreate(data);
    if (result == null) return;
    if (util.isWechat()) {
      WX.pay(result, () => {
        Toast.info('支付成功');
        history.push('/paysuccess');
      });
    } else {
      window.location.href = result.mweb_url;
    }

  }

  return (
    <div className='page bg-f8'>
      <Header title='支付' back={true} outerContainerStyles={{ backgroundColor: '#484d59' }} />
      <div className={styles.cell}>
        <span className={styles.lefttext}>需支付:</span>
        <span className='font15 ml5 mr5 red' >{param.money}</span>
        <span className={styles.lefttext}>元</span>
      </div>

      <div className={`${styles.cell} mt10`}>
        <span className={styles.lefttext}>优惠:</span>
        <span className='ml5 mr5 c9' >0</span>
        <span className={styles.lefttext}>元</span>
      </div>

      <div className={`${styles.cell} mt10`}>
        <span className={styles.lefttext}>选择支付方式:</span>
      </div>
      <List>
        {data.map(i => (
          <RadioItem key={i.value} checked={value === i.value} onChange={() => onChange(i.value)}>
            <img className={styles.img} src={i.uri} alt="" />
            <span className='c3 font15'>{i.label}</span>
          </RadioItem>
        ))}
      </List>
      <div className='text-center pl10 pr10' style={{ marginTop: '20vh' }}>
        <Button onClick={() => pay()} className='br25' type='primary' >支付：{param.money}元</Button>
      </div>
    </div>
  )
}

export default PayPage;
