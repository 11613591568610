import React, {CSSProperties} from 'react';

interface StyleProps {
    className?: string;
    style?: CSSProperties;
    color?: string;
    title?: string;
}
export default function NoData(props: StyleProps) {
    return <div className={`c6 text-center font13 ${props.className}`} style={{...props.style}}>
        {props.title || '暂无数据'}
    </div>
}