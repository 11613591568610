import React from 'react'
import { ConnectProps } from '@/react-app-env';
import Avatar from './children/Avatar';
import Main from './children/Main';

interface ForecastCellProps extends ConnectProps {
  data: any;
  type?: string;
  refresh: any;
}

export default function ForecastCell(props: ForecastCellProps) {
  const { data, type } = props;
  const item = Object.assign({
    bbsUser: {},
    user: {},
    userLevel: {}
  }, data)
  return (
    <div className='mt10 mb10'>
      <Avatar {...props} data={item} />
      <Main {...props} type={type} data={item} refresh={props.refresh}/>
    </div>
  )
};


