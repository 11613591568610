/**
 * @description 工具
 */
const util = {
  /**
   * 解析url
   * @param {string} 可以是完整的可以是部分的url, 如果键值不存在则不显示
   * @return {object}
   */
  parseUrl(url: string): object {
    let param: any = {};

    (url || '').replace(/([^=?&#]+)=([^=?&#]+)/g, (all, first, second): any => {
      param[decodeURIComponent(first)] = decodeURIComponent(second);
    });

    return param;
  },
  /**
   * @description 微信中使用原生input键盘无法回弹问题
   */
  kickBack() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollTop + 1);
      document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1);
    }, 10)
  },
  isWechat(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    const match = ua.match(/MicroMessenger/i);
    if (match === null) {
      return false;
    }
    if (match.includes('micromessenger')) {
      return true;
    }
    return false;
  },
  outputTypeImg(type: string) {
    const uri: any = {
      spf: '/images/spf_icon.png',
      rqspf: '/images/spf_icon.png',
      daxiao: '/images/dx_icon.png',
      yazhi: '/images/rq_icon.png',
      bqc: '/images/bq_icon.png',
      jqs: '/images/jq_icon.png',
      bf: '/images/bf_icon.png'
    }
    return uri[type];
  },
  getSimpleText(html: string) {
    const re1 = new RegExp("<.+?>", "g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
    const msg = html.replace(re1, '');//执行替换成空字符
    return msg;
  }
}

export default util;