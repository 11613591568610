import React from 'react';
import WX from '@/util/weChat';
import { useGetSetState, useMount } from 'react-use';
import styles from './Promotion.module.scss'
import { ConnectProps } from '@/react-app-env';
import qs from 'qs';
import { promotionUserCreate, promotionGet } from '@/services/userServices';
import { unlimitedLianhong } from '@/services/listServices';
import { Toast } from 'antd-mobile';

const Promotion = (props: ConnectProps) => {
    const { location } = props;
    const query = qs.parse(location.search.replace('?', ''));

    const [state, setState] = useGetSetState({
        phone: '',
        list: [],
        nick: '',
        goApp: false,
        showFooter: true,
        height: ''
    });

    useMount(async () => {
        const result: any = await promotionGet(query.token as string);
        if (result != null) {
            setState({
                nick: result
            })
        }
        const resultOfList: any = await unlimitedLianhong();
        if (resultOfList != null) {
            setState({
                list: resultOfList
            })
        }
    })

    const focus = () => { setState({ showFooter: false }) }
    const blur = () => { setState({ showFooter: true }) }
    const sendPhone = async () => {
        const reg = /^1[0-9]{10}$/;
        if (state().phone === '') {
            return Toast.info('手机号不能为空');
        }
        if (!reg.test(state().phone)) {
            return Toast.info("手机号格式错误");
        }

        const result = await promotionUserCreate(state().phone, query.token as string);
        if (result != null) {
            Toast.info('领取成功,请点击下载APP');
            setState({ goApp: true });
        }
    }
    const openApp = () => {
        const _clickTime = new Date().getTime();
        window.location.href = "qiuzhangmen://ball044310/tabPage:h5";

        //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
        let _count = 0,
            intHandle: any;
        intHandle = setInterval(function () {
            _count++;
            let elsTime = new Date().getTime() - _clickTime;
            if (_count >= 100 || elsTime > 3000) {
                console.log(_count);
                console.log(elsTime);
                clearInterval(intHandle);
                //检查app是否打开
                if (document.hidden) {
                    window.close();
                } else {
                    window.location.href = "http://h5.qiuzhangmen.com/download.html";//下载链接
                }
            }
        }, 20);
    }

    return (
        <div className={styles.shire} style={{ height: document.body.clientHeight + 'px' }}>
            {WX.isWechat() && <div className={styles.mask}></div>}
            <header>
                <div className={styles.tip}>
                    <p>接受{state().nick}的邀请您与他同时获得社区2000魔豆可打赏喜欢高手哦..</p>
                </div>
                <img src='/images/yq-2.png' alt='' />
            </header>
            {!state().goApp ?
                <section>
                    <input
                        onFocus={focus}
                        onBlur={blur}
                        maxLength={11}
                        type='text'
                        placeholder='填写手机号'
                        onChange={(e) => setState({ phone: e.target.value })} />
                    <button onClick={sendPhone}>立即领取</button>
                </section> :
                <section>
                    <button onClick={openApp}>下载APP</button>
                </section>
            }
            <footer>
                <p className={styles.ad}>球掌门最近30天连红大神</p>
                <div className={styles.cell}>
                    {state().list.map((item: any, index) => {
                        return <div className={styles.cell_main} key={index}>
                            <img src={item?.user?.avatar} alt='' />
                            <span className={styles.cell_nick}>{item?.user?.nick}</span>
                            <span className={styles.cell_grade}>{item.lianhong}连红</span>
                        </div>
                    })}
                </div>
                <div className={styles.font}>
                    <img src='/images/beian.jpg' alt='' />
                </div>
            </footer>
        </div>
    )
}

export default Promotion;
