import React, { Fragment } from 'react';
import { Header, Line } from '@component/index';
import { messageList } from '@/services/userServices';
import { useMount, useList } from 'react-use';
import { Flex, WingBlank } from 'antd-mobile';
import moment from 'moment';

const Message = () => {
    const [list, { set }] = useList([]);

    useMount(async () => {
        const result: any = await messageList();
        if (result != null) {
            set(result);
        }
    });

    return (
        <div className='page'>
            <Header title='我的消息' back={true} />
            <WingBlank size='md' className=''>
                {list.map((item: any) => {
                    return <Fragment key={item._id}>
                        <Flex align='start' justify='between' className='pt10 pb10'>
                            <img className='w15' style={styles.img} src={item.fromInfo.avatar} width='44' alt="" />
                            <span className='w60 c3 font14 pl10'>{item.text}</span>
                            <span className='w25 c9 font12'>{moment(item.updatedAt).format('YYYY-MM-DD')}</span>
                        </Flex>
                        <Line style={{ marginLeft: '15%' }} />
                    </Fragment>
                })}
            </WingBlank>
        </div>
    )
}

const styles = {
    img: {
        width: '44px',
        borderRadius: '50%'
    }
}
export default Message;
