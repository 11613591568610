import React, { useState } from 'react';
import { ConnectProps } from '@/react-app-env';
import { PullToRefresh, WingBlank } from 'antd-mobile';
import { useMount, useList, useGetSetState } from 'react-use';
import { excellentUsers, excellentList } from '@/services/listServices';
import Avatar from '@/component/Ranking/Avatar';
import UserMatch from '@component/Ranking/UserMatch';
import { constant } from '@/model/constant';
import { NoData } from '@/component';

const Celebrity = (props: ConnectProps) => {
  const [state, setState] = useGetSetState({
    userList: [],
    refresh: false
  })
  const [list, { set, updateAt, removeAt }] = useList<any>([]);
  const [isBottom, setBottom] = useState(false);

  useMount(() => {
    getUsers();
    getList('', []);
  })

  const onRefresh = (id: string, index: number) => {
    let l = list[index];
    l['forecasts'].forEach((value: any) => {
      if (id === value._id) {
        value.isLock = false;
        value.order = { _id: new Date() };
      }
    });
    updateAt(index, l);
  }

  const deleteOneMatch = (index: number) => {
    removeAt(index);
  }

  const getUsers = async () => {
    const result: any = await excellentUsers();
    if (result != null) {
      setState({ userList: result });
    }
  }

  const getList = async (_id: string, dataArray: Array<any>) => {
    const result: any = await excellentList(_id);
    if (result != null) {
      set(dataArray.concat(result));
      setState({ refresh: false });
      setBottom(result.length < constant.perpage)
    }
  }

  return (
    <PullToRefresh
      getScrollContainer={() => {
        return ''
      }}
      distanceToRefresh={50}
      damping={100}
      style={{
        height: document.documentElement.clientHeight - 95 - 43.5,
        overflowY: 'auto',
      }}
      indicator={{}}
      direction={'down'}
      refreshing={state().refresh}
      onRefresh={() => {
        setState({ refresh: true });
        getUsers();
        getList('', []);
      }}
    >
      <div>
        <WingBlank size='md'>
          <Avatar {...props} list={state().userList} />
        </WingBlank>
        {list.map((item: any, index: number) => {
          return <UserMatch key={item._id} index={index} refresh={onRefresh} {...props} data={item} deleteOneMatch={deleteOneMatch} />
        })}
        {list.length === 0 && <NoData title='暂无赛事竞猜' className='mt10' />}
        {!isBottom &&
          <div className="font13 c6 text-center pt10 pb10"
            onClick={() => { getList(list[list.length - 1]._id, list) }}>加载更多</div>
        }
      </div>
    </PullToRefresh>
  )
};

export default Celebrity;
