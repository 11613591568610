/**
 * @description 比分模块数据
 */
import { request, urls } from '@/services/request';

/**
 * 比分列表接口
 * @param status number 状态 
 * @param date string 日期
 */
export async function nightmareBifen(status: number, date: string) {
  const url = urls.nightmareBifen;
  let data = { status, date };

  const result = await request({ url, data });
  return result;
}

/**
 * 比分关联预测列表
 * @param nightmareId 比赛id
 * @param perpage 数量
 */
export async function forecastList(nightmareId: number, perpage: number = 100) {
  const url = urls.forecastList;
  let data = { nightmareId, perpage };

  const result = await request({ url, data });
  return result;
}

/**
 * 预测详情
 * @param forecastId 预测id
 */
export async function forecastDetail(forecastId: number) {
  const url = urls.forecastDetail;
  let data = { forecastId };

  const result = await request({ url, data });
  return result;
}

/**
 * 购买预测
 * @param _id 预测id
 */
export async function orderAdd(_id: string) {
  const url = urls.orderAdd;
  const data = { _id, types: 'forecast' };

  const result = await request({ url, data });
  return result;
}

/**
 * 获取赛事数据 --bbs发布、擂台赛发布
 */
export async function nightmare500() {
  const url = urls.nightmare500;

  const result = await request({ url });
  return result;
}