import React from 'react';
import { InputItem, WingBlank, Flex, Button, Toast } from 'antd-mobile';
import styles from './Login.module.scss';
import { useGetSetState } from 'react-use';
import { ConnectProps } from '@/react-app-env';
import { sendCaptcha, registerByMobile } from '@/services/userServices';

const Register = ({ history }: ConnectProps) => {
  const [state, setState] = useGetSetState({
    phone: '',
    code: '',
    password: '',
    getCode: false,
    time: 60
  });
  let timer: NodeJS.Timeout | null = null;

  const register = async () => {
    const result = await registerByMobile(state().phone, state().code, state().password);
    if (result != null) {
      Toast.info('注册成功', 1.5, () => history.replace('/login'));
    }
  }

  const getCode = async () => {
    if (state().phone.length < 11) {
      return Toast.info('请输入正确手机号')
    }
    const result = await sendCaptcha(state().phone);
    if (result != null) {
      Toast.info('验证码发送成功');
      setState({ getCode: true });
      timer = setInterval(() => {
        let time = state().time;
        if (time === 0) {
          clearInterval(timer as NodeJS.Timeout);
          setState({
            getCode: false,
            time: 60
          });
        } else {
          setState({
            time: time - 1
          });
        }
      }, 1000);
    }
  }

  return (
    <WingBlank size='md'>
      <div className='text-right mt20'>
        <img onClick={() => history.goBack()} className={styles.close_img} src="/images/login_ico_Close@2x.png" alt="" />
      </div>
      <div className='text-center mt20 mb30'>
        <img className='mt20' width='30%' src="/images/login_ico_logo@2x.png" alt="" />
      </div>

      <Flex align='center' direction='column' className='text-center'>
        <div className='w80'>
          <InputItem onChange={val => setState({ phone: val })} maxLength={11} type='text' className={styles.input} clear placeholder="请输入手机号"></InputItem>
        </div>
        <div className='w80 mt20'>
          <InputItem onExtraClick={() => getCode()} extra={state().getCode ? state().time + 'S' : '获取验证码'} onChange={val => setState({ code: val })} maxLength={6} type='text' className={styles.input} clear placeholder="请输入验证码"></InputItem>
        </div>
        {/* 禁用填充 */}
        <div style={{ width: 0, height: 0 }}>
          <InputItem maxLength={6} type='text'></InputItem>
        </div>
        <div style={{ width: 0, height: 0 }}>
          <InputItem type='password'></InputItem>
        </div>
        {/* ---end--- */}
        <div className='w80 mt20'>
          <InputItem onChange={val => setState({ password: val })} value={state().password} type='password' className={styles.input} clear placeholder="请输入密码"></InputItem>
        </div>
        <div className='w80 mt20'>
          <Button onClick={() => register()} className={styles.button} type='primary'>注册</Button>
        </div>
      </Flex>

      <Flex align='center' justify='center' className='mt20'>
        <div onClick={() => history.replace('/login')}>
          <span className='c6 font13'>去登陆</span>
        </div>
        <span className='c6 font13 ml5 mr5'>|</span>
        <div onClick={() => history.push('/quickLogin')}>
          <span className='c6 font13'>手机快捷登录</span>
        </div>
      </Flex>
    </WingBlank>
  );
}

export default Register;
