import React from 'react';
import styles from './detail.module.scss';
import { Flex } from 'antd-mobile';

interface MatchProps {
  list: any;
}
function Match(props: MatchProps) {
  const { list } = props;

  return <div>
    {list.map((item: any) => {
      let resultSrc
      if (item.is_win) {
        item.is_win === 1 && (resultSrc = '/images/commonsuccess.png')
        // item.is_win === 2 && (resultSrc = require('../../assets/images/commonfile.png'))
        // item.is_win === 3 && (resultSrc = require('../../assets/images/commontuihui.png'))
      }
      if (!item.match_info?.current_data?.matchInfo.match_id) { return null }
      return <div key={item.id} className={styles.main_data}>
        {resultSrc && <img src={resultSrc} className={styles.position} alt='' />}
        <div className={styles.main_data_position}>
          <span className={styles.main_p_text}>{item.match_info.current_data.matchInfo.code}</span>
        </div>
        <Flex align='center' justify='center' className='mt2'>
          <span className={styles.main_data_country_text}>{item.match_info.current_data.matchInfo.zhu_full_name}</span>
          <span className={styles.vs2}>vs</span>
          <span className={styles.main_data_country_text}>{item.match_info.current_data.matchInfo.ke_full_name}</span>
        </Flex>
        <div className={styles.info_detail}>
          <div className={styles.main_info_wrap}><span className={styles.main_info_text_one}>推荐</span></div>
          <div style={{ width: 20 }}></div>
          <div className={styles.main_info_wrap}>
            {item.match_info.current_data.matchInfo.select.type === 'daxiao' && <span className={styles.main_info_text_two}>{item.match_info.current_data.matchInfo.select.title}</span>}
            {item.match_info.current_data.matchInfo.select.type === 'yazhi' && <span className={styles.main_info_text_two}>{item.match_info.current_data.matchInfo.select.title}</span>}
            {(item.match_info.current_data.matchInfo.select.type === 'spf' || item.match_info.current_data.matchInfo.select.type === 'rqspf') && <span className={styles.main_info_text_two}>{item.match_info.current_data.matchInfo.select.full_name}:{item.match_info.current_data.matchInfo.select.value}</span>}
          </div>
        </div>
      </div>
    })}
  </div>;
}

export default Match;