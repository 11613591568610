import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import routerConfig from './index';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import Layout from '@/component/layouts/layout';

interface ItemType {
  path: string;
  exact?: boolean;
  component: any;
}

/**
 * 渲染路由组件
 */
function renderNormalRoute(item: ItemType, index: number) {
  return item.component ? (
    <CacheRoute
      key={index}
      path={item.path}
      component={item.component}
      exact={item.exact}
    />
  ) : null;
};

function AppRouter() {
  return (
    <Router>
      <Layout>
        <CacheSwitch>
          {/* 渲染路由表 */}
          {routerConfig.map(renderNormalRoute)}
          <Route path="/*"
            render={() =><Redirect to='/'/>}
          />
        </CacheSwitch>
      </Layout>
    </Router>
  );

}

export default AppRouter;
