import { request, urls } from '@/services/request';

/**
 * 关注
 */
export async function focusRequest(userId: number | string) {
  const url = urls.watchUserAdd;
  const data = { userId };

  const result = await request({ url, data });
  return result;
}

// 取消关注
export async function unfocusRequest(userId: number | string) {
  const url = urls.unfollowUser;
  const data = { userId };

  const result = await request({ url, data });
  return result;
}
