import React, {useState} from 'react';
import { Flex, Toast } from 'antd-mobile';
import styles from './CategoryCell.module.scss';
import { userCircleJoin } from '@/services/bbsServices';

function CategoryCell({ data, onTab, index, onJoin }: any) {
  const [state, setState] = useState(data);
  // console.log(data);
  const joinEvent = async (st: number) => {
    const result = await userCircleJoin(data.id);
    if (result != null) {
      Toast.info(st ? '加入圈子成功' : '退出圈子成功');
      setState({
        ...state,
        isJoin: st
      });
      onJoin(index, st);
    }
  }

  return (
    <div className='mt10 bg-white p10 relative'>
      <Flex align='start' className='mt5'>
        <div className='w20' onClick={() => onTab(index, data.id, data.showMatch, data)}>
          <img className={styles.img} src={state.imgUrl} alt="" />
        </div>
        <div className='w80 pl5'>
          <div>
            <p className={styles.title}>{state.name}</p>
            {Boolean(!state.isJoin) && <span className={styles.join} onClick={() => joinEvent(1)}>加入</span>}
            {Boolean(state.isJoin) && <span className={styles.hasJoin} onClick={() => joinEvent(0)}>已加入</span>}
          </div>
          <div className={styles.text} onClick={() => onTab(index, data.id,  data.showMatch, data)}>
            <p className='line-clamp lineNumber-2'>{state?.comment?.substr(0, 30)}</p>
          </div>
          <div className={styles.status}>
            <span><b className='color-primary'>{state.itemNum}</b>动态</span>
            <span className='ml10'><b className='color-primary'>{state.userNum}</b>圈友入场</span>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default CategoryCell;
