import React from 'react';
import { Header, Line } from '@component/index';
import { Toast, InputItem, Checkbox, Flex, Button } from 'antd-mobile';
import styles from './CardPage.module.scss';
import { useGetSetState, useMount } from 'react-use';
import { userGetProperty, puserInfo } from '@/services/payServices';
import { ConnectProps } from '@/react-app-env';
import util from '@/util/index';
import qs from 'qs';

const AgreeItem = Checkbox.AgreeItem;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps: object | undefined;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: (e: { preventDefault: () => any; }) => e.preventDefault(),
    };
}

interface ParamProps {
    page: string;
    useageType?: string;
}
enum Name { zmb = '掌门币', forum = '魔币' };

const RechargePage = (props: ConnectProps) => {
    const { location, history } = props;
    const param = util.parseUrl(location.search) as ParamProps;
    const useageType = param.useageType === 'forum' ? 'forum' : 'zmb';

    const [state, setState] = useGetSetState({
        myInfo: {
            chargeAvail: 0,
            cashAvail: 0,
            income_amont: 0
        },
        unit: Name[useageType],
        checked: true,
        text: '10'
    })

    useMount(() => {
        if (useageType === 'forum') {
            getMoney();

        } else {
            getData();
        }
    })

    const getData = async () => {
        const result: any = await userGetProperty();
        if (result != null) {
            setState({ myInfo: result });
        }
    }

    const getMoney = async () => {
        const result: any = await puserInfo();
        if (result != null) {
            setState({ myInfo: result })
        }
    }
    const _zmbCard = () => {
        return <div className={styles.wrap}>
            <div className={styles.c_linear}>
                <div className='pl10 pr10'>
                    <div className={styles.wrapTitle}>
                        <img className={styles.titleImage} src='/images/kb-icon-1@2x.png' alt='' />
                        <span className={styles.title}>掌门币</span>
                    </div>
                    <Line style={{ backgroundColor: '#f39c99', margin: '22px' }} />
                    <div className={styles.bottom}>
                        <div className='flex'>
                            <span className='white font16'>余额：</span>
                            <span
                                className='white font18 w700'>{state().myInfo.chargeAvail ? (state().myInfo.chargeAvail / 100).toFixed(2) : 0}掌门币</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const _forumCard = () => {
        return <div>
        <div className={styles.forum}>
            <div className='pl10 pr10'>
                <div className='pt15 relative'>
                    <img
                        style={{ width: '60px', height: '60px', position: 'absolute', right: 0, bottom: 0 }}
                        src='/images/douzi.png' alt=''/>
                    <p className='font14 white'>剩余魔豆</p>
                    <span className='font30 white w500' >{state().myInfo.income_amont}</span>
                </div>
                <Line style={{ backgroundColor: '#f39c99', margin: '10px 0' }} />
                <Flex justify='between' align='center' className='mt20'>
                        <span className='font18' style={{ color: '#f7de4b' }}>+{~~state().text > 0 ? ~~state().text * 1000 : 0}魔豆</span>
                        <span className='font14 white'>10元=10000魔豆</span>
                </Flex>
            </div>
        </div>
    </div>
     }
    const pay = () => {
        let reg = /^\d+$/;
        if (!state().checked) {
            Toast.info('请阅读并同意支付协议');
            return;
        }
        ;
        if (!reg.test(state().text)) {
            Toast.info(`请输入整数`);
            return;
        }
        if (state().text === '0' || state().text < '1') {
            Toast.info(`充值${state().unit}数量最小为1`);
            return;
        }
        history.push('pay?' + qs.stringify({ money: state().text, useageType, page: param.page }));
    }
    return (
        <div className='page bg-f8'>
            <Header title={param?.useageType === 'forum' ? '魔豆充值' : '充值'} back={true} outerContainerStyles={{ backgroundColor: param?.useageType === 'forum' ? '#ed715a' : '#484d59' }} />
            {param?.useageType === 'forum' ? _forumCard() : _zmbCard()}
            <div className='bg-white mt30 pt2 pb2'>
                <div className='w100'>
                    <InputItem
                        type={'money'}
                        defaultValue={state().text}
                        moneyKeyboardAlign="left"
                        moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        placeholder='请输入金额'
                        extra='元'
                        onChange={(v) => { setState({ text: v }) }}
                    ><span className='font16 c9'>购买{state().unit}:</span></InputItem>
                </div>
            </div>

            <Flex align='center' direction='column' className='text-center'>
                <AgreeItem defaultChecked={state().checked} onChange={(e: any) => setState({ checked: e.target.checked })}>
                    <span className='font12 c6' onClick={() => window.location.href = '/pay.html'}>我已阅读并同意支付协议</span>
                </AgreeItem>
            </Flex>

            <div className='text-center pl10 pr10' style={{ marginTop: '20vh' }}>
                <Button onClick={() => pay()} className='br25' type='primary' >下一步</Button>
            </div>
        </div>
    )
}

export default RechargePage;
