/**
 * @description 我的卡包
 */
import React from 'react';
import { Header, Line } from '@component/index';
import { WingBlank, Toast, Flex } from 'antd-mobile';
import styles from './CardPage.module.scss';
import { useGetSetState, useMount } from 'react-use';
import { userGetProperty, puserInfo } from '@/services/payServices';
import { ConnectProps } from '@/react-app-env';

const CardPage = (props: ConnectProps) => {
    const { history } = props;

    const [state, setState] = useGetSetState({
        myInfo: {
            chargeAvail: 0,
            cashAvail: 0
        },
        userInfo: {
            income_amont: 0,
            received_amont: 0
        }
    })

    useMount(() => {
        getData();
        getMoney();
    })

    const getData = async () => {
        const result: any = await userGetProperty();
        if (result != null) {
            setState({ myInfo: result });
        }
    }

    const getMoney = async () => {
        const result: any = await puserInfo();
        if (result != null) {
            setState({ userInfo: result })
        }
    }

    const recharge = (type = '') => {
        history.push('/recharge?page=card&useageType=' + type);
    }

    return (
        <div className='page bg-48'>
            <Header title='我的卡包' back={true} outerContainerStyles={{ background: '#484d59' }} />
            <WingBlank size='md'>
                <div className={styles.linear}>
                    <div className='pl10 pr10 mt10'>
                        <div className={styles.wrapTitle}>
                            <img className={styles.titleImage} src='/images/kb-icon-1@2x.png' alt='' />
                            <span className={styles.title}>掌门币</span>
                        </div>
                        <Line style={{ backgroundColor: '#f39c99', margin: '22px' }} />
                        <div className={styles.bottom}>
                            <div className='flex'>
                                <span className='white font16'>余额：</span>
                                <span
                                    className='white font18 w700'>{state().myInfo.chargeAvail ? (state().myInfo.chargeAvail / 100).toFixed(2) : 0}掌门币</span>
                            </div>
                            <div onClick={() => {
                                recharge()
                            }} className={styles.button}>
                                <span className='font16' style={{ color: '#ff5959' }}>充值</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.linearGreen}>
                    <div className='pl10 pr10 mt10'>
                        <div className={styles.wrapTitle} style={{justifyContent: 'space-between'}}>
                            <Flex align='center'>
                                <img className={styles.titleImage} src='/images/kb-icon-3@2x.png' alt='' />
                                <span className={styles.title}>魔豆: 打赏/收获</span>
                            </Flex>
                            <Flex align='center'>
                               <i className='iconfont icongift font22 color-4b'></i>
                                <span className='font16 w500 ml5 color-4b'>兑换礼品</span>
                            </Flex>
                        </div>
                        <Line style={{ backgroundColor: 'rgb(190, 226, 156)', margin: '22px' }} />
                        <div className={styles.bottom}>
                            <div>
                                <p className='white font16'>可打赏魔豆：<span className='w700 font18'>{state().userInfo.income_amont || 0}</span></p>
                                <p className='white font16'>已获得魔豆：<span className='w700 font18'>{state().userInfo.received_amont || 0}</span></p>
                            </div>
                            <div onClick={() => {
                                recharge('forum')
                            }} className={styles.button}>
                                <span className='font16' style={{ color: '#ff5959' }}>充值</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.linearYellow}>
                    <div className='pl10 pr10 mt10'>
                        <div className={styles.wrapTitle}>
                            <img className={styles.titleImage} src='/images/kb-icon-2@2x.png' alt='' />
                            <span className={styles.title}>平台奖励</span>
                        </div>
                        <Line style={{ backgroundColor: '#f6c37e', margin: '22px' }} />
                        <div className={styles.bottom}>
                            <div className='flex'>
                                <span className='white font16'>您获得的收益：</span>
                                <span
                                    className='white font18 w700'>{state().myInfo.cashAvail ? (state().myInfo.cashAvail / 100).toFixed(2) : 0}元</span>
                            </div>
                            <div onClick={() => { Toast.info('请下载APP进行提现', 3) }} className={styles.button}>
                                <span className='font16' style={{ color: '#ff5959' }}>提现</span>
                            </div>
                        </div>
                    </div>
                </div>
            </WingBlank>
        </div>
    )
}

export default CardPage;
