import React from 'react'
import { ConnectProps } from '@/react-app-env';
import qs from 'qs';
import { useMount } from 'react-use';
import { wxLogin } from '@/services/userServices';
import { Toast } from 'antd-mobile';

const RedirectPage = (props: ConnectProps) => {
  const { location } = props;
  const param = qs.parse(location.search.replace('?', ''));

  useMount(() => {
      if (param?.code && param?.state === 'wechatlogin') {
        wechatLogin();
      }
  })

  const wechatLogin = async () => {
    const result = await wxLogin(param.code as string, 'h5');
    if (result != null) {
      Toast.info('登录成功');
      localStorage.setItem('user', JSON.stringify(result));
      window.location.href = '/';
    }
  }

  return (
    <div>
    </div>
  )
}

export default RedirectPage;
