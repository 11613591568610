import React, { useContext } from 'react';
import styles from './detail.module.scss';
import { Flex, Toast, Modal } from 'antd-mobile';
import Line from '@/component/common/Line';
import { giftList, giftReward } from '@/services/bbsServices';
import { Context } from '@/pages/Bbs/BbsDetail'
import { useMount, useGetSetState } from 'react-use';
import { ConnectProps } from '@/react-app-env';
import qs from 'qs';

interface RewardProps extends ConnectProps {
  data: any;
}
function Reward(this: any, props: RewardProps) {
  const { data, history } = props;
  const { likes } = useContext(Context);
  const [state, setState] = useGetSetState({
    list: [],
    open: false
  });

  useMount(async () => {
    const result: any = await giftList();
    if (result != null) {
      setState({ list: result.list });
    }
  })

  const onChangeSendGrid = () => {
    setState({ open: true })
  }

  const onSendGrid = (item: any) => {
    if (item.gift_money < 10000) {
      return sendHttp(item);
    }
    Modal.alert('赠送礼物', `是否确定赠送${item.gift_name},价值${item.gift_money}魔豆`, [
      { text: '取消', onPress: () => { } },
      {
        text: '确定', onPress: () => {
          sendHttp(item);
        }
      }
    ])
  }

  const sendHttp = async (item: any) => {
    const result = await giftReward(data.article_id, item.id);
    /**
     * 注意：此处需要刷新评论列表接口
     */
    if (result != null) {
      setState({ open: false });
      Toast.info('赠送成功');
    }
  }

  return (
    <div className={styles.reward_wrap}>
      <Flex align='center' justify='between'>
        <Flex align='center' className='mt10'>
          <span className={styles.reward_top_left}>打赏贵人</span>
          {data?.top_list?.length ? data.top_list.map((item: any, index: string | number | undefined) => {
            return <img
              key={index}
              src={item.user_info.user_avatar}
              onClick={() => history.push('/user/center?' + qs.stringify({
                _id: item?.user_info?.app_user_id, // 用户id
                id: item?.user_info?.id // 论坛用户id
              }))}
              className={styles.reward_top_image}
              alt=''
            />
          }) : <div><span className='font12 c6 ml5'>暂无打赏</span></div>}
        </Flex>
        <Flex align='center' onClick={() => onChangeSendGrid()}>
          <i className='iconfont icongift font20 color-primary'></i>
          <span className={styles.send_text}>赠送</span>
        </Flex>
      </Flex>
      <Flex align='center' justify='between' className='mt10'>
        <Flex align='center' justify='center'>
          <div className={styles.reward_top_border}>
            <span className={styles.reward_top_border_text}>月红</span>
          </div>
          <span className={styles.send_text}>{data.user_info?.user_win}</span>
        </Flex>
        <Flex align='center' justify='center'>
          <Flex align='center' justify='center' onClick={() => likes()}>
            <i className={`iconfont iconlike font16 ${data.is_liked ? 'color-primary' : 'c6'}`}></i>
            <span className={`${styles.reward_bottom_text} ${data.is_liked ? 'color-primary' : 'c6'}`}>{data.liked || 0}</span>
          </Flex>
          <Flex align='center' justify='center'>
            <i className='iconfont iconshare c6 font15 ml10'></i>
            <span className={styles.reward_bottom_text}>分享</span>
          </Flex>
          <Flex align='center' justify='center'>
            <i className='iconfont iconmessage c6 font15 ml10'></i>
            <span className={styles.reward_bottom_text}>{data.reply || 0}</span>
          </Flex>
        </Flex>
      </Flex>
      <Line style={{ backgroundColor: "#eaeaea", marginTop: '10px' }} />
      <Modal
        popup
        visible={state().open}
        onClose={() => setState({ open: false })}
        animationType="slide-up"
      >
        <Flex wrap='wrap' className={styles.gift_wrap}>
          {[state().list.map((item: any, index: number) => {
            return <div key={index} className={styles.gift_card_wrap}>
              <div onClick={() => onSendGrid(item)}>
                <img className={styles.gift_card_img} src={item.gift_img} alt='' />
                <p className={styles.gift_card_text}>银豆{item.gift_money}</p>
                <p className={styles.gift_card_text}>{item.gift_name}</p>
              </div>
            </div>
          })]}
        </Flex>
      </Modal>
    </div>
  );
}

export default Reward;
