import React from 'react';
import styles from './ExpertsHeader.module.scss';
import { Flex } from 'antd-mobile';

function ExpertsHeader() {
    const score = JSON.parse(localStorage.getItem('score') as string);
    let zhu_score = score.zhu_score || score.zhu_score === 0;
    let ke_score = score.ke_score || score.ke_score === 0;
    return (
        <div className={styles.header}>
            <Flex align='center' justify='center'>
                <img className={styles.image} src={score.teamZhu.logoUrl} alt='' />
                <span className={styles.text}>{score.zhu_full_name}</span>
                {zhu_score && <div className={`${styles.block_yellow} ml5`}>
                    <span className={styles.text}>{score.zhu_score}</span>
                </div>}
                <span className={`${styles.text} ml5 mr5`} style={{ marginBottom: '3px' }}>-</span>
                {ke_score && <div className={`${styles.block_yellow} mr5`}>
                    <span className={styles.text}>{score.ke_score}</span>
                </div>}
                <span className={styles.text}>{score.ke_full_name}</span>
                <img className={`${styles.image} ml5`} src={score.teamKe.logoUrl} alt='' />
            </Flex>
            <Flex justify='center' className='mt5'>
                <span className={styles.small_text}>{score.code}</span>
                <span className={`${styles.small_text} ml5`}>{score.result.sh}</span>
                <span className={`${styles.small_text} ml20`}>比赛时间：{score.game_start}</span>
            </Flex>
        </div>
    )
}

export default ExpertsHeader;
