import React from 'react';
import { circleList } from '@/services/bbsServices';
import { useMount, useGetSetState } from 'react-use';
import CategoryCell from '@component/Bbs/CategoryCell';
import { PullToRefresh } from 'antd-mobile';

const Category = (props: { onTab: Function, onJoin: Function }) => {
  const [state, setState] = useGetSetState({
    page: 1,
    refreshing: false,
    list: []
  })
  useMount(() => {
    getList();
  })

  const getList = async () => {
    const result: any = await circleList(state().page);
    if (result != null) {
      setState({ list: result.list })
    }
    setState({ refreshing: false })
  }

  return (
    <PullToRefresh
      getScrollContainer={() => {
        return ''
      }}
      distanceToRefresh={50}
      damping={100}
      style={{
        height: document.documentElement.clientHeight - 50 - 43.5 + 'px',
        overflowY: 'auto',
      }}
      indicator={{}}
      direction={'down'}
      refreshing={state().refreshing}
      onRefresh={() => {
        setState({ refreshing: true })
        getList();
      }}
    >
      {state().list.map((item, index) => {
        return <CategoryCell key={index} data={item} onTab={props.onTab} onJoin={props.onJoin} index={index}/>
      })}
    </PullToRefresh>
  )
}

export default Category;
