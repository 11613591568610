import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { Flex } from 'antd-mobile';
import { yinglilvList, mingzhonglvList, lianhongList, unlimitedLianhong } from '@/services/listServices';
import { useMount, useGetSetState } from 'react-use';
import styles from './AdMain.module.scss';
import { listModel } from '@/model/list';
import qs from 'qs';

interface AdMainProps extends ConnectProps {
  type: string;
}

function AdMain(props: AdMainProps) {
  const [, setModel] = listModel();

  const { type, history, location } = props;
  const [state, setState] = useGetSetState({
    nameList: [
      { label: '盈利率', value: '0' },
      { label: '命中率', value: '1' },
      { label: '连红', value: '2' },
    ],
    nameId: 0,
    list: []
  })

  useMount(() => {
    if (type === 'common') {
      return getList();
    }
    if (type === 'lianhong') {
      return getLian();
    }
  });

  const gaoShou = () => {
    const id = state().nameId;
    let index = 0;
    if (id === 2) {
      index = 1
    }
    setModel({ pageIndex: 2, comeInTwoTab: index });
    if (location.pathname.indexOf('list') <= -1) {
      history.replace('/list')
    }
  }

  const getList = async () => {
    let result: any = null;
    switch (state().nameId) {
      case 0:
        result = await yinglilvList();
        break;
      case 1:
        result = await mingzhonglvList();
        break;
      case 2:
        result = await lianhongList();
        break;
    }
    if (result != null) {
      setState({
        list: result,
      })
    }
  }

  const getLian = async () => {
    const result: any = await unlimitedLianhong();
    if (result != null) {
      setState({
        list: result,
        nameId: 2
      })
    }
  }

  const getTypeData = (index: number) => {
    setState({ nameId: index });
    getList();
  }

  const _header = () => {
    const map: any = { common: '球掌门高手推荐', lianhong: '球掌门最近30天内连红大神' }
    return <Flex justify='between' align='center'>
      <Flex justify='between' align='center'>
        <img className={styles.header_icon} src='/images/List_ico_gold@2x.png' alt='' />
        <span className='c3 font13'>{map[type]}</span>
      </Flex>
      <Flex justify='between' align='center'>
        {type === 'common' && state().nameList.map((name: any, index: number) => {
          let textColor = index === state().nameId ? 'green' : 'c6';
          return <Flex justify='between' align='center' key={name.value}>
            <div onClick={() => getTypeData(index)}>
              <span className={`font13 ${textColor}`}>{name.label}</span>
            </div>
            {index <= 1 && <Flex className={styles.borderStyle}></Flex>}
          </Flex>
        })}
      </Flex>
    </Flex>
  }

  return (
    <div className='mb10 mt10'>
      {_header()}
      <div className={styles.wrap} onTouchMove={(e) => e.stopPropagation()}>
        {state().list.map((item: any) => {
          const { user } = item;
          const nameId = state().nameId;
          return <div key={item._id} className={styles.avCell}
            onClick={() => history.push('/user/center?' + qs.stringify({
              _id: item?.user?._id, // 用户id
              id: item?.bbsUser?.id, // 论坛用户id
            })
            )}>
            <img className={styles.img} src={user.avatar} alt='' />
            <p className='c6 font14 w600 mb2'>{user.nick}</p>
            {nameId === 0 &&
              <p className='color-primary font14 w600'>
                盈利率{Math.round(item.yinglilv * 100) + '%'}</p>}
            {nameId === 1 &&
              <p className='color-primary font14 w600'>
                命中率{Math.round(item.mingzhonglv * 100) + '%'}</p>}
            {nameId === 2 &&
              <p className='color-primary font14 w600'>
                {item.lianhong || 0}连红</p>}
          </div>
        })}
      </div>
      <div className='text-center' onClick={() => gaoShou()}>
        <span className='c3 font13 w600'>更多高手查看排行榜</span>
      </div>
    </div>
  )
}

export default AdMain
