import React from 'react';
import './Notify.scss';

function Notify(props: { show: boolean, title: string }) {

  return (
    <div className='Notify_F'>
      {props.show ? <div className='Notify'>
  <span className='white'>{props.title}</span>
      </div> : null}
    </div>
  )
}

export default Notify;
