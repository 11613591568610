import React from 'react';
import { ConnectProps } from '@/react-app-env';
import styles from '../ForecastCell.module.scss';
import { Flex } from 'antd-mobile';
import qs from 'qs';

interface ForecastCellProps extends ConnectProps {
    data: any;
    type?: string;
}
function Avatar(props: ForecastCellProps) {
    const { data: item, history, location } = props;
    const goUserCenter = () => {
      if (location.pathname === '/user/center') return;
      history.push('/user/center?' + qs.stringify({
        _id: item.user._id, // 用户id
        id: item.bbsUser.id, // 论坛用户id
        type: 'yazhi',
      }))
    }

    return (
        <Flex>
            <div className='w15' onClick={() => { goUserCenter() }}>
                <img className={styles.top_left_img} src={item.user.avatar} alt='' />
            </div>

            <div className={styles.top_center}>
                <Flex align='center'>
                    <span className='font15 w600 c3'>{item.user.nick}</span>
                    {item.userLevel.levelShow && <div className={styles.top_center_bg}>
                        <span className='font11 white'>{item.userLevel.times}中{item.userLevel.hitTimes || 0}</span>
                    </div>}
                </Flex>
                <Flex>
                    <span className={styles.top_center_text}>{item.bbsUser.userGradeName}</span>
                    <span className={styles.top_center_text}>{item.userLevel.monthLevel > 0 ? `上月排榜第${item.userLevel.monthLevel}名` : '暂未上榜'}</span>
                </Flex>
            </div>

            <div className='w30 text-right'>
                {item.userLevel.yinglilv > 0 && <p className={`${styles.top_right_scare} font18 w600`}>{item.userLevel.yinglilv}%</p>}
                {item.userLevel.yinglilv > 0 && <p className={`${styles.top_right_scare} font11`}>近{item.userLevel.times}场盈利率</p>}
            </div>
        </Flex>
    )
};

export default Avatar;