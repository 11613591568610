/**
 * @description 榜单模块状态
 * @author hayho
 */
import { createGlobalState } from 'react-use';

interface listProps {
    pageIndex: number;
    comeInTwoTab: number;
}
const listModel = createGlobalState<listProps>({
    pageIndex: 0,
    comeInTwoTab: 0
});


export { listModel };
export type { listProps };
