import React, { useState, Fragment, createContext } from 'react'
import { ConnectProps } from '@/react-app-env';
import ExpertsHeader from '@component/Score/ExpertsHeader';
import { Header, Line } from '@component/index';
import { useMount, useList } from 'react-use';
import { forecastList } from '@/services/scoreServices';
import { PullToRefresh, WingBlank } from 'antd-mobile';
import ForecastCell from '@component/Score/ForecastCell';

export const Context = createContext<any>(null);
const ExpertsRecommend = (props: ConnectProps) => {
  const [list, {set, updateAt}] = useList<any>([]);
  const [refreshing, setRefreshing] = useState(false);

  const { match } = props;
  const id = match.params.id;

  useMount(() => {
    getList();
  });

  const getList = async () => {
    const result: any = await forecastList(id);
    if (result) {
      set(result);
      setRefreshing(false);
    }
  };
  
  const onRefresh = (id: string) => {
    list.forEach((value: any, index) => {
      if (id === value._id) {
        value.isLock = false;
        value.order = { _id: new Date()};
        updateAt(index, value);
      }
    })
  }

  return (
    <div>
      <Header title='推荐市场' back={true} outerContainerStyles={{ backgroundColor: '#6fc47c' }} />
      <ExpertsHeader />
      <PullToRefresh
        getScrollContainer={() => {
          return ''
        }}
        distanceToRefresh={50}
        damping={100}
        style={{
          height: document.documentElement.clientHeight - 45 - 55,
          overflowY: 'auto',
        }}
        indicator={{}}
        direction={'down'}
        refreshing={refreshing}
        onRefresh={() => {
          setRefreshing(true);
          getList();
        }}
      >
        <Context.Provider value={{ list }}>
          <WingBlank size='md'>
            {list.map((item: { _id: string }) => {
              return <Fragment key={item._id}>
                <ForecastCell data={item} {...props} refresh={onRefresh}/>
                <Line />
              </Fragment>
            })}
          </WingBlank>
        </Context.Provider>
      </PullToRefresh>
    </div>
  )
};

export default ExpertsRecommend;
