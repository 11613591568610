/**
 * @description 支付模块数据
 */
import { request, urls } from '@/services/request';

/**
 * 个人账户
 */
export async function userGetProperty() {
  const url = urls.userGetProperty;

  const result = await request({ url });
  return result;
}

/**
 * 个人魔豆账户
 */
export async function puserInfo() {
  const url = urls.puserInfo;

  const result = await request({ url });
  return result;
}

/**
 * 充值
 */
interface payTypes {
  fen: number,
  title: string,
  // 'alipay'|'wechat'|'h5Wechat'|'jsApiWechat'
  type: string,
  useageType: 'zmb' | 'forum'
}
export async function paymentCreate(data: payTypes) {
  const url = urls.paymentCreate;

  const result = await request({ url, data });
  return result;
}

/**
 * jssdk
 */
export async function jsskdConfig(uri: string) {
  const url = urls.jsskdConfig;
  const data = {url: uri};

  const result = await request({ url, data });
  return result;
}
