import React from 'react';
import { Header } from '@/component';
import { ConnectProps } from '@/react-app-env';
import qs from 'qs';
import Square from './Square';

const NoJoinPage = (props: ConnectProps) => {
  const { match, location } = props;
  const id = match.params.id;
  const { name } = qs.parse(location.search.replace('?', ''));
  return (
    <div className='page'>
      <Header title={name as string} back={true} />
      <Square onJoin={() => {}} {...props} id={id} />
    </div>
  );
}

export default NoJoinPage;
