/**
 * @description 常量集合
 * @author hayho
 */

 /**
  * 比赛状态
  */
enum MatchStatus {
    '全部' = -1,
    '进行中' = 1,
    '已结束' = 3,
    '未开始' = 0,
    '已取消' = 2
};

const constant = {
  perpage: 20
}

export { MatchStatus, constant }