// require  loadable from '@/component';

import TabPage from '@/pages/TabPage';
import ArticleDetail from '@/pages/Home/ArticleDetail';
import ScoreList from '@/pages/Score/ExpertsRecommend';
import ForecastDetail from '@/pages/Score/ForecastDetail';
import Login from '@/pages/User/Login';
import Register from '@/pages/User/Register';
import QuickLogin from '@/pages/User/QuickLogin';
import CardPage from '@/pages/Pay/CardPage';
import RechargePage from '@/pages/Pay/RechargePage';
import PayPage from '@/pages/Pay/PayPage';
import Message from '@/pages/User/Message';
import PublishPage from '@/pages/Bbs/PublishPage';
import Crypto from '@/pages/Util/Crypto';
import BbsDetail from '@/pages/Bbs/BbsDetail';
import Finance from '@/pages/User/FinancePage';
import UserCenter from '@/pages/User/UserCenter';
import RedirectPage from '@/pages/User/RedirectPage';
import ConsumePage from '@/pages/User/ConsumePage';
import PaySuccess from '@/pages/Pay/PaySuccess';
import FontSizePage from '@/pages/Util/FontSizePage';
import NoJoinPage from '@/pages/Bbs/NoJoinPage';
import Promotion from '@/pages/Util/Promotion';
// import Test from '@/pages/Util/Test';

const routerConfig = [
  { path: '/', exact: true, component: TabPage },
  { path: '/score', component: TabPage },
  { path: '/forum', component: TabPage },
  { path: '/refer', component: TabPage },
  { path: '/users', component: TabPage },
  { path: '/article/detail/:id', component: ArticleDetail },
  { path: '/scoreRecommend/:id', component: ScoreList },
  { path: '/forecastDetail/:id', component: ForecastDetail },
  { path: '/login',component: Login },
  { path: '/register',component: Register },
  { path: '/quickLogin',component: QuickLogin },
  { path: '/card',component: CardPage },
  { path: '/recharge',component: RechargePage },
  { path: '/pay',component: PayPage },
  { path: '/message',component: Message },
  { path: '/publish/:id',component: PublishPage },
  { path: '/crypto',component: Crypto },
  { path: '/bbs/detail/:id',component: BbsDetail },
  { path: '/finance',component: Finance },
  { path: '/user/center',component: UserCenter },
  { path: '/redirect',component: RedirectPage },
  { path: '/consume',component: ConsumePage },
  { path: '/paysuccess',component: PaySuccess },
  { path: '/font',component: FontSizePage },
  { path: '/nojoin/:id',component: NoJoinPage },
  { path: '/promotion',component: Promotion },
  // { path: '/test',component: Test },
  // { path: '/*', redirect:  TabPage },
  // { path: '/use', component: Use },
];

export default routerConfig;
