import React from 'react';
import { Header } from '@/component';
import { ConnectProps } from '@/react-app-env';
import { Result } from 'antd-mobile';

const PaySuccess = (props: ConnectProps) => {
  const { history } = props;

  return (
    <div className='page'>
      <Header title='充值结果' leftContent={<i onClick={() => history.replace('/user')} className='iconfont iconarrowleft font17 bold'></i>} />
      <Result
        className='mt20'
        img={
          <img src='/images/article_ico_yes@3x.png' alt='' width='60px' />
        }
        title="充值成功"
        message=""
      />
    </div>
  )
}

export default PaySuccess;
