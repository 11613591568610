import React from 'react';
import { Header, NoData } from '@/component';
import { useMount, useList, useBoolean, useCounter } from 'react-use';
import { flowList } from '@/services/userServices';
import { List, SegmentedControl } from 'antd-mobile';
import moment from 'moment';


const Item = List.Item;
const FinancePage = () => {
  const Types: any = {
    forecast_cash: '让球盈利',
    arenafour_cash: '擂台盈利',
    forecast: '让球消费',
    arenafour: '擂台消费',
    promotion: '推广奖励',
    alipay: '支付宝充值',
    wechat: '微信充值',
    atm: '提现',
    forecast_canceled: '让球取消退款',
    forecast_promised: '让球不中退款',
    atm_canceled: '提现取消',
    atm_rejected: '提现被拒',
    admin_recharge: '后台充值'
  }

  const [list, { set }] = useList<any>([]);
  const [isBottom, setBottom] = useBoolean(false);
  const [index, { set: setCounter }] = useCounter(0);

  useMount(() => {
    getList('', 'consume');
  });

  const getList = async (id: string, type: 'consume' | 'cash') => {

    const result: any = await flowList(type, id);
    if (result != null) {
      set(id === '' ? result : list.concat(result));
      setBottom(result.length < 20);
    }
  }

  return (
    <div className='page bg-f8'>
      <Header title='资金明细' back={true} />
      <SegmentedControl
        values={['掌门币', '平台奖励']}
        selectedIndex={index}
        // tintColor={'#ff0000'}
        onChange={async (e: any) => { setCounter(e.nativeEvent.selectedSegmentIndex); getList('', e.nativeEvent.selectedSegmentIndex === 0 ? 'consume' : 'cash'); }}
        style={{ margin: '10px' }} />

      <List>
        {list.map((item: any) => {
          return <Item key={item._id}
            thumb={<img style={{ width: '40px', borderRadius: '50%', height: '40px' }} src={item.user.avatar} alt='' />}
            extra={item.fen > 0 ? <span className='color-primary font20'>+{(item.fen / 100).toFixed(2)}币</span> :
              <span className='c6 font20'>{(item.fen / 100).toFixed(2)}币</span>}
            onClick={() => { }}
          >
            <div>
              <p className='c6 font15'>{Types[item.types]}</p>
              <p className='c9 font11 mt5'>{moment(item.createdAt).format('YYYY年MM月DD日 HH:ss')}</p>
            </div>
          </Item>
        })}
      </List>
      {!isBottom &&
        <div className="font13 c6 text-center pt10 pb10"
          onClick={() => { getList(list[list.length - 1]._id,  index === 0 ? 'consume' : 'cash') }}>加载更多</div>
      }

      {list.length === 0 && <NoData className='mt10' />}
    </div>
  )
}

export default FinancePage;
