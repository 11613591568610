import React, { useContext } from 'react';
import { useList, useMount } from 'react-use';
import { nightmare500 } from '@/services/scoreServices';
import { PublishContext } from '@/pages/Bbs/PublishPage';
import { Flex, Toast, Button } from 'antd-mobile';
import styles from './Bifen.module.scss';
import { Line } from '@component/index';

function Bifen() {
  const { state, setState } = useContext(PublishContext);
  const [matchList, { set: setMatch }] = useList<any>([]);

  useMount(async () => {
    const result: any = await nightmare500();
    if (result != null) {
      setMatch(Array.from(result, ((item: any) => {
        const label = `${item.code} ${item.result.sh} ${item.game_start}`;
        const title = `${item.zhu_full_name} vs ${item.ke_full_name}`;
        return {
          _id: item._id,
          label,
          title,
          value: JSON.stringify({ ...item, label })
        }
      })));
    }
  })

  const onSelect = (v: string, index: number) => {
    console.log(11);
    const data = JSON.parse(v);
    setState({ biFenTitle: data.label, biFenData: data, biFenValue: {}, biFenIndex: index });
  }

  const onAddData = (item: string, number: number, title: string, name: string, type: string) => {
    if (number <= 6 && parseFloat(item) < 1.5) {
      return Toast.info('赔率1.5以下不可选')
    }

    const data = {
      type: type,
      title: title,
      value: item,
      full_name: name,
      sh: state.biFenData?.result?.sh
    }
    setState({
      selectIndex: number,
      biFenValue: data
    })
  }

  const biFenMain = () => {
    const { biFenData, selectIndex } = state;
    console.log(biFenData)
    const boolen = JSON.stringify(biFenData) === '{}';
    if (boolen) {
      return null;
    }

    type nameTypes = string[];
    let ya: nameTypes = [], daXiao: nameTypes = [];
    const first_obj = { 0: '胜', 1: '平', 2: '负', 3: '让胜', 4: '让平', 5: '让负' };
    const daxiaoBoolen = biFenData.daxiao.current ? true : false;
    const yaBoolen = biFenData.yazhi.current ? true : false;
    if (yaBoolen) {
      let p = biFenData.yazhi.current.pan;
      ya = [`${biFenData.zhu_full_name}${p >= 0 ? '+' + p : p} 胜${biFenData.yazhi.current.zhu}`, `${biFenData.ke_full_name}${p === 0 ? '-' : ''}${p >= 0 ? p * -1 : '+' + p * -1} 胜${biFenData.yazhi.current.ke}`]
    }
    if (daxiaoBoolen) {
      daXiao = [`大于${biFenData.daxiao.current.pan}球 胜${biFenData.daxiao.current.da || 0}`, `小于${biFenData.daxiao.current.pan}球 胜${biFenData.daxiao.current.xiao || 0}`]
    }
    return <div>
      <Flex justify='between' align='center' wrap='wrap'>
        {[...biFenData.spf, ...biFenData.rqspf].map((item: any, i: number) => {
          let title = `${first_obj[i as 0 | 1 | 2 | 3 | 4 | 5]}(${item})`
          return <div key={i}
            onClick={() => onAddData(item, i + 1, title, first_obj[i as 0 | 1 | 2 | 3 | 4 | 5], 'spf')}
            className={`${styles.result} ${selectIndex === i + 1 ? styles.s_result : ''}`}>
            <span className={`${styles.text} ${selectIndex === i + 1 ? styles.s_text : ''}`}>
              {i > 2 ? biFenData?.result?.rq : ''}{title}
            </span>
          </div>
        })}
      </Flex>
      {yaBoolen && <Flex justify='between' align='center'>
        <div className={`${styles.result2} ${selectIndex === 7 ? styles.s_result2 : ''}`}
          onClick={() => onAddData(biFenData.yazhi?.current.zhu, 7, ya[0], biFenData.yazhi?.current?.pan, 'yazhi')}
        >
          <span className={`${styles.text2} ${selectIndex === 7 ? styles.s_text2 : ''}`}>{ya[0]}</span>
        </div>
        <div className={`${styles.result2} ${selectIndex === 8 ? styles.s_result2 : ''}`}
          onClick={() => onAddData(biFenData.yazhi?.current.ke, 8, ya[1], biFenData.yazhi?.current?.pan, 'yazhi')}
        >
          <span className={`${styles.text2} ${selectIndex === 8 ? styles.s_text2 : ''}`}>{ya[1]}</span>
        </div>
      </Flex>}
      {daxiaoBoolen && <Flex justify='between' align='center'>
        <div className={`${styles.result2} ${selectIndex === 9 ? styles.s_result2 : ''}`}
          onClick={() => onAddData(biFenData.daxiao?.current?.da, 9, daXiao[0], biFenData.daxiao?.current?.pan, 'daxiao')}
        >
          <span className={`${styles.text2} ${selectIndex === 9 ? styles.s_text2 : ''}`}>{daXiao[0]}</span>
        </div>
        <div className={`${styles.result2} ${selectIndex === 10 ? styles.s_result2 : ''}`}
          onClick={() => onAddData(biFenData.daxiao?.current?.xiao, 10, daXiao[1], biFenData.daxiao?.current?.pan, 'daxiao')}
        >
          <span className={`${styles.text2} ${selectIndex === 10 ? styles.s_text2 : ''}`}>{daXiao[1]}</span>
        </div>
      </Flex>}
    </div>
  }

  const renderList = () => {
    return matchList.map((item: any, index) => {
      const isSelect = index === state.biFenIndex;
      return <div key={item._id}>
        <Flex onClick={() => onSelect(item.value, index)} style={{ background: isSelect ? '#fff9e8' : '#fff' }} className={styles.wrapCell} justify='between' align='center'>
          <div>
            <p className='font16 w600 c3'>{item.title}</p>
            <p className='c6 font12 mt5'>{item.label}</p>
          </div>
          <i className={`iconfont blue font18 ${isSelect ? 'iconradioCur' : 'iconIoniconsmdradiobuttonoff'}`}></i>
        </Flex>
        {isSelect && <div className={styles.wrapCell}>
          {biFenMain()}
        </div>}
        <Line />
      </div>
    })
  }

  const reset = () => {
    setState({
      biFenTitle: '',
      biFenData: {},
      biFenIndex: null,
      biFenValue: {},
      radio: 0
    })
  }

  const submit = () => {
    if (JSON.stringify(state.biFenValue) === '{}') {
      return Toast.info('请选择比分数据');
    }
    setState({
      open: false
    })
  }

  return (
    <div className='bg-white p10 mt10'>
      <div className={styles.drawer} style={{ width: state.open ? '100vw' : 0 }}>
        <div className={styles.bg} onClick={(e) => { setState({ open: false }) }}></div>
        <div className={styles.drawerContent}>
          {renderList()}
        </div>
        <div className={styles.drawerFooter}>
          <Flex align='center' justify='between' className='font13 c3 bg-white p10'>
            <Flex align='center' justify='start'>
              <Flex align='center' className='ml10' onClick={() => setState({ radio: state.radio === 1 ? 0 : 1 })}>
                <i className={`iconfont blue font18 ${state.radio === 1 ? 'iconradioCur' : 'iconIoniconsmdradiobuttonoff'}`}></i>
                <span className='ml2'>原创</span>
              </Flex>
              <Flex align='center' className='ml10' onClick={() => setState({ radio: state.radio === 2 ? 0 : 2 })}>
                <i className={`iconfont blue font18 ${state.radio === 2 ? 'iconradioCur' : 'iconIoniconsmdradiobuttonoff'}`}></i>
                <span className='ml2'>首发</span>
              </Flex>
            </Flex>
            <Flex align='center' justify='center'>
              <Button onClick={reset} size='small' type='ghost' className='mr20'>重置</Button>
              <Button onClick={submit} size='small' type='primary' className='ml20'>确定</Button>
            </Flex>
          </Flex>
        </div>

      </div>
      <Flex justify='between'>
        <p className='c3 font13 '>
          <span>{state.biFenTitle}</span>
          {state.radio === 1 && <span className='color-primary'> #原创</span>}
          {state.radio === 2 && <span className='color-primary'> #首发</span>}
        </p>
        <Button onClick={() => setState({ open: true })} className='mr10' type='ghost' size='small'>选择比赛</Button>
      </Flex>
    </div>
  );
}

export default Bifen;
