import React from 'react'
import tpl from './template';
import { template } from 'lodash';
import  './ArticleMain.scss';

function ArticleMain({ data }: any) {

  function addPredictor () {
    const result: any = {}
    const matchData = data.matchData || []

    matchData.forEach((item: { identify: string; }) => {
      const str = '{{' + item.identify + '}}';
      result[str] = template(tpl)({item});
    })

    return result;
  }

  function parseContent (content: any) {
    const result = addPredictor()
    const regexp = new RegExp('(?:' + Object.keys(result).join('|') + ')', 'g')

    return (content || '').replace(regexp, (match: string | number) => {
      return result[match] || ''
    })
  }

  return (
    <div>
      <div className="articleMain"
        dangerouslySetInnerHTML={{ __html: parseContent(data.context)}}>
      </div>
    </div>
  )
}

export default ArticleMain;
