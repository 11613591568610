import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { Flex } from 'antd-mobile';
import qs from 'qs';

interface AvatarProps extends ConnectProps {
    list: Array<any>
}

function Avatar(props: AvatarProps) {
    const { list, history } = props;

    const _avCell = (userInfo: any) => {
        return <div onClick={() =>  history.push('/user/center?' + qs.stringify({
          _id: userInfo._id, // 用户id
          id: userInfo.bbsUser.id, // 论坛用户id
          type: 'yazhi',
        }))} key={userInfo._id} className='w25 text-center mt5 mb5 relative'>
            <img className='absolute' style={styles.hj} src='/images/hj.png' alt='' />
            <img style={styles.img} src={userInfo.avatar} alt='' />
            <p className='c6 font14'>{userInfo?.nick?.substr(0, 4)}</p>
        </div>
    }

    return (
        <Flex className='mt10' wrap='wrap' align='center' justify='start'>
            {list.map((userInfo) => {
                return _avCell(userInfo)
            })}
        </Flex>
    )
};

export default Avatar;

const styles = {
    hj: {
        width: '24px',
        height: '20px',
        top: '-7px',
        left: '12px',
        transform: 'rotate(-45deg)'
    },
    img: {
        width: '70%',
        borderRadius: '50%',
        border: '1px solid #eaeaea'
    }
}