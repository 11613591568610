import React from 'react';
import styles from './ArticleListCell.module.scss';
import moment from 'moment';
import  { Flex } from 'antd-mobile';

interface ArticleListCellProps {
    item: object | any;
    goDetail: (id: string) => void;
}

function ArticleListCell(props: ArticleListCellProps) {
    const { item, goDetail } = props;
    const titleStyle = (item.subTitle !== '' && item.subTitle !== undefined) ? styles.title : null;
    return <Flex
        className={styles.itemContainer}
        onClick={() => { goDetail(item._id); }}>
        <span className={styles.left}>
            <span className={`${titleStyle}`}>{item.subTitle || ''}</span>
            <img src={item.imageUrl || 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2150981279,4230224918&fm=27&gp=0.jpg'} alt='' />
        </span>
        <Flex direction="column" justify="between" className={styles.right}>
            <div className="line-clamp lineNumber_2">
                <span className='font17 c3 w400'>{item.title}</span>
            </div>
            <Flex justify="start" align="center">
                <span className={styles.align}>
                    <img className={styles.image} src='/images/newlist_ico_see@2x.png' alt='' />
                    <span className={styles.itemText}>{item.uv}</span>
                </span>
                <span className={styles.align}>
                    <img className={styles.image} src='/images/newlist_ico_time@2x.png' alt=''/>
                    <span className={styles.itemText}>{moment(item.publishAt).fromNow()}</span>
                </span>
                <span className={styles.align}>
                    <img className={styles.image} src='/images/newlist_ico_author@2x.png' alt=''/>
                    <span className={styles.itemText}>{item.showAuthor.name}</span>
                </span>
            </Flex>
        </Flex>
    </Flex>
};

export default ArticleListCell;