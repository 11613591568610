import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { Header } from '@component/index';
import { forecastDetail } from '@/services/scoreServices';
import { useMount, useGetSetState } from 'react-use';
import styles from './ForecastDetail.module.scss';
import { Flex, WingBlank } from 'antd-mobile';
import MatchDetail from '@component/Score/MatchDetail';

const ForecastDetailPage = (props: ConnectProps) => {
  const [state, setState] = useGetSetState({
    show: false,
    user: {
      avatar: '',
      nick: ''
    },
    bbsUser: {
      userGradeName: ''
    },
    forecastItems: [
      { nightmare: {} }
    ]
  });
  const { match } = props;
  const forecastId = match.params.id;

  useMount(async () => {
    const result: any = await forecastDetail(forecastId);
    if (result != null) {
      setState({...result, show: true});
    }
  })

  return (
    <div className='page'>
      <Header title='预测详情' back={true} />
      <Flex justify='between' className={styles.avatar}>
        <Flex className='ml15'>
          <div>
            <img className={styles.avatarImage}
              src={state().user.avatar} alt='' />
          </div>
          <div className={styles.avatar_right}>
            <span className='white font16'>{state().user.nick}</span>
            <div className={styles.grade}>
              <span className='white font11'>用户等级：{state().bbsUser.userGradeName}</span>
            </div>
          </div>
        </Flex>
      </Flex>
      <WingBlank size='md' style={{ overflow: 'auto', marginTop: '80px', height: document.documentElement.clientHeight - 125 + 'px' }}>
        {state().show && <MatchDetail data={state()} />}
      </WingBlank>
    </div>
  )
}

export default ForecastDetailPage;

