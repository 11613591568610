/**
 * @description 2020-09-20 ~ 2020-09-21 12Hours
 */
import React, { useImperativeHandle } from 'react';
import { upload } from '@/services/bbsServices';
import { Toast } from 'antd-mobile';
import { useGetSetState, useMount } from 'react-use';
import Compressor from 'compressorjs';
import BraftEditor, { ControlType, ExtendControlType } from 'braft-editor';
import './Edit.scss';
import { ContentUtils } from 'braft-utils';
/// 编写扩展模块
const underdotExtension = {
  // 指定扩展类型
  type: 'inline-style',
  // 指定扩展样式名，推荐使用全大写
  name: 'UNDERDOT',
  // 在编辑器工具栏中增加一个样式控制按钮，text可以为一个react组件
  control: {
    text: <span className='bold'>H</span>
  },
  // 指定该扩展样式的CSS规则，请注意，IE/EDGE浏览器暂时不支持textEmphasis
  style: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  importer: (nodeName: string, node: { style: any; }) => {
    // 指定html转换为editorState时，何种规则的内容将会附加上该扩展样式
    // 如果编辑器在createEditorState时使用的是RAW数据，并且开启了stripPastedStyles，则可以不指定importer，因为不存在html转editorState的场景
    return nodeName === 'span' && [].find.call(node.style, (styleName: any) => styleName.indexOf('text-emphasis') !== -1)
  },
  exporter: () => {
    // 指定该样式在输出的html中如何呈现，对于inline-style类型的扩展可以不指定exporter，输出样式即为该扩展的style
    return (
      <span style={{
        fontSize: '20px',
        fontWeight: 'bold',
      }} />
    )
  }
}

// 加载扩展模块
BraftEditor.use(underdotExtension);

function Edit({ cRef }: any) {
  const [state, setState] = useGetSetState<any>({
    editorState: BraftEditor.createEditorState('', {
      // editorId: 'editor-with-underdot-ext'
    }),
    editorInstance: null
  });
  // const editorInstance = useRef<any>(null);

  useMount(() => {
    const dom: any = document.body.querySelector('.BraftEditor-content');
    if (dom) {
      dom.onclick = () => {
        state().editorInstance.focus();
      };
    }
  })

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal: () => {
      return state().editorState.toHTML()
    }
  }));

  const uploadHandler = async (file: File) => {
    if (!file) {
      return Toast.info('请选择图片');
    }
    const size = file.size / 1024 / 1024;
    if (size > 8) {
      return Toast.info('图片大小不能超过8M');
    }
    // gif直传
    if (file.type.indexOf('gif') !== -1) {
      if (size > 5) {
        return Toast.info('gif图片大小不能超过5M');
      }
      const result: any = await upload(file);
      if (result != null) {
        setFile(result.url)
      }
      return
    }
    new Compressor(file, {
      quality: 0.6,
      async success(fileData: File) {
        const result: any = await upload(fileData);
        if (result != null) {
          setFile(result.url)
        }
      },
      error(err) {
        throw err;
      }
    })
  }

  const setFile = (url: string) => {
    setState({
      editorState: ContentUtils.insertMedias(state().editorState, [{
        type: 'IMAGE',
        url
      }])
    })
  }

  const handleEditorChange = (editorState: any) => {
    setState({ editorState })
  }

  const controls: ControlType[] = [
    { key: 'undo', title: undefined },
    { key: 'redo', title: undefined },
    { key: 'bold', title: undefined },
    'headings',
    // { key: 'hr', title: undefined },
    // { key: 'blockquote', title: undefined }
  ];

  const extendControls: ExtendControlType[] = [
    {
      key: 'uploader',
      type: 'component',
      component: (
        <span className='control-item button' onClick={() => {
          const topic: HTMLElement | null = document.getElementById('topic-add-img');
          return topic != null && topic.click();
        }}>
          <i className='iconfont iconimage w600' ></i>
        </span>
      )
    }
  ]

  return (
    <div className='mt10' id='Edit'>
      <BraftEditor
        id="editor-with-underdot-ext"
        placeholder='发布帖子正文，最多（3000）字'
        contentStyle={{ height: 300, minHeight: 300, background: '#fff' }}
        value={state().editorState}
        // ref={editorInstance}
        ref={instance => state().editorInstance = instance}
        onChange={handleEditorChange}
        extendControls={extendControls}
        controls={controls}
      />
      <div style={{ visibility: 'hidden' }}>
        <input
          type="file"
          id={'topic-add-img'}
          style={{ display: "none" }}
          onChange={(e: any) => uploadHandler(e.target.files[0])}
        />
      </div>
    </div>
  )
}

export default Edit;
