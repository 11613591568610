/**
 * @description url集合
 *  /base 指node端接口  nginx转发依据
 *  /php 指php接口  nginx转发依据
 */

const url = {
    articleList: '/base/article/list',
    topbar: '/base/index/topbar',
    carouselIndex: '/base/carousel/index',
    carouselJingcaiFour: '/base/carousel/jingcai/four',
    nightmareFeatureList: '/base/nightmare/future/list',
    watchUserList: '/base/watch/user/list',
    sendCaptcha: '/base/send/captcha',
    registerByMobile: '/base/register/by/mobile',
    loginByMobile: '/base/login/by/mobile',
    searchHot: '/base/search/hot',
    searchSave: '/base/search/save',
    articleDetail: '/base/article/detail',
    commentList: '/base/comment/list',
    commentAdd: '/base/comment/add',
    articleApprove: '/base/article/approve',
    paymentCreate: '/base/payment/create',
    arenaFourSave: '/base/arena/four/save',
    wechatLogin: '/base/wechat/login',
    commentSubadd: '/base/comment/subadd',
    commentSublist: '/base/comment/sublist',
    levels4Topbar: '/base/levels4/topbar',
    levels4: '/base/levels4',
    arenaFourDetail: '/base/arena/four/detail',
    arenaFourList: '/base/arena/four/list',
    userGetInfo: '/base/user/get/info',
    watchUserAdd: '/base/watch/user/add',
    unfollowUser: '/base/watch/user/unfollow',
    watchUserCancel: '/base/watch/user/cancel',
    userEdit: '/base/user/edit',
    nightmareBifen: '/base/nightmare500/bifen',
    messageList: '/base/message/list',
    userPublicInfo: '/base/user/public/info',
    versions: '/base/version',
    articleSearch: '/base/article/search',
    userGetProperty: '/base/user/get/property',
    orderAdd: '/base/order/add',
    passwordReset: '/base/password/reset',
    uploadToken: '/base/upload/token',
    messageCount: '/base/message/count',
    messageRead: '/base/message/read',
    orderList: '/base/order/list',
    bankcardAdd: '/base/bankcard/add',
    bankcardList: '/base/bankcard/list',
    bankcardDel: '/base/bankcard/del',
    atmAdd: '/base/atm/add',
    atmlist: '/base/atm/list',
    atmCancel: '/base/atm/cancel',
    platform: '/base/platform',
    nightmare500: '/base/nightmare500/future/list',
    flowList: '/base/finance/flow/list',


    /**
     * php接口
     *  */
    likeTop: '/php/like/top',
    articleTop: '/php/article/top',
    focus: '/php/focus',
    article_info: '/php/article/info',
    inform_types: '/php/inform/types',
    top_list: '/php/gift/top-list',
    replyList: '/php/article/replyList',
    gift_list: '/php/gift/list',
    articleAdd: '/php/article/add',
    inform: '/php/inform',
    likeAdd: '/php/like/add',
    articleReply: '/php/article/reply',
    giftReward: '/php/gift/reward',
    articleWinRate: '/php/article/winRate',
    puserInfo: '/php/user/info',
    logList: '/php/gift/log-list',
    userPay: '/php/user/pay',
    pushPoint: '/php/push/point',
    userArticle: '/php/user/article',
    getAuth: '/php/getAuth',
    deleteArticle: '/php/article/deleteArticle',
    deleteReply: '/php/article/deleteReply',
    sync: '/php/user/sync',
    today: '/php/gift/today-top',
    bbsUpload: '/php/upload',

    /**
     * 亚盘
     */
    oupei: '/base/oupei/list',
    daxiao: '/base/daxiao/list',
    yapan: '/base/yapan/list',
    forecastCreate: '/base/forecast/create',
    forecastList: '/base/forecast/list',
    futureget: '/base/nightmare500/future/get',
    perday: '/base/property/pull/jifen/perday',
    forecastDetail: '/base/forecast/get',
    userForecastList: '/base/user/forecast/list',
    excellentUsers: '/base/forecast/excellent/users',
    excellentList: '/base/forecast/excellent/list',
    unlimitedLianhong: '/base/forecast/recommand/unlimitedLianhong/list',
    highBenefitList: '/base/forecast/highBenefit/list',
    yinglilvList: '/base/forecast/recommand/yinglilv/list',
    mingzhonglvList: '/base/forecast/recommand/mingzhonglv/list',
    lianhongList: '/base/forecast/recommand/lianhong/list',
    gradeLatest: '/base/forecast/grade/latest',
    levelList: '/base/forecast/level/list',
    mingdengList: '/base/forecast/level/mingdeng/list',
    jsskdConfig: '/base/wechat/jssdk/config',

    // 推广
    bindPhone: '/base/wechat/bind/phone/and_login',
    promotion: '/base/promotion/url',
    promotionGet: '/base/promotion/promoter/get',
    promotionCreate: '/base/promotion/user/create',

    /**
     * java接口
     */
    circleAll: '/jac/circle/all',
    circleList: '/jac/circle/list',
    userCircleJoin: '/jac/user/circle/join',
    article_list: '/jac/article/list',
    getUpdateNum: '/jac/article/getUpdateNum',

};
export default url;
