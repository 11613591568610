/**
 * @description 首页模块数据
 */
import { request, urls } from '@/services/request';

/**
 * 首页轮播图接口
 * @param title string
 */
export async function getCarouseIndex(title: string) {
  const url = urls.carouselIndex;
  let data = { tag: title, types: '首页' };

  const result = await request({ url, data });
  return result;
}

/**
 * 文章列表接口
 * @param tag string 类别
 * @param createdAt string | null lastTime
 */
export async function getArticleList(tag: string, publishAt?: string | null) {
  const url = urls.articleList;
  let data = { tag, publishAt: publishAt || new Date() };

  const result = await request({ url, data });
  return result;
}

/**
 * 文章详情接口
 * @param _id string 文章id
 */
export async function getArticleDetail(_id: string) {
  const url = urls.articleDetail;
  let data = { _id };

  const result = await request({ url, data });
  return result;
}

/**
 * 评论列表
 * @param _id string 文章id
 * @param createdAt string 评论id--分页
 */
export async function getCommentList(articleId: string, createdAt?: string | null) {
  const url = urls.commentList;
  let data = { articleId, createdAt: createdAt || new Date() };

  const result = await request({ url, data });
  return result;
}

/**
 * 单条评论数据下的列表
 * @param _id string 评论id
 * @param articleId string 文章id
 */
export async function getCommentSubList(_id: string, articleId: string) {
  const url = urls.commentSublist;
  let data = { articleId, _id };

  const result = await request({ url, data });
  return result;
}

/**
 * 回复其他人评论
 * @param _id string 评论id
 * @param comment string 回复内容
 */
export async function addCommentSub(_id: string, comment: string) {
  const url = urls.commentSubadd;
  let data = { _id, comment };

  const result = await request({ url, data });
  return result;
}

/**
 * 回复评论
 * @param articleId string 文章id
 * @param comment string 回复内容
 */
export async function addComment(articleId: string, comment: string) {
  const url = urls.commentAdd;
  let data = { articleId, comment };

  const result = await request({ url, data });
  return result;
}

/**
 * 点赞
 * @param _id string 文章id
 */
export async function articleApprove(_id: string) {
  const url = urls.articleApprove;
  let data = { _id };

  const result = await request({ url, data });
  return result;
}



