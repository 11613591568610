import React from 'react';
import { Flex } from 'antd-mobile';
import styles from './ScoreListCell.module.scss';
import { useHistory } from 'react-router-dom';

enum StatusName {
    '未开赛' = 0,
    '进行中' = 1,
    '已取消' = 2,
    '已完成' = 3,
    '暂停' = -1,
    '改期' = -2
};

function ScoreListCell(props: { data: any }) {
    const history = useHistory();
    const { data: item } = props;

    const goTab = () => {
        localStorage.setItem('score', JSON.stringify(Object.assign({
            result: {}
        }, item)))
        history.push(`/scoreRecommend/` + item._id)
    };

    return (
        <div className={styles.container}>
            {/* top */}
            <Flex align='center' justify='between' className='mt10 pt10 mb5'>
                <div className='w40 text-left'>
                    <Flex align='center' className='pl10 pr10'>
                        <span className='font12 w800' style={{ color: '#' + item.rgbColor || 'eee' }}>{item.result.sh ? item.result.sh.substr(0, 4) : ''}  </span>
                        <span className='c6 font12 ml5'>{item.game_start}</span>
                    </Flex>
                </div>
                <div>
                    <span className='c6 font12 ml10'>{item.half ? '半场 ' : ''}</span>
                    <span className='c6 font12'>{item.half ? item.half : ''}</span>
                </div>
                <div className='w40 text-right'>
                    <span className='font12 mr10' style={{ color: ~~item.status === 1 ? '#6fc47c' : '#666' }}>{StatusName[item.status]}</span>
                </div>
            </Flex>
            {/* middle */}
            <Flex align='center' justify='center' className='pb5' onClick={goTab}>
                <Flex className='w40' justify='end'>
                    <img className={styles.image} src={item.teamZhu.logoUrl} alt="" />
                    <span className='c3 w700 font16'>{item.zhu_full_name}</span>
                </Flex>
                <div>
                    <span className={styles.bifen}>{item.zhu_score}-{item.ke_score}</span>
                </div>
                <Flex justify='start' className='w40'>
                    <span className='c3 font16 w700'>{item.ke_full_name}</span>
                    <img className={styles.image} src={item.teamKe.logoUrl} alt='' />
                </Flex>
                {/* 动画 */}
                {~~item.status === 1 && <img className={styles.circle} src='/images/circle.png' alt='' />}
            </Flex>
            {/* bottom */}
            <Flex justify='between' className='pl10 pb10'>
                {item.spf.length > 0 ?
                    <Flex>
                        <span className='c9 font12'>胜{item.spf[0]}</span>
                        <span className='c9 font12 ml10 mr10'>平{item.spf[1]}</span>
                        <span className='c9 font12'>负{item.spf[2]}</span>
                    </Flex> :
                    <div></div>
                }
                <div onClick={goTab}>
                    <span className='font12 mr5' style={{ color: '#84a1ca' }}>{item.forecastItems ? item.forecastItems.length : 0}篇数据{'>>'}</span>
                </div>
            </Flex>
        </div>
    )
}

export default ScoreListCell;
