import React from 'react';
import { Flex } from 'antd-mobile';
import { ConnectProps } from '@/react-app-env';
import { NoData } from '@component/index';
import qs from 'qs';

const LIST_DATA = {
  titleArray: [
    ['排名', '用户', '胜率', '盈利率'],
    ['排名', '用户', '当前', '历史'],
    ['排名', '用户', '积分榜单'],
    ['排名', '用户', '黑神榜'],
  ],
  widthArray: [
    ['10%', '40%', '25%', '25%'],
    ['10%', '40%', '25%', '25%'],
    ['10%', '50%', '40%'],
    ['10%', '50%', '40%'],
  ],
  daysArray: [
    { name: '7场数据', value: 3 },
    { name: '14场数据', value: 4 },
    { name: '21场数据', value: 5 },
  ]
}

interface StateProps extends ConnectProps {
  state: {
    filterIndex: number;
    daysIndex: number;
    mingdengType: string;
    SuperiorList: any;
  };
  setState: Function;
  getList: Function
}

const Superior = (props: StateProps) => {
  const { state, setState, getList, history } = props;

  const changeMing = () => {
    let { mingdengType } = state;
    const mType = mingdengType === 'down' ? 'up' : 'down';
    const name = mingdengType === 'down' ? '红单神' : '黑神榜';
    LIST_DATA.titleArray[3][2] = name;
    setState({ mingdengType: mType });
    getList();
  }


  const _header = () => {
    let { filterIndex, mingdengType } = state;
    return <Flex className='bg-ef p10'>
      {LIST_DATA.titleArray[filterIndex].map((title, index) => {
        const width = LIST_DATA.widthArray[filterIndex][index];
        // 黑神榜红单神增加点击事件
        if (filterIndex === 3 && index === 2) {
          return <Flex align='center' justify='center' onClick={() => changeMing()} key={index} style={{ width }}>
            <span className='font12 c3'>{title}</span>
            <i className={`iconfont icon${mingdengType} c6 font12`}></i>
          </Flex>
        }
        return <Flex align='center' justify='center' key={index} style={{ width }}>
          <span className='font12 c3'>{title}</span>
        </Flex>
      })}
    </Flex>
  }
  type ItemProp = { user: { _id: any; avatar: any; nick: string; }; bbsUser: { id: any; }; mingzhonglv: number; yinglilv: number; lianhong: any; historyLianhong: any; reward: any; allTimes: any; missTimes: any; hitTimes: any; }
  const _main = () => {
    let { filterIndex, SuperiorList, mingdengType } = state;
    let { widthArray } = LIST_DATA;
    const width: any = widthArray[filterIndex];
    let src = [
      '/images/List_ico_gold@2x.png',
      '/images/List_ico_silver@2x.png',
      '/images/List_ico_copper@2x.png',
    ]
    return <div>
      {SuperiorList.length === 0 && <NoData className='mt10'/>}
      {SuperiorList.map((item: ItemProp, index: number) => {
        const bg = index % 2 !== 0 ? { backgroundColor: '#efefef' } : undefined;
        return <Flex key={index} className='pt5 pb5' style={bg}>
          <Flex align='center' justify='center' style={{ width: width[0] as unknown as string }}>
            {index <= 2 && <img width='30' height='30' src={src[index]} alt='' />}
            {index > 2 && <span className='w600 c3 font13'>{index + 1}</span>}
          </Flex>
          <Flex align='center' justify='center' onClick={() => {
              history.push('/user/center?' + qs.stringify({
                _id: item?.user?._id, // 用户id
                id: item?.bbsUser?.id, // 论坛用户id
              }))
          }} style={{ width: width[1] as unknown as string }}>
            <div className='text-right' style={{ width: '50%' }}>
              <img style={styles.img} src={item.user.avatar} alt='' />
            </div>
            <span className='w600 c3 font12' style={{ width: '50%' }}>{item.user.nick.substr(0, 6)}</span>
          </Flex>
          {filterIndex === 0 && <Flex align='center' justify='center' style={{ width: width[2] }}><span className='color-primary w600 font13'>{Math.round(item.mingzhonglv * 100)}%</span></Flex>}
          {filterIndex === 0 && <Flex align='center' justify='center' style={{ width: width[3] }}><span className='color-primary w600 font13'>{Math.round(item.yinglilv * 100)}%</span></Flex>}
          {filterIndex === 1 && <Flex align='center' justify='center' style={{ width: width[2] }}><span className='color-primary w600 font13'>{item.lianhong}连红</span></Flex>}
          {filterIndex === 1 && <Flex align='center' justify='center' style={{ width: width[3] }}><span className='color-primary w600 font13'>{item.historyLianhong}连红</span></Flex>}
          {filterIndex === 2 && <Flex align='center' justify='center' style={{ width: width[2] }}><span className='color-primary w600 font13'>赢利{item.reward}积分</span></Flex>}
          {filterIndex === 3 && <Flex align='center' justify='center' style={{ width: width[2] }}><span className='color-primary w600 font13'>{item.allTimes}{mingdengType === 'down' ? '黑' : '中'}{mingdengType === 'down' ? item.missTimes : item.hitTimes}</span></Flex>}
        </Flex>
      })}

    </div>
  }

  return (
    <div className='mt10'>
      {_header()}
      {_main()}
    </div>
  );
}

const styles = {
  img: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    marginRight: '5px',
    marginLeft: '10px'
  },
}

export default Superior;
