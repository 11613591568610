import React, { useState } from 'react';
import styles from './MatchDetail.module.scss';
import { Flex } from 'antd-mobile';
import { useMount, useEffectOnce } from 'react-use';
import base from '@/util/base';
import './children/Main.scss';
import moment from 'moment';
import { UserGrade } from '../index';

enum status {
  '未开赛' = 0,
  '进行中' = 1,
  '已取消' = 2,
  '已完成' = 3
}

function MatchDetail({ data }: any) {
  const [minutes, setMinutes] = useState('')
  const match = data.forecastItems[0].nightmare;
  let timer: any = null;

  useMount(() => {
    if (match.status === 0) {
      timer = setInterval(() => {
        overTime();
      }, 1000);
    } else {
      setMinutes(status[match.status] || '已完成');
    }
  });

  useEffectOnce(() => {
    return () => {
      if (timer !== null) {
        clearInterval(timer);
      }
    }
  });

  const overTime = () => {
    let format: any = base.getNowDateStr({ value: match.game_start, type: 'str' })
    if (format.value) {
      setMinutes(format.value);
    } else {
      setMinutes('已开赛');
      clearInterval(timer);
    }
  }

  return (
    <div>
      <UserGrade userId={data.userId}/>
      <Header data={data} match={match} minutes={minutes} />
      <Main data={data} match={match} />
      <div className={styles.reason}>
        <p className='font14 c9 mb5'>推荐理由:</p>
        <span className='font13 c2' style={{ letterSpacing: '.5px', lineHeight: '18px' }}>
          {data.desc || '作者暂未提交分析理由'}</span>
      </div>
      <Flex className='mb10' align='start'>
        <div>
          <span className={styles.reason_text}>功能提示：</span>
        </div>
        <div style={{ flex: 1 }}>
          <span className={styles.reason_text}>如果比赛中断，取消或者数据异常，推荐会被取消并返还所使用的积分（包括付费用户的订单金额）部份比赛结果需要人工干预确认，可能会有延时。</span>
        </div>
      </Flex>
    </div>
  )
}

function Header({ data, match, minutes }: any) {
  let vs = 'VS'
  let item = data
  if (item.status === 1) {
    vs = `${match.zhu_score || 0}-${match.ke_score || 0}`
  }
  return <div className={styles.header}>
    <Flex align='center' justify='start'>
      <Flex align='center'>
        <img className={styles.image} src={match.teamZhu.logoUrl} alt='' />
        <span className='white font17 w500'>{match.zhu_full_name || ''}</span>
        <span className='white font17 w500 ml5 mr5'>{vs}</span>
        <span className='white font17 w500'>{match.ke_full_name || ''}</span>
        <img className={styles.image} src={match.teamKe.logoUrl} alt='' />
      </Flex>
    </Flex>
    <Flex align='center' justify='between'>
      <Flex align='center' className='mt10'>
        <span className='white font12 w500'>{match.result.sh || ''}&nbsp;&nbsp;</span>
        <span className='white font12'>比赛时间：{match.game_start}</span>
      </Flex>
      <div className={styles.header_time}>
        <span className='font11 color-primary'>{minutes}</span>
      </div>
    </Flex>
    <Flex align='center' className='mt5'>
      <div className={styles.header_num}>
        <span className='font12 white'>{data.userLevel.times}场</span>
      </div>
      <span className='white font12 ml10 w600'>近{item.userLevel.times}场盈利率  {item.userLevel.yinglilv}%</span>
      <Flex align='center' className={styles.tab}>
        <span className='font12 color-primary w600 w40'>{data.userLevel.hitTimes || ' 0 '}</span>
        <span className='font12 white w600 w60'>命中</span>
      </Flex>
    </Flex>
  </div>
}

function Main({ data, match }: any) {
  let Result = null
  let item = data.forecastItems[0]
  if (data.status === 1 && item) {
    if (item.result === 1) {
      Result = <img className={styles.resultImage} src='/images/sheng.png' alt='' />
    } else if (item.result === 2) {
      Result = <img className={styles.resultImage} src='/images/bai.png' alt='' />
    } else if (item.result === 3) {
      Result = <img className={styles.resultImage} src='/images/zou.png' alt='' />
    } else if (item.result === 4) {
      Result = <img className={styles.resultImage} src='/images/gai.png' alt='' />
    } else {
      Result = null
    }
  }
  let obj: any = { spf: '胜平负', rqspf: '让球胜平负', dxq: '大小球', yp: '亚盘', bqc: '半全场', jqs: '进球数', bf: '比分' }
  // 亚盘处理
  let p = item.pan || 0
  let yp = [
    `${match.zhu_full_name}${p >= 0 ? '+' + p : p} 胜`,
    `${match.ke_full_name}${p === 0 ? '-' : ''}${p >= 0 ? p * -1 : '+' + p * -1} 胜`]
  let objDetail: any = {
    s: '胜', p: '平', f: '负',
    rs: '让胜', rp: '让平', rf: '让负',
    d: `大${p}`, x: `小${p}`,
    z: yp[0], k: yp[1],
    ss: '胜胜', sp: '胜平', sf: '胜负', ps: '平胜', pp: '平平', pf: '平负', fs: '负胜', fp: '负平', ff: '负负',
    sqt: '胜其他', 'pqt': '平其他', 'fqt': '负其他',
  }
  const formatRqspf = (item: any) => {
    console.log(item);
    return `${item.nightmare.zhu_full_name} ${item.rq>0?'+':''}${item.rq} ${objDetail[item.value].substr(1)}(${item.odds})`
  }
  const displayContent = () => {
    if ((item.playType === 'rqspf' && item.rq != null)) {
      return formatRqspf(item);
    }
    if (item.playType === 'jqs') {
      return `进球数${item.value}球(SP=${item.odds})`;
    }
    return `${objDetail[item.value] || item.value }(${item.odds})`;
  };
  return <div className='relative'>
    {Result ? Result : null}
    <div className={styles.main_line}>
      <span className={styles.main_title}>玩法类型:</span>
      <span className={styles.main_result}>{obj[item.playType]}</span>
    </div>
    <div className={styles.main_line}>
      <span className={styles.main_title}>投注积分:</span>
      <span className={styles.main_result}>{data.jifen}分</span>
      {data.reward > 0 && <span className={`${styles.main_title} ml10`} style={{ color: '#ed715a' }}>中奖积分</span>}
      {data.reward > 0 && <span className='ml5 color-primary'>{data.reward}分</span>}
    </div>
    <div className={styles.main_line}>
      <span className={styles.main_title}>推荐内容:</span>
      <span className={`${styles.main_result} w600`} style={{ color: '#ed715a' }}>
        {displayContent()}
      </span>
    </div>
    <div className={styles.main_line}>
      <span className={styles.main_title}>收费金额:</span>
      <span className={styles.main_result}>{data.worth / 100}掌门币</span>
    </div>
    <div className={styles.main_line}>
      <span className={styles.main_title}>发布时间:</span>
      <span className={styles.main_result}>{moment(data.createdAt).fromNow()}</span>
    </div>
  </div>
}

export default MatchDetail;
