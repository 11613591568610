/**
 * 我的
 */
import React, { Fragment } from 'react';
import { ConnectProps } from '@/react-app-env';
import { useGetSetState } from 'react-use';
import styles from './User.module.scss';
import { Flex, Modal, Toast } from 'antd-mobile';
import { Line } from '@component/index';
import { userModel } from '@/model/user';

const alert = Modal.alert;

const User = (props: ConnectProps) => {
  const { history } = props;
  const [model, setModel] = userModel();

  const [state, setState] = useGetSetState({
    login: Boolean(model?.user?._id),
    code: false
  })

  const Login = () => {
    return <Flex align='center' justify='center' className={styles.login}>
      <span onClick={() => { history.push('register') }}>注册</span>
      <span className={styles.line}></span>
      <span onClick={() => { history.push('login') }}>登录</span>
    </Flex>;
  }

  const logout = () => {
    alert('提示', '是否确认退出登录？', [
      { text: '取消' },
      {
        text: '确认', onPress: () => {
          setModel({ ...model, user: {} });
          setState({ login: false });
          localStorage.removeItem('user');
          Toast.info('已退出登录');
        }
      },
    ])
  }

  const Avatar = () => {
    return <div className={styles.avatar}>
      <i onClick={() => logout()} className={`iconfont iconlogout ${styles.logout}`}></i>
      <div className={styles.avatarView}>
        <img className={styles.avatarImage}
          src={model?.user.avatar ? model?.user?.avatar : '/images/icon.png'} alt='' />
        <p className={styles.avatarText}>{model?.user.nick}</p>
      </div>
    </div>
  }

  const data = {
    rowList: [
      { name: '我的资产', uri: '/images/user_ico_card@2x.png', onClick: () => history.push('/card') },
      { name: '资金明细', uri: '/images/user_ico_pk@2x.png', onClick: () => history.push('/finance') },
      { name: '我的文章', uri: '/images/user_ico_article@2x.png', onClick: () => history.push('/user/center') },
    ],
    list: [
      { name: '我的消息', uri: '/images/user_ico_news@2x.png', onClick: () => history.push('/message') },
      { name: '我的消费', uri: '/images/user_ico_help@2x.png', onClick: () => history.push('/consume') },
      { name: '在线客服', uri: '/images/user_ico_line@2x.png', onClick: () => setState({ code: true }) },
    ]
  }
  return (
    <div className={styles.container}>
      <header>
        {state().login ? Avatar() : Login()}
      </header>
      <Flex justify='center' align='center' className='bg-white pt15 pb15'>
        {data.rowList.map((item, index) => {
          return <Fragment key={index}>
            <Flex align='center' justify='center' direction='column' className='w33'
              onClick={item.onClick || null}>
              <img className={styles.rowImage} src={item.uri} alt='' />
              <span className='font14 color-ab mt5'>{item.name}</span>
            </Flex>
            {index <= 1 && <div className={styles.rowWrap}></div>}
          </Fragment>
        })}
      </Flex>
      <div className='mt15'>
        {data.list.map((item, index) => {
          return <div key={index}>
            <Flex align='center' justify='between' className={styles.cell} onClick={item.onClick || null}>
              <Flex>
                <img className={styles.left_ico} src={item.uri} alt="" />
                <span className='ml10 c3 font14'>{item.name}</span>
              </Flex>
              <img className={styles.right_ico} src="/images/user_ico_right@2x.png" alt="" />
            </Flex>

            {index <= 1 && <Line className='ml10' style={{ background: '#eaeaea' }} />}
          </div>
        })}
      </div>
      <Flex onClick={() => window.location.href = '/download.html'} align='center' justify='between' className={`${styles.cell} mt15`}>
        <Flex>
          <img className={styles.left_ico} src="/images/user_ico_setup@2x.png" alt="" />
          <span className='ml10 c3 font14'>下载APP</span>
        </Flex>
        <img className={styles.right_ico} src="/images/user_ico_right@2x.png" alt="" />
      </Flex>
      {state().code && <div
        className={styles.fix}
        onClick={(e) => setState({ code: false })}>
        <div><i className='iconfont iconclose'></i></div>
        <img src="/images/qrcode.png" alt="" />
      </div>}
    </div>
  );
}

export default User;
