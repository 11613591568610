/**
 * @description 手机号快捷登录
 */

import React from 'react';
import { InputItem, WingBlank, Flex, Button, Toast, Checkbox } from 'antd-mobile';
import styles from './Login.module.scss';
import { useGetSetState } from 'react-use';
import { loginByMobile, sendCaptcha } from '@/services/userServices';
import { ConnectProps } from '@/react-app-env';

const AgreeItem = Checkbox.AgreeItem;

const QuickLogin = ({ history }: ConnectProps) => {
    const [state, setState] = useGetSetState({
        phone: '',
        code: false,
        codeText: '',
        checked: true
    });

    const getCode = async () => {
        if (!state().checked) {
            return Toast.info('请同意用户协议');
        }
        if (state().code) {
            const result = await loginByMobile(state().phone, state().codeText, 'captcha');
            if (result != null) {
                Toast.info('登录成功');
                localStorage.setItem('user', JSON.stringify(result));
                window.location.href = '/';
            }
        } else {
            const result = await sendCaptcha(state().phone, 'LOGIN_');
            if (result != null) {
                Toast.info('发送成功');
                setState({ code: true })
            }
        }
    }

    return (
        <WingBlank size='md'>
            <div className='text-right mt20'>
                <img onClick={() => history.goBack()} className={styles.close_img} src="/images/login_ico_Close@2x.png" alt="" />
            </div>
            <div className='text-center mt20 mb30'>
                <img className='mt20' width='30%' src="/images/login_ico_logo@2x.png" alt="" />
            </div>

            <Flex align='center' direction='column' className='text-center'>
                <div className='w80'>
                    <InputItem onChange={val => setState({ phone: val })} maxLength={11} type='text' className={styles.input} clear placeholder="请输入手机号"></InputItem>
                </div>
                {state().code && <div className='w80 mt20'>
                    <InputItem onChange={val => setState({ codeText: val })} value={state().codeText} type='text' className={styles.input} clear placeholder="请输入验证码"></InputItem>
                </div>}
                <div className='w80 mt20'>
                    <Button onClick={() => getCode()} className={styles.button} type='primary'>{state().code ? '登录' : '获取短信验证码'}</Button>
                </div>
            </Flex>

            <Flex align='center' direction='column' className='text-center'>
                <AgreeItem defaultChecked={state().checked} className='w80 mt10 font12' onChange={(e: any) => setState({ checked: e.target.checked })}>
                    <span className='font12 c6'>我已阅读并同意
                    <span className='font12' style={{ color: '#6484b6' }} onClick={(e) => { e.preventDefault(); window.location.href = '/register.html' }} >《用户协议》《隐私政策》</span>
                    </span>
                </AgreeItem>
            </Flex>

        </WingBlank>
    );
}

export default QuickLogin;
