import React, {CSSProperties} from 'react';

interface StyleProps {
    className?: string;
    style?: CSSProperties;
    height?: number;
    color?: string;
}
export default function Line(props: StyleProps) {
    const height = props.height || 1 + 'px';
    const color = props.color || '#eaeaea';
    return <div className={props.className} style={{height, backgroundColor: color, ...props.style}}></div>
}