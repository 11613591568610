import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { Tabs } from 'antd-mobile';
import { circleAll } from '@/services/bbsServices';
import { useMount, useGetSetState, useHash } from 'react-use';
import Category from './Category';
import Square from './Square';
import SlideMenu from '@component/Bbs/SlideMenu';
import './bbsTabs.scss';

const tabs = [
  { title: '圈子', id: -912, showMatch: 0 },
  { title: '动态广场', id: -818, showMatch: 0 },
];

interface StateProps {
  tabs: { title: string, id: number | string }[],
  tabsId: number,
  pageIndex: number,
  showMatch: number
}

function filter(item: { name: string, id: number | string, showMatch: number }) {
  return { title: item.name, id: item.id, showMatch: item.showMatch };
}

const BbsPage = (props: ConnectProps) => {
  const { history } = props;
  const [hash, setHash] = useHash();
  const [state, setState] = useGetSetState<StateProps>({
    tabs: [],
    tabsId: -818,
    pageIndex: 1,
    showMatch: 0
  });

  useMount(() => {
    getAll();
  })

  const getAll = async () => {
    const result: any = await circleAll();
    if (result != null) {
      const array = Array.from(result.list || [], filter);
      const allTabs = [...tabs, ...array];
      allTabs.forEach((item: any, index: number) => {
        if (hash === `#${item.id}`) {
          setState({
            tabs: allTabs,
            pageIndex: index,
            tabsId: item.id,
            showMatch: item.showMatch
          })
        } else {
          setState({
            tabs: allTabs
          })
        }
      })
    }
  }

  const onTab = (index: number, id: number, showMatch: number, data: any) => {
    for (let i = 0; i < state().tabs.length; i++) {
      if (state().tabs[i].id === id) {
        setState({ pageIndex: i, tabsId: id, showMatch });
        return;
      }
    }

    history.push(`/nojoin/${data.id}?name=${data.name}`);
  }

  const onJoin = (index: number, st: number) => {
    getAll();
  }

  const renderContent = (tab: any) => {
    if (tab.id === -912) {
      return <Category onTab={onTab} onJoin={onJoin} />
    }
    return <Square onJoin={onJoin} {...props} id={state().tabsId} />
  }

  return (
    <div className='bg-f8 relative'>
      {state().tabsId !== -912 && <SlideMenu tabsId={state().tabsId} history={props.history} showMatch={state().showMatch} />}
      <Tabs tabs={state().tabs}
        onChange={(e, index) => {
          setState({ tabsId: e.id, pageIndex: index, showMatch: e.showMatch });
          setHash(e.id);
        }}
        onTabClick={(tab, index: number) => {
          setState({ pageIndex: index, showMatch: tab.showMatch });
          setHash(tab.id);
        }}
        tabBarUnderlineStyle={{ border: '1px #fff solid', marginBottom: '5px' }}
        tabBarBackgroundColor='#ed715a'
        tabBarInactiveTextColor='#f2c6c6'
        tabBarActiveTextColor='#fff'
        distanceToChangeTab={0.7}
        initialPage={state().pageIndex}
        page={state().pageIndex}
        prerenderingSiblingsNumber={0}
        swipeable={false}
        renderTab={tab => <span className="font14">{tab.title}</span>}>
        {renderContent}
      </Tabs>
    </div>
  )
}

export default BbsPage;
