import { jsskdConfig } from '@/services/payServices';
import { Toast } from 'antd-mobile';

/**
 * @description 微信jssdk相关配置
 */
const wx = require('weixin-js-sdk');
declare let WeixinJSBridge: any;

interface ShireProps {
  title?: string;
  desc?: string;
  link?: string;
  imgUrl?: string;
}
class Wechat {
  static pay: any;
  static init() { }
  static shire() { }
  public appId: string;
  public ready: boolean;
  public shireData: any;

  constructor() {
    this.appId = 'wx51bf1750a90f4997';
    this.ready = false;
    this.shireData = {
      title: '球掌门官方网站',
      desc: '足球掌门人社区，优化国内牛人大神方案，比武擂台甄选掌门人，精准方案，百里挑一！',
      link: window.location.href,
      imgUrl: 'http://web.static.qiuzhangmen.com/1540113531324.png',
    }
  }

  public isWechat(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    const match = ua.match(/MicroMessenger/i);
    if (match === null) {
      return false;
    }
    if (match.includes('micromessenger')) {
      return true;
    }
    return false;
  }

  public async init() {
    const result: any = await jsskdConfig(window.location.href.split('#')[0]);
    if (result == null) return;
    wx.config({
      debug: process.env.NODE_ENV === 'development', // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: this.appId, // 必填，公众号的唯一标识
      timestamp: result.timestamp, // 必填，生成签名的时间戳
      nonceStr: result.noncestr, // 必填，生成签名的随机串
      signature: result.signature,// 必填，签名
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'chooseWXPay'
      ]
    });

    wx.ready(() => {
      this.ready = true;
      this.shire();
    });
    wx.error(function (res: any) {
      console.log(res)
    });
  }

  public pay({ requestData: param }: any, callback: Function) {
    if (this.ready) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": this.appId,
        "timeStamp": param.timeStamp,
        "nonceStr": param.nonceStr, //随机串     
        "package": param.package,
        "signType": param.signType,
        "paySign": param.paySign //微信签名 
      },
        function (res: { err_msg: string; }) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            callback();
          } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
            Toast.info('支付已取消')
          } else {
            Toast.info('支付失败')
          }
        });
    }
  }

  public updateAppMessageShareData(data: ShireProps) {
    // wx.onMenuShareAppMessage({
    //   title: data.title, // 分享标题
    //   desc: data.desc, // 分享描述
    //   link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //   imgUrl: data.imgUrl, // 分享图标
    //   type: 'link', // 分享类型,music、video或link，不填默认为link
    //   dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    //   success: function () {
    //     // 用户点击了分享后执行的回调函数
    //   }
    // });    
    wx.updateAppMessageShareData({
      title: data.title, // 分享标题
      desc: data.desc, // 分享描述
      link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: data.imgUrl, // 分享图标
      success: function () {
       
      }
    })
  }

  public updateTimelineShareData(data: ShireProps) {
    wx.updateTimelineShareData({
      title: data.title, // 分享标题
      link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: data.imgUrl, // 分享图标
      success: function () {
      }
    })
  }

  public shire(data: ShireProps = {}) {
    const shireData = Object.assign({}, this.shireData, data);
    if (!this.isWechat()) {
      // Toast.info('请在微信内打开进行分享');
      return
    }
    this.updateAppMessageShareData(shireData);
    this.updateTimelineShareData(shireData);
    return;

    // const dataList = [
    //   { url: 'umnHwvEgSyQtXlZjNJTt', title: '微信好友' },
    //   { url: 'cTTayShKtEIdQVEMuiWt', title: '朋友圈' },
    // ].map(obj => ({
    //   icon: <img src={`https://gw.alipayobjects.com/zos/rmsportal/${obj.url}.png`} alt={obj.title} style={{ width: 36 }} />,
    //   title: obj.title,
    // }));
    // ActionSheet.showShareActionSheetWithOptions({
    //   options: dataList,
    //   title: '',
    //   message: '分享',
    // },
    //   (buttonIndex) => {
    //     if (buttonIndex < 0) return;
    //     return new Promise((resolve) => {
    //       switch (buttonIndex) {
    //         case 0:
    //           this.updateAppMessageShareData(data);
    //           break;
    //         case 1:
    //           this.updateTimelineShareData(data);
    //           break;
    //         default:
    //           break;
    //       }
    //       setTimeout(resolve, 1000);
    //     });
    //   });
  }
}
const WX = new Wechat();
export default WX;
