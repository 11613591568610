import React from 'react';
import { Header, Line, UserGrade, NoData } from '@/component';
import { Flex, WingBlank, Toast } from 'antd-mobile';
import { useGetSetState, useMount, useList, useBoolean } from 'react-use';
import { puserInfo, userArticle, userPublicInfo } from '@/services/bbsServices';
import { ConnectProps } from '@/react-app-env';
import qs from 'qs';
import styles from './UserCenter.module.scss';
import { forecastList } from '@/services/userServices';
import ForecastCell from '@/component/Score/ForecastCell';
import ArticleCell from '@/component/Bbs/ArticleCell';
import WX from '@/util/weChat';


const originState = {
  userInfo: {
    user_avatar: '',
    user_name: '',
    user_grade_name: '',
    user_mobi_amount: 0,
    user_fans: 0,
    medal_list: []
  },
  tabId: 0,
  page: 1,
  list: [],
  isBottom: true,
  public: {}
}

const UserCenter = (props: ConnectProps) => {
  const users = JSON.parse(localStorage.getItem('user') as string);

  const { location } = props;
  /**
   * @id bbs 论坛用户ID
   * @_id 用户id
   * @type 进入第几个tab页
   */
  const param = qs.parse(location.search.replace('?', ''));

  const [state, setState] = useGetSetState<any>(originState);
  const [chuList, { set, updateAt }] = useList<any>([]);
  const [chuBottom, setChuBottom] = useBoolean(false);

  useMount(async () => {
    if (param?.type === 'yazhi') {
      setState({ tabId: 1 })
    }
    const result: any = await puserInfo(param.id as string | undefined);
    if (result != null) {
      setState({
        userInfo: result
      })
      getList();
      getForecastList(null);
    }
    getUserPublicInfo();
  });

  const getUserPublicInfo = async () => {
    const result: any = await userPublicInfo(param._id as string | undefined || users._id);
    console.log(param)
    if (result != null) {
      setState({
        public: result
      })
    }
  }

  const getList = async () => {
    const result: any = await userArticle(state().page, 20, param.id);
    if (result != null && result?.list != null) {
      if (state().list.length === 0 && result.list.length === 0) {
        setState({
          tabId: 1
        })
      }
      setState({
        list: state().list.concat(result.list),
        isBottom: result.list.length < 20
      });
    } else {
      setState({
        tabId: 1
      })
    }
  }

  const getForecastList = async (id: string | null | undefined) => {
    const result: any = await forecastList(param._id || users._id, id);
    if (result != null) {
      setChuBottom(result.length < 20);
      if (result.length === 0) {
        return Toast.info('没有更多数据了')
      }
      WX.shire({
        title: `球掌门(${state().userInfo.user_name})盈利(${result[0]?.userLevel?.yinglilv}%)推荐`,
        desc: '',
        link: window.location.href,
        imgUrl: state().userInfo.user_avatar || 'http://web.static.qiuzhangmen.com/1540113531324.png',
      });
      set(chuList.concat(result));
    }
  }

  const Avatar = () => {
    return <Flex className={styles.avatar}>
      <img className={styles.avatarImage} src={state().userInfo?.user_avatar} alt="" />
      <div className='ml10'>
        <p className='white font16 mb5'>
          <span>{state().userInfo.user_name}</span>
          {state().userInfo?.medal_list.map((item: { medal_img: string | undefined; }, index: string | number | undefined) => {
            return <img key={index} src={item.medal_img} style={{ width: '20px', height: '20px', marginLeft: '5px' }} alt='' />
          })}
        </p>
        <p className={styles.grade}>用户等级：{state().userInfo.user_grade_name}</p>
      </div>
    </Flex>
  }

  const Rows = () => {
    return <Flex align='center' justify='around' className={styles.renderRow}>
      <div>
        <p>{state().userInfo.user_mobi_amount}</p>
        <p>魔豆</p>
      </div>
      <div>
      <p>{state()?.public?.data?.visitSum}</p>
        <p>阅读量</p>
      </div>
      <div>
        <p>{state().userInfo.user_fans < 100 ? '100+' : state().userInfo.user_fans}</p>
        <p>粉丝</p>
      </div>
    </Flex>
  }

  const RenderTab = () => {
    return <Flex className='p10'>
      <div onClick={() => setState({ tabId: 0 })} className={`${state().tabId === 0 ? styles.tab_s_text : styles.tab_text}`}><span>论坛文章</span></div>
      <div onClick={() => setState({ tabId: 1 })} className={`${state().tabId === 1 ? styles.tab_s_text : styles.tab_text}`}><span>推荐市场</span></div>
    </Flex>
  }

  const updateBbs = (id: string) => {
    chuList.forEach((value: any, index) => {
      if (id === value._id) {
        value.isLock = false;
        value.order = { _id: new Date() };
        updateAt(index, value);
      }
    })
  }


  const RenderList = () => {
    const { tabId } = state();
    if (tabId === 0) {
      return <WingBlank size='md'>
        {state().list.length === 0 && <NoData />}
        {state().list.map((item: any, index: number) => {
          return <div key={index}>
            <ArticleCell key={index} data={item} state={state()} index={index} {...props} setState={setState} page='userCenter'/>
          </div>
        })}
        {!state().isBottom && <div className="font13 c6 text-center pt10 pb10"
          onClick={() => { setState({ page: ++state().page }); getList() }}>加载更多</div>}
      </WingBlank>
    }
    if (tabId === 1) {
      return <WingBlank size='md'>
        <UserGrade userId={param._id || users._id} />
        {chuList.map((item: any) => {
          return <div key={item._id}>
            <ForecastCell refresh={(id: string) => updateBbs(id)} data={item} type='user' {...props} />

            <Line />
          </div>
        })}
        {chuList.length === 0 && <NoData />}
        {!chuBottom && <div className="font13 c6 text-center pt10 pb10"
          onClick={() => { getForecastList(chuList[chuList.length - 1]._id) }}>加载更多</div>}
      </WingBlank>
    }
    return null
  }

  return (
    <div className='page bg-f9'>
      <Header title='个人中心' back={true} />
      <div className='content'>
        <Avatar/>
        <Rows/>
        <RenderTab/>
        <RenderList/>
      </div>
    </div>
  )
}

export default UserCenter;
