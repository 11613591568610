/**
 * @description 用户模块数据
 */
import { request, urls } from '@/services/request';
import { constant } from '@/model/constant';

/**
 * 个人成绩
 * @param userId string 用户id
 */
export async function gradeLatest(userId: string) {
  const url = urls.gradeLatest;
  let data = { userId };

  const result = await request({ url, data });
  return result;
}

/**
 * 登录
 */
interface LoginTypes {
  phone: string;
  password: string;
  type?: string;
}
export async function loginByMobile(phone: string, password: string, type?: string) {
  const url = urls.loginByMobile;
  let data: LoginTypes = { phone, password };

  if (type && type !== '') {
    data.type = type;
  }

  const result = await request({ url, data });
  return result;
}

/**
 * 获取验证码
 */
export async function sendCaptcha(phone: string, type?: string) {
  const url = urls.sendCaptcha;
  let data = { phone, type: 'REGISTER_' };

  if (type) {
    data.type = type;
  }

  const result = await request({ url, data });
  return result !== undefined ? true : result;
}

/**
 * 注册
 */
export async function registerByMobile(phone: string, verify: string, password: string) {
  const url = urls.registerByMobile;
  let data = { phone, verify, password };

  const result = await request({ url, data });
  return result;
}

/**
 * 消息列表
 */
export async function messageList() {
  const url = urls.messageList;
  let data = { createdAt: new Date() };

  const result = await request({ url, data });
  return result;
}

/**
 * 资金明细
 * 账户类型 consume掌门币账户, cash掌门币可提现账户
 */
type flowListTypes = { accountTypes: 'consume' | 'cash'; _id?: string };
export async function flowList(accountTypes: 'consume' | 'cash', _id?: string) {
  const url = urls.flowList;
  let data: flowListTypes = { accountTypes };
  if (_id) {
    data._id = _id;
  }
  const result = await request({ url, data });
  return result;
}

/**
 * 我的预测
 */
export async function forecastList(userId: number | string, _id?: string | null) {
  const url = urls.forecastList;
  let data: any = { userId, perpage: constant.perpage };
  if (_id) {
    data._id = _id;
  }
  const result = await request({ url, data });
  return result;
}

/**
 * 授权登录
 */
export async function wxLogin(code: string, from: string) {
  const url = urls.wechatLogin;
  let data = { code, from };

  const result = await request({ url, data });
  return result;
}

/**
 * 订单列表
 */
export async function orderList() {
  const url = urls.orderList;
  let data = { types: ['arenafour', 'forecast'] };

  const result = await request({ url, data });
  return result;
}

/**
 * 获取昵称
 */
export async function promotionGet(token: string) {
  const url = urls.promotionGet;
  let data = { token };

  const result = await request({ url, data });
  return result;
}

/**
 * 推广保存
 */
export async function promotionUserCreate(phone: string|number, token: string) {
  const url = urls.promotionCreate;
  let data = { phone, token };

  const result = await request({ url, data });
  return result;
}