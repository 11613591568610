import React from 'react';
import styles from './ImageQueue.module.scss';
import { useMount, useList } from 'react-use';
import { Flex } from 'antd-mobile';

interface ImageTypes {
  width: number,
  height: number,
  shape: number,
  uri: string
}
interface ImageProps {
  images: Array<any>
}

/**
* 正方形 1
* 横长方形 >1
* 竖长方形 >1
*/
function imageSort(uri: string) {
  const img = new Image();
  img.src = uri;
  return new Promise<ImageTypes>((resolve) => {
    img.onload = function () {
      const [width, height] = [img.width, img.height];
      const shape: number = width === height ? 1 : parseFloat(String(width/height));
      resolve({ width, height, shape, uri });
    }
  })
}

function ImageQueue({ images }: ImageProps) {
  const [sequenceImg, { set }] = useList<ImageTypes>([]);


  useMount(async () => {
    const result: ImageTypes[] = await Promise.all(
      images.map((item: string) => imageSort(item)
    ));
    set(result);
  });

  /**
   * 单张排版
   */
  const oneImg = () => {
    if (sequenceImg[0].shape > 1) {
      return <img src={sequenceImg[0].uri} alt="" className={styles.one_row} />
    } else {
      return <img src={sequenceImg[0].uri} alt="" className={styles.one_column} />
    }
  }

  /**
   * 双张
   */
  const twoImg = () => {
    const isRow = sequenceImg.some(item => item.shape >= 1);
    if (isRow) {
      return <div className={styles.two_row}>
        {sequenceImg.map((item,index) => {
          return <img key={index} src={item.uri} alt="" />
        })}
      </div>
    } else {
      return <Flex align='center' justify='between' className={styles.two_column}>
        {sequenceImg.map((item, index) => {
          return <img key={index} src={item.uri} alt="" />
        })}
      </Flex>
    }
  }

  /**
   * 三张排版
   */
  const threeImg = () => {
    const shapeBigImgs = sequenceImg.sort((a, b) =>  b.shape - a.shape);
    const shapeSmallImgs = [...shapeBigImgs].reverse();
    if (shapeBigImgs[0].shape -1 > 1 - shapeSmallImgs[0].shape ) {
      return <div className={styles.three_row}>
        <div><img src={shapeBigImgs[0].uri} alt="" /></div>
        <Flex justify='between'>
        <img src={shapeBigImgs[1].uri} alt="" />
        <img src={shapeBigImgs[2].uri} alt="" />
        </Flex>
      </div>
    } else {
      return <div className={styles.three_column}>
      <Flex justify='between'>
        <img src={shapeBigImgs[0].uri} alt="" />
        <Flex direction='column' justify='between' align='center'>
          <img className='pb5' src={shapeBigImgs[1].uri} alt="" />
          <img className='pt5' src={shapeBigImgs[2].uri} alt="" />
        </Flex>
      </Flex>
    </div>
    }
  }

  /**
   * 四张排版
   */
  const fourImg = () => {
    const shapeBigImgs = sequenceImg.sort((a, b) =>  b.shape - a.shape);

      return <div className={styles.four_column}>
      <Flex justify='between'>
        <img src={shapeBigImgs[0].uri} alt="" />
        <Flex direction='column' justify='between' align='center'>
          <img src={shapeBigImgs[1].uri} alt="" />
          <img src={shapeBigImgs[2].uri} alt="" />
          <img src={shapeBigImgs[3].uri} alt="" />
        </Flex>
      </Flex>
    </div>
  }

  return (
    <div className={styles.container}>
      {sequenceImg.length === 1 && oneImg()}
      {sequenceImg.length === 2 && twoImg()}
      {sequenceImg.length === 3 && threeImg()}
      {sequenceImg.length === 4 && fourImg()}
    </div>
  )
}

export default ImageQueue;
