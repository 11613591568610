/**
 * @description 论坛
 */
import { request, urls, uploadFile } from '@/services/request';
import { constant } from '@/model/constant';
import { Toast } from 'antd-mobile';

/**
 * 栏目列表
 */
export async function circleAll(action?: number) {
  const url = urls.circleAll;
  const data: {action?: number} = {};
  if (action) {
    data.action = action;
  }

  const result = await request({ url, data });
  return result;
}

/**
 * 圈子列表
 */
export async function circleList(page: number, pageSize: number = constant.perpage) {
  const url = urls.circleList;
  const data = { page, pageSize };

  const result = await request({ url, data });
  return result;
}

/**
 * 加入圈子
 */
export async function userCircleJoin(circleId: number | string) {
  const url = urls.userCircleJoin;
  const data = { circleId };

  const result = await request({ url, data });
  return result;
}

/**
 * 列表
 */
interface articleListTypes {
  type?: 7 | 4,
  page: number,
  circleId?: number | null,
  page_size?: number
}
export type { articleListTypes };

export async function articleList(param: articleListTypes) {
  const url = urls.article_list;
  let data: articleListTypes = {
    page: param.page || 1,
    page_size: param.page_size || constant.perpage,
  };

  if (param.type) {
    data.type = param.type;
  }

  if (param.circleId) {
    data.circleId = param.circleId
  }

  const result = await request({ url, data });
  return result;
}

/**
 * 个人中心列表
 */
export async function userArticle(page: number, page_size: number, id: any) {
  const url = urls.userArticle;
  const data: any = { page, page_size };
  if (id != null) {
    data.user_id = ~~id;
  }

  const result = await request({ url, data });
  return result;
}

/**
 * 点赞
 */
export async function likeAdd(article_id: number | string) {
  const url = urls.likeAdd;
  const data = { article_id };

  const result = await request({ url, data });
  return result;
}

/**
 * 上传
 */
export async function upload(file: File) {
  const url = urls.bbsUpload;

  const result = await uploadFile(url, file);
  return result;
}

/**
 * 发布帖子
 */
export async function articleAdd(data: any) {
  const url = urls.articleAdd;

  const result = await request({ url, data });
  return result;
}

/**
 * 详情
 */
export async function articleInfo(id: string | number) {
  const url = `${urls.article_info}/${id}`;

  const result = await request({ url });
  return result;
}

/**
 * 个人预测推荐
 */
export async function forecastList(userId: number | string) {
  const url = urls.forecastList;
  let data = { userId, status: 0, perpage: 3 };

  const result = await request({ url, data });
  return result;
}

/**
 * 文章关联个人成绩
 */
export async function articleWinRate(article_id: number | string) {
  const url = urls.articleWinRate;
  let data = { article_id };

  const result = await request({ url, data });
  return result;
}

/**
 * 礼物列表
 */
export async function giftList() {
  const url = urls.gift_list;

  const result = await request({ url });
  return result;
}

/**
 * 礼物赠送
 */
export async function giftReward(article_id: string | number, gift_id: number) {
  const url = urls.giftReward;
  const data = { article_id, gift_id };

  const result = await request({ url, data });
  return result;
}

/**
 * 打赏贵人
 */
export async function topList(article_id: string | number,) {
  const url = urls.top_list;
  const data = { article_id, page: 1, page_size: 30 };

  const result = await request({ url, data });
  return result;
}

/**
 * 文章打赏榜
 */
export async function logList(article_id: string | number,) {
  const url = urls.logList;
  const data = { article_id, page: 1, page_size: 1000 };

  const result = await request({ url, data });
  return result;
}

/**
 * 评论列表
 */
export async function replyList(article_id: string | number, last_id = 0, pid = 0, page_size = 10) {
  const url = urls.replyList;
  const data = { article_id, last_id, page_size, pid };

  const result = await request({ url, data });
  return result;
}

/**
 * 评论
 */
export async function articleReply(article_id: string | number, content: string, pid: number) {
  if (content === '') {
    return Toast.info('回复内容不能为空');
  }
  const url = urls.articleReply;
  const data = { article_id, content, pid };

  const result = await request({ url, data });
  return result;
}

export async function puserInfo(user_id: string | null | undefined) {
  const url = urls.puserInfo;
  const data: { user_id?: string } = {};
  if (user_id != null) {
    data.user_id = user_id
  }

  const result = await request({ url, data });
  return result;
}

/**
 * 评论
 */
export async function userPublicInfo(_id: string | null | undefined) {
  const url = urls.userPublicInfo;
  const data = { userId: _id};

  const result = await request({ url, data });
  return result;
}

/**
 * 获取列表的更新动态数
 */
export async function getUpdateNum() {
  const url = urls.getUpdateNum;
  const data: { user_id?: string } = {};

  const result = await request({ url, data });
  return result;
}

/**
 * 删除帖子
 */
export async function deleteArticle(id: number) {
  const url = urls.deleteArticle;
  const data = { article_id: id };

  const result = await request({ url, data });
  return result;
}

