import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { PullToRefresh, Modal, Toast } from 'antd-mobile';
import { useMount, useGetSetState, useDeepCompareEffect } from 'react-use';
import { articleList, articleListTypes, getUpdateNum, deleteArticle } from '@/services/bbsServices';
import ArticleCell from '@component/Bbs/ArticleCell';
import { BbsModel, BbsProps } from '@/model/bbs';
import { NoData, Notify } from '@/component';

interface SquareProps extends ConnectProps {
  id: number;
  onJoin: Function
}

const Square = (props: SquareProps) => {
  const { id, onJoin, location } = props;
  const [model, setModel] = BbsModel();
  const [state, setState] = useGetSetState({
    page: 1,
    refreshing: false,
    list: [],
    total: 0,
    isBottom: true,
    showNotify: false,
    num: 0
  })
  const FOOTER_HEIGHT = location.pathname.indexOf('nojoin') > -1 ? 0 : 50;

  useMount(() => {
    getList();
  })

  const getList = async () => {
    let data: articleListTypes = {
      page: state().page,
    }
    if (id > 0) {
      data.circleId = id;
    } else {
      data.type = 7;
    }
    const result: any = await articleList(data);
    if (result != null) {
      setState({
        refreshing: false,
        list: state().page === 1 ? result.list : state().list.concat(result.list),
        total: result.total,
        isBottom: result.list.length < 20
      });
      setModel({ ...model as BbsProps, publishId: null, squareData: { id: null, type: '', value: '', articleId: 0 } });
    }
  }

  const showHandler = (num: number, time: number) => {
    setState({showNotify: true, num});
    setTimeout(() => {
      setState({showNotify: false, num: 0});
    }, time)
  }

  const getNewListNumber = async () => {
    if (id !== -818) {
      setState({ refreshing: true, page: 1 })
      getList();
      return
    }
    const result: any = await getUpdateNum();
    if (result?.updateNum != null) {
      if (result.updateNum > 0) {
        showHandler(result.updateNum, 2000)
      }
      setState({ refreshing: true, page: 1 })
      getList();
    }
  }

  useDeepCompareEffect(() => {
    // 返回刷新圈子
    if (model?.publishId != null) {
      onJoin();
    }

    // 刷新列表
    if (model?.publishId === id) {
      setState({ refreshing: true, page: 1, list: [] })
      getList();
    }
    
    // 回调状态
    if (model?.squareData.id === id) {
      if(model?.squareData.type === 'focus') {
        const list = state().list;
        list.forEach((item: any) => {
          if (model?.squareData.articleId === item.article_id) {
            item.is_focus = model?.squareData.value;
          }
        });
        setModel({...model as BbsProps, squareData: { id: null, type: '', value: '', articleId: 0 }});
      }
      if (model?.squareData.type === 'likes') {
        const list = state().list;
        list.forEach((item: any) => {
          if (model?.squareData.articleId === item.article_id) {
            item.liked = model?.squareData.value.liked;
            item.is_liked = model?.squareData.value.is_liked;
          }
        });
        setModel({...model as BbsProps, squareData: { id: null, type: '', value: '', articleId: 0 }});
      }
    }
  }, [model])

  const onDelete = (id: any, index: any) => {
    Modal.alert(
      '删除',
      '是否删除此帖子？',
      [
        {text: '取消'},
        {text: '确认', onPress: async () => {
          const result = await deleteArticle(id as number);
          if (result != null) {
            Toast.info('删除成功');
            const list = state().list.filter((item: any) => item.article_id !== id);
            setState({list});
          }
        }},
      ]
    )
  }

  return (
    <div className='relative'>
      <Notify show={state().showNotify} title={`收到${state().num}条新动态`}/>
      <PullToRefresh
        getScrollContainer={() => {
          return ''
        }}
        distanceToRefresh={50}
        damping={100}
        style={{
          height: document.documentElement.clientHeight - FOOTER_HEIGHT - 43.5,
          overflowY: 'auto',
        }}
        indicator={{}}
        direction={'down'}
        refreshing={state().refreshing}
        onRefresh={() => {
          getNewListNumber();
        }}
      >
        {state().list.map((item: any, index) => {
          return <ArticleCell key={item.article_id} ids={id} data={item} state={state()} index={index} {...props} setState={setState} onDelete={onDelete} page='bbs'/>
        })}
        {state().list.length === 0 && <NoData className='mt10' />}
        {!state().isBottom &&
          <div className="font13 c6 text-center pt10 pb10 bg-white"
            onClick={() => { setState({ page: ++state().page }); getList() }}>展开更多文章</div>
        }
      </PullToRefresh>
    </div>
  )
}

export default Square;

