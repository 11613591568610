import React from 'react'
import { Header, NoData, Line } from '@/component'
import { useMount, useList } from 'react-use'
import { orderList } from '@/services/userServices'
import ForecastCell from '@/component/Score/ForecastCell'
import { ConnectProps } from '@/react-app-env'
import { Flex, WingBlank } from 'antd-mobile'
import moment from 'moment'

const ConsumePage = (props: ConnectProps) => {
  const [list, {set}] = useList([])

  useMount(async () => {
    const result: any = await orderList();
    if(result != null) {
      set(result)
    }
  })

  const arenafour = (item: any) => {
    return <div></div>
  }

  const forecast = (item: any) => {
    return <div className='mt10'>
      <Flex align='center' justify='between' className='c3 font13 w500'>
         <span>购买时间:{moment(item.createdAt).format('YYYY-MM-DD')}</span>
         <span>消费金额:{item.arena.worth / 100}掌门币</span>
      </Flex>
      <ForecastCell 
        refresh={null} {...props}
        type='consume'
        data={Object.assign({ user: item.arenaUser, bbsUser: item.bbsUser }, item.arena)}/>
      <Line className='mt20'/>
    </div>
  }

  return (
    <div className='page'>
      <Header title='我的消费' back={true}/>
      <WingBlank size='md'>
      {list.length === 0 && <NoData className='mt10' title='暂无数据'/>}
      {list.map((item: any) => {
        return <div key={item._id}>
          {item.type === 'arenafour' ? arenafour(item) : forecast(item)}
        </div>
      })}
      </WingBlank>
    </div>
  )
}

export default ConsumePage;
