import React, { useEffect } from 'react';
import { useGetSetState, useMount } from 'react-use';
import { ConnectProps } from '@/react-app-env';
import SuperiorHeader from '@component/Ranking/SuperiorHeader';
import SuperiorList from '@component/Ranking/SuperiorList';
import { levelList, mingdengList } from '@/services/listServices';
import { listModel } from '@/model/list';
import { WingBlank } from 'antd-mobile';

const DATA = {
  daysArray: [
    { name: '7场数据', value: 3 },
    { name: '14场数据', value: 4 },
    { name: '21场数据', value: 5 },
    // { name: '28场数据', value: '28' },
  ],
  filterArray: [
    { name: '综合指标', value: 'zonghe' },
    { name: '连红指标', value: 'lianhong' },
    { name: '财富榜', value: 'caifu' },
    { name: '明灯榜', value: 'mingdeng' },
  ]
}

const Superior = (props: ConnectProps) => {
  const [model] = listModel();

  const [state, setState] = useGetSetState({
    filterIndex: model?.comeInTwoTab as number || 0,
    daysIndex: 0,
    mingdengType: 'down',
    SuperiorList: []
  });

  useEffect(() => {
    setState({filterIndex: model?.comeInTwoTab})
  }, [model, setState]);

  useMount(() => {
    getList();
  });

  const getList = async () => {
    let { filterIndex, daysIndex, mingdengType } = state();
    let { filterArray, daysArray } = DATA;
    let type = filterArray[filterIndex].value;
    let timesType = daysArray[daysIndex].value;

    if (filterIndex === 3 && mingdengType === 'up') {
      type = 'hongshen';
    }
    let result: any = null;
    if (filterIndex === 3 && mingdengType === 'down') {
      result = await mingdengList(timesType += 5);
    } else {
      result = await levelList(type, timesType);
    }
    if (result != null) {
      setState({ SuperiorList: result });
    }

  }

  return (
    <WingBlank size='md'>
      <SuperiorHeader state={state()} setState={setState} DATA={DATA} getList={getList} />
      <SuperiorList state={state()} setState={setState} getList={getList} {...props}/>
    </WingBlank>
  );
}

export default Superior;
