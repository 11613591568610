import React, { useState } from 'react';
import { ConnectProps } from '@/react-app-env';
import { Flex, WingBlank } from 'antd-mobile';
import { MatchStatus } from '@/model/constant';
import { useMount, useEffectOnce } from 'react-use';
import base from '@/util/base';
import ForecastCell from '@/component/Score/ForecastCell';
import { Line } from '@component/index';
import moment from 'moment';
import styles from './AdMain.module.scss';

interface UserMatchProps extends ConnectProps {
  data: any;
  refresh: Function;
  index: number;
  deleteOneMatch: Function;
}

function UserMatch(props: UserMatchProps) {
  const { data, refresh, index, deleteOneMatch } = props;
  const [minutes, setMinutes] = useState('');
  let timer: NodeJS.Timeout | null = null;

  useMount(() => {
    if (data.status === 0) {
      overTime()
      timer = setInterval(() => {
        overTime()
      }, 1000)
    } else {
      setMinutes(MatchStatus[data.status])
    }
  })

  useEffectOnce(() => {
    return () => {
      if (timer !== null) {
        clearInterval(timer)
      }
    }
  })

  const overTime = () => {
    let format = base.getNowDateStr({ value: data?.game_start || moment().format('MM-DD'), type: 'str' });
    if (format.value) {
      setMinutes(format.value as string);
    } else {
      setMinutes('已开赛');
      clearInterval(timer as NodeJS.Timeout)
    }
  }

  const _header = () => {
    return <div className={styles.header}>
      <img src="/images/ich.png" className={styles.ich} alt=""/>

      <Flex justify='between' align='center'>
        <Flex align='center' style={{ marginLeft: '-5px' }}>
          <img className={styles.image} src={data.teamZhu.logoUrl} alt='' />
          <span className='c3 font18 w600'>{data.zhu_full_name}</span>
          <span className='c3 font21 w600 ml5 mr5'>vs</span>
          <span className='c3 font18 w600'>{data.ke_full_name}</span>
          <img className={styles.image} src={data.teamKe.logoUrl} alt='' />
        </Flex>
        <div className='mr5 pl10' onClick={() => {
          deleteOneMatch(index)
        }}>
          <i className='iconfont iconclose-circle-fill font16 c9' />
        </div>
      </Flex>
      <Flex align='center' justify='between'>
        <span className='c6 font11 mt5'>{data.result.sh}  比赛时间：{data.game_start}</span>
        <div className={styles.header_time}>
          <span className='font11 white'>{minutes}</span>
        </div>
      </Flex>
    </div>
  }

  const _main = (item: any) => {
    return <div key={item._id}>
      <ForecastCell
        {...props}
        data={item}
        type='commom'
        refresh={(_id: string) => refresh(_id, index)} />
    </div>
  }
  return (
    <div className='mt10'>
      <Line className='mb10' style={{ height: '10px', backgroundColor:'rgba(241,241,241,0.8)' }} />

      <WingBlank size='md'>
        {_header()}
        {data.forecasts.map((item: any, index: number) => {
          return _main(item)
        })}
      </WingBlank>
    </div>
  )
}

export default UserMatch;
