/**
 * @description useContext透传项（必须）-- list, setList 修改自身属性值
 */

import React from 'react';
import { ConnectProps } from '@/react-app-env';
import styles from '../ForecastCell.module.scss';
import { Flex, SegmentedControl, Modal, Toast } from 'antd-mobile';
import moment from 'moment';
import './Main.scss';
import { orderAdd } from '@/services/scoreServices';

const alert = Modal.alert;

interface ForecastCellProps extends ConnectProps {
  data: any;
  type?: string;
  refresh: any;
}

function Main(props: ForecastCellProps) {
  const { data: item, history, refresh } = props;
  const obj: any = { spf: '胜平负', rqspf: '让球胜平负', dxq: '大小球', yp: '让球', bqc: '半全场', jqs: '进球数', bf: '比分' };
  const forecastItems = item.forecastItems instanceof Array ? item.forecastItems[0] : null;
  if (forecastItems == null) {
    return null;
  }
  const match = forecastItems.nightmare;

  const goDetail = () => {
    if (!item.isLock) {
      history.push(`/forecastDetail/${item._id}`)
      return
    }
    if (item.isLock) {
      alert(<h3 className='font15'>{`您将给${item.user.nick}支付${item.worth / 100}掌门币`}</h3>,
        <p className='text-left'>球掌门保证用户前期预测的真实性与准确性，但不能保证您购买此信息后保证您中奖。确认表示您认可该信息。</p>, [
        { text: '取消', onPress: () => console.log('cancel') },
        {
          text: '确定', onPress: async () => {
            const result = await orderAdd(item._id);
            if (result != null) {
              Toast.info('购买成功');
              refresh(item._id);
              history.push(`/forecastDetail/${item._id}`)
            }
          }
        },
      ]);
      //           //余额不足
      //           if (e.money == 'low') {
      //             Toast.hide();
      //             Alert.alert('余额不足', '是否去充值？',
      //               [
      //                 { text: "取消", onPress: null },
      //                 {
      //                   text: "确认", onPress: () => {
      //                     this.props.navigation.navigate('RechargePage', { page: 'ListResultpage', useageType: 'zmb' })
      //                   }
    }
  }

  const Result = () => {
    if (item.status === 1 && forecastItems) {
      if (forecastItems.result === 1) {
        return <img className={styles.resultImage} src='/images/sheng.png' alt='' />
      } else if (forecastItems.result === 2) {
        return <img className={styles.resultImage} src='/images/bai.png' alt='' />
      } else if (forecastItems.result === 3) {
        return <img className={styles.resultImage} src='/images/zou.png' alt='' />
      } else if (forecastItems.result === 4) {
        return <img className={styles.resultImage} src='/images/gai.png' alt='' />
      } else {
        return null;
      }
    }
  };

  const showMatch = props.type === 'forum' || props.type === 'user' || props.type === 'consume';
  return (
    <div className={styles.bg} onClick={() => goDetail()}>
      {Result()}
      {showMatch &&
        <Flex align='center' className='mb5'>
          <img className={styles.img2} src={match.teamZhu.logoUrl} alt='' />
          <span className='font13 c3 w600'>{match.zhu_full_name}</span>
          <span className='font16 c3 w600 mb2'>&nbsp;vs&nbsp;</span>
          <span className='font13 c3 w600'>{match.ke_full_name}</span>
          <img className={styles.img2} src={match.teamKe.logoUrl} alt='' />
          <span className='font12 c3 w600 ml5'>{match.result.sh}</span>
          <Flex>
            <span className='font11 c6 ml15'>开赛：</span>
            <span className='font11 c6 ml5'>{match.game_start}</span>
          </Flex>
        </Flex>}
      <Flex align='center'>
        <span className='font12 c3 w600'>类型：</span>
        <span className='font12 c3 w600 ml5'>{obj[item.forecastItems[0] ? item.forecastItems[0].playType : '暂无']}</span>
        {getIcon(item.forecastItems[0] ? item.forecastItems[0].playType : null)}
        <span className='font12 c6 ml15'>投注积分：</span>
        <span className='font12 c6 ml5'>{item.jifen}分</span>
      </Flex>
      <Flex align='center' className='mt5'>
        <span className='font12 c3'>推荐时间：</span>
        <span className='font12 c3, ml5'>{moment(item.createdAt).fromNow()}</span>
        <span className='font12 ml5' style={{ color: '#5e97d2' }}>{item.desc ? '有理由>' : ''}</span>
      </Flex>
      {tabComponent(item)}
    </div>
  )
}

const getIcon = (ptype: any) => {
  if (ptype == null) return;
  const uri: any = {
    spf: '/images/spf_icon.png',
    rqspf: '/images/spf_icon.png',
    dxq: '/images/dx_icon.png',
    yp: '/images/rq_icon.png',
    bqc: '/images/bq_icon.png',
    jqs: '/images/jq_icon.png',
    bf: '/images/bf_icon.png'
  }
  return uri[ptype] ? <img className={styles.icon} src={uri[ptype]} alt='' /> : null;
}

function tabComponent(item: any) {
  let TabButton = null;
  let order = item.order || {};
  const backPercent: string = item.backPercent ? '不中退' : '';
  if (item.worth <= 0) {
    TabButton = <div className={styles.btn}>免费阅读</div>;
  }
  if (item.worth > 0) {
    if (order._id) {
      TabButton = <div className={styles.btn} style={{ background: '#ed715a' }}>已购买</div>;
    } else {
      TabButton = backPercent !== '' ?
        <SegmentedControl
          prefixCls='hay0816'
          disabled={true}
          values={[backPercent, item.worth / 100 + '掌门币']}
        />
        : <div className={styles.btn} style={{ background: 'rgba(0,0,0,0)', border: '1px solid #ed715a', color: '#ed715a' }}>{item.worth / 100 + '掌门币'}</div>
    }
  }
  return <div className={styles.buttonWrap}>
    {TabButton}
  </div>

}

export default Main;

