import React from 'react';
import Header from '@/component/layouts/Header';
import { ConnectProps } from '@/react-app-env';
import { Tabs } from 'antd-mobile';
import { useMount, useMap } from 'react-use'
import { request, urls } from '@/services/request';
import HomeMain from './HomeMain';

type topbar = { title?: string; value: string; };

const HomePage = (props: ConnectProps) => {
  const [map, { set }] = useMap<any>({
    tabs: []
  });

  useMount(async () => {
    const url = urls.topbar;
    const result: topbar[] | any = await request({ url, data: {} });
    if (result != null) {
      set('tabs', result.map((item: topbar) => {
        item.title = item.value;
        return item;
      }))
    }
  })

  const renderContent = (tab: { title: React.ReactNode; name: string; isCarouse?: boolean }) => {
    return <HomeMain {...props} name={tab.name} isCarouse={tab.isCarouse as boolean || false} />
  }

  return (<div className="page">
    <Header
      title="球掌门" />
    <Tabs tabs={map.tabs}
      tabBarUnderlineStyle={{ backgroundColor: '#ec5d38', height: 1 }}
      tabBarInactiveTextColor='#9b9b9b'
      tabBarActiveTextColor='#3c3c3c'
      initialPage={0}
      swipeable={true}
      renderTab={tab => <span className="font14">{tab.title}</span>}>
      {renderContent}
    </Tabs>
  </div>)
}

export default HomePage;