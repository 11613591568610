import React, { useState } from 'react';
import { Flex, Popover } from 'antd-mobile';

const Item: any = Popover.Item;

interface StateProps {
  state: {
    filterIndex: number;
    daysIndex: number;
    mingdengType: string;
  };
  setState: Function;
  DATA: {
    daysArray: any[];
    filterArray: any[];
  };
  getList: Function
}

function SuperiorHeader(props: StateProps) {
  const { state, setState, DATA, getList } = props;
  const [show, setShow] = useState(false);

  const _days = () => {
    let { daysArray } = DATA;
    let { daysIndex } = state;
    return <Popover mask
      visible={show}
      placement='bottom'
      overlay={
        DATA.daysArray.map((item: any, index: number) => {
          return <Item className='font12' style={{ width: '100px' }} key={item.id} value={item.value} index={index}>{item.name}</Item>
        })
      }
      onSelect={data => {
        const { index } = data.props;
        setState({ daysIndex: index });
        setShow(false);
        getList();
      }}
    >
      <Flex align='center' className='pt2 pb2' onClick={() => setShow(true)}>
        <span className='c3 font12'>{daysArray[daysIndex].name}</span>
        <i className='iconfont icondown c3 ml5 font15'></i>
      </Flex>
    </Popover>
  }

  const _filters = () => {
    let { filterArray } = DATA;
    let { filterIndex } = state;
    return <Flex justify='around'>
      {filterArray.map((item, index) => {
        let isSelect = filterIndex === index;
        return <div key={index}
          style={isSelect ? { ...styles.selectView, ...styles.view } : styles.view}
          onClick={() => { setState({ filterIndex: index }); getList(); }}>
          <span className='c3 font12' style={isSelect ? styles.selectText : undefined}>{item.name}</span>
        </div>
      })}
    </Flex>
  }

  return (
    <Flex className='mt10 w100'>
      <div className='w20'>
        {_days()}
      </div>
      <div className='w80'>
        {_filters()}
      </div>
    </Flex>
  )
}

const styles = {
  view: {
    padding: '2px 7px',
    borderRadius: '3px',
    lineHeight: '100%'
  },
  selectView: {
    background: '#eaeaea'
  },
  selectText: {
    color: '#ed715a',
    fontWeight: 500
  }
};

export default SuperiorHeader;
