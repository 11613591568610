import axios, { AxiosRequestConfig } from 'axios';
import { Toast } from 'antd-mobile';
import url from './url';
import { encrypt } from 'crypto-lg';

interface OptionsProps {
  url: string;
  data?: object;
}

const service = axios.create({
  withCredentials: false, // 跨域凭证
  timeout: 10000 // request timeout
})

// Add a request interceptor
service.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
service.interceptors.response.use(function (response) {
  // Returns data for null processing
  if (response.data?.code === 0 && response.data?.result == null) {
    response.data.result = true;
  }

  return response;
}, function (error: any) {
  return Promise.reject(error);
});

export const urls = url;

/**
 *  url重定向
 */
const redirectUrl = (url: string): string => {
  if (process.env.NODE_ENV === 'development') {
    return url;
  }

  const reg = /\/(base|php|jac).*/gi
  if (reg.test(url)) {
    if (url.indexOf('/base') > -1) {
      url = url.replace('/base', process.env.REACT_APP_BASE_URL as string);
    }
    if (url.indexOf('/php') > -1) {
      url = url.replace('/php', process.env.REACT_APP_PHP_URL as string);
    }
    if (url.indexOf('/jac') > -1) {
      url = url.replace('/jac', process.env.REACT_APP_JAC_URL as string);
    }
    return url;
  }

  return '';


}

/**
 * request操作
 * {
 *  url: '',
 *  data: {}
 * }
 *
 * @returns {Promise<void>}
 */
export const request = async (options: OptionsProps): Promise<void> => {
  Toast.loading('加载中...', 10)
  const user = JSON.parse(localStorage.getItem('user') as string)
  const opts: AxiosRequestConfig = {
    method: 'POST',
    baseURL: '',
    data: {
      head: {
        token: user && user.token,
        version: 'h5'
      }
    }
  };

  const data = opts.data;

  data.body = options.data;
  opts.data = { data: encrypt(data) };

  delete options.data;
  options.url = redirectUrl(options.url);
  Object.assign(opts, options);

  // Add development environment parameters
  // if (process.env.NODE_ENV === 'development') {
    console.log({...opts, data});
  // }

  try {
    const response = await service(opts);
    Toast.hide()
    const data_1 = response.data;
    if (+data_1.code !== 0) {
      return codeError(data_1);
    }
    return data_1.result;
  }
  catch (error) {
    return codeError((error as any)?.response?.data || {});
  }
}

/**
 * upload
 * @param file
 */
export const uploadFile = async (url: string, file: File) => {
  Toast.loading('上传中...', 10)
  const formData = new FormData();
  formData.append("file", file, file.name);

  const config = {
    headers: { "Content-Type": "multipart/form-data" }
  };

  try {
    const response = await service.post(redirectUrl(url), formData, config);
    Toast.hide()
    const data_1 = response.data;
    if (+data_1.code !== 0) {
      return codeError(data_1);
    }
    return data_1.result;
  } catch (e) {
    return codeError((e as any)?.response?.data || {});
  }
}
function codeError(error: any) {
  Toast.hide()
  const info = error || {};
  console.log(info)
  if (info?.msg) {
    Toast.info(info.msg, 2);
    if (+info.code === 10000) { // 清理登录信息
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
  }
}
