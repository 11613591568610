import React from 'react';
import { ConnectProps } from '@/react-app-env';
import { PullToRefresh, WingBlank } from 'antd-mobile';
import { useMount, useList, useGetSetState } from 'react-use';
import { highBenefitList } from '@/services/listServices';
import { constant } from '@/model/constant';
import ForecastCell from '@component/Score/ForecastCell';
import { Line, NoData } from '@component/index';
import AdMain from '@component/Ranking/AdMain';

const Reward = (props: ConnectProps) => {
  const [state, setState] = useGetSetState({
    refresh: false,
    isBottom: false,
    page: 1,
    adIndex: 2,
    lianHongIndex: 3,
  })
  const [list, { set }] = useList<any>([]);

  useMount(() => {
    getList(state().page, [])
  })

  const getList = async (page: number, dataArray: Array<any>) => {
    const result: any = await highBenefitList(page);
    if (result != null) {
      set(dataArray.concat(result));
      setState({
        page,
        refresh: false,
        isBottom: result.length < constant.perpage
      });
    }
  }

  const _ForecastCell = (item: any, index: number) => {
    const { forecast } = item;
    return <div key={index}>
      {index === state().adIndex && _adMain('common')}
      {index === state().lianHongIndex && _adMain('lianhong')}
      <ForecastCell
        {...props}
        data={forecast}
        type='forum'
        refresh={null} />
         <Line />
    </div>
  }

  const _adMain = (type: string) => {
    return <div>
      <AdMain {...props} type={type} />
      <Line />
    </div>
  }

  return (
    <PullToRefresh
      getScrollContainer={() => {
        return ''
      }}
      distanceToRefresh={50}
      damping={100}
      style={{
        height: document.documentElement.clientHeight - 95 - 43.5,
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      indicator={{}}
      direction={'down'}
      refreshing={state().refresh}
      onRefresh={() => {
        setState({ refresh: true });
        getList(1, []);
      }}
    >
      <WingBlank size='md'>
        {list.map((item, index) => {
          return _ForecastCell(item, index)
        })}
        {list.length === 0 && <NoData className='mt10' />}
        {!state().isBottom &&
          <div className="font13 c6 text-center pt10 pb10"
            onClick={() => { getList(state().page + 1, list) }}>展开更多文章</div>
        }
      </WingBlank>
    </PullToRefresh>
  )
}

export default Reward;
