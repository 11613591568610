/**
 * 预测列表
 *
 * @author luoage@msn.cn
 */
const template = `<div class="predictor">
    <div class="item">
      <div class="header">
        <div>
          <img class="zhu-logo logo" src="<%- item.zhu_team.logoUrl %>" alt="">
        </div>
        <div class="vs-center">
          <span><%- item.code %></span>
          <span class="vs-title"> 
            <%- 
            item.result.zdkd.replace(/(\\[[^\\]]+])/g, '$1') 
            %>
          </span>
          <span>比赛时间: <span><%- item.game_start %></span></span>
        </div>
        <div>
          <img class="ke-logo logo" src="<%- item.ke_team.logoUrl %>" alt="">
        </div>
      </div>
      <div class="predictor-content">
        <span class="rq tag gray">
          <% if (item.status >= 3) { %>
            <i class="oi" data-glyph="<%- item.predictor_result.spf ? 'check' : '' %>"></i>
         <% } %>0
        </span>
        <div class="flex">
          <span class="tag red <%- +(item.predictor || {}).spf === 1 ? 'active' : '' %>">主胜(<%- (item.spf || [])[0]||'-' %>)</span>
          <span class="tag green <%- +(item.predictor || {}).spf === 0 ? 'active' : '' %>">平局(<%- (item.spf || [])[1]||'-' %>)</span>
          <span class="tag blue <%- +(item.predictor || {}).spf === -1 ? 'active' : '' %>">客胜(<%- (item.spf || [])[2]||'-' %>)</span>
        </div>
        <span class="rq tag gray">
          <% if (item.status >= 3) { %><i class="oi" data-glyph="<%- item.predictor_result.rqspf ? 'check' : '' %>"></i><% } %>
          <%- item.result.rq %>
        </span>
        <div class="flex">
            <span class="tag red <%- +(item.predictor || {}).rqspf === 1 ? 'active' : '' %>">让胜(<%- (item.rqspf || [])[0]||'-' %>)</span>
            <span class="tag green <%- +(item.predictor || {}).rqspf === 0 ? 'active' : '' %>">让平(<%- (item.rqspf || [])[1]||'-' %>)</span>
            <span class="tag blue <%- +(item.predictor || {}).rqspf === -1 ? 'active' : '' %>">让负(<%- (item.rqspf || [])[2]||'-' %>)</span>
        </div>
      </div>
    </div>
</div>
`

export default template;
