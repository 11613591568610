import React, { useState, useContext } from 'react';
import { useGetSetState, useMount, useBoolean } from 'react-use';
import styles from './detail.module.scss';
import { Flex, Button, Toast, Modal } from 'antd-mobile';
import { ConnectProps } from '@/react-app-env';
import { articleWinRate, logList, articleReply, replyList } from '@/services/bbsServices';
import { NoData } from '@component/index'
import util from '@/util';
import { Context } from '@/pages/Bbs/BbsDetail';
import moment from 'moment';
import ReactEchartsCore from 'echarts-for-react/lib/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/visualMap';

interface FooterProps extends ConnectProps {
  data: any
}
const footer_tab_list = [
  { id: 0, name: '全部评论' },
  { id: 1, name: '打赏榜' },
  { id: 2, name: '成绩统计' },
]
function Footer(props: FooterProps) {
  const { data } = props;
  const { commentList, setComment, getComment } = useContext(Context);
  const [state, setState] = useGetSetState({
    footer_tab_id: 0,
    log_list: [],
    winRate: {
      currentList: [],
      currentText: ''
    }
  });
  const [, setFocus] = useBoolean(false);
  const [text, setText] = useState<any>('');

  useMount(async () => {
    const rate: any = await articleWinRate(data.article_id);
    if (rate != null) {
      setState({winRate: rate})
    }
    const result: any = await logList(data.article_id);
    if (result != null) {
      setState({ log_list: result.list })
    }
  })

  const addComment = async (pid = 0) => {
    const result: any = await articleReply(data.article_id, text, pid);
    if (result != null) {
      Toast.info('回复成功');
      if (pid === 0) {
        setComment([result, ...commentList]);
        setText('');
      }
    }
  }

  const subadd = (pid: number, index: number) => {
    Modal.prompt(
      '评论回复',
      '请输入评论内容',
      [{ text: '取消' }, {
        text: '确定', onPress: async (comment) => {
          if (comment === '') return Toast.info('内容不能为空');
          const result: any = await articleReply(data.article_id, comment, pid);
          if (result != null) {
            let arr = commentList;
            if (arr[index].reply_list) {
              arr[index].reply_list = [
                result,
                ...arr[index].reply_list
              ];
            } else {
              arr[index].reply_list = [result];
            }
            setComment(arr);
            Toast.info('回复成功', 1);
          }
        }
      }],
      'default')
  }

  const commentSublist = async (pid: number, index: number) => {
    const last_id = commentList[index]['reply_list'][commentList[index]['reply_list'].length - 1].reply_id;
    const result: any = await replyList(data.article_id, last_id, pid);
    if (result != null) {
      let arr = commentList;
      arr[index].reply_list = [...arr[index].reply_list, ...result.list];
      setComment(arr);
    }
  }

  const comment = () => {
    if (commentList.length === 0) {
      return <NoData className='mt10' title='暂无评论' />
    }
    return <div>
      <Flex align="center" className="mt20 mb20">
        <div className={styles.row_line}></div>
        <span className="ml15 mr15 c3 font16 w500">热门评论</span>
        <div className={styles.row_line}></div>
      </Flex>
      {commentList.map((item: any, index: number) => {
        return <Flex key={item.reply_id} className="mt10" align="start">
          <div className="w15">
            <img className={styles.avatar} src={item.user_info.user_avatar} alt="avatar" />
          </div>
          <div className="ml5 mr20 w85" style={{ flex: 8.5 }}>
            <Flex justify="between" align="start">
              <span className={`mt5 w500 font14 ${item.reply_type===1?'color-primary':'c3'}`}>{item.user_info.user_name.substr(0, 11)}</span>
              <Flex justify="start">
                <div onClick={() => {
                  subadd(item.reply_id, index)
                }}>
                  <img className={styles.icon_msg} src="/images/newlist_ico_msg@2x.png" alt="" />
                </div>
                <div onClick={() => { Toast.success('点赞成功', 1) }}>
                  <img className={styles.ico_fabulous} src="/images/article_foot_ico_fabulous@2x.png" alt="" />
                </div>
              </Flex>
            </Flex>
            <div className={`font14 mt5 ${item.reply_type===1?'color-primary':'c3'}`} style={{ letterSpacing: 1, lineHeight: '16px' }}>{item.reply_content}</div>
            <div className="c9 font12 mt5">{moment(item.create_time).fromNow().replace(' ', '')}</div>
            <div className={(item.reply_list && item.reply_list.length > 0) ? styles.wrapComment : ''}>
              {item.reply_list ? item.reply_list.map((item2: any, i: number) => {
                return <div key={i} className={`mt5 font14 c3`}>
                  <span className='w500'>{item2.user_info.user_name}</span> 回复:{item2.reply_content}</div>
              }) : null}
            </div>
              {item.reply_num >= 2 ? <div
                  onClick={() => {
                    commentSublist(item.reply_id, index);
                  }}
                >
                  <span className='font11 color-blue mt5'>共{item.reply_num}条回复</span>
                </div> : null}
          </div>
        </Flex>
      })}

      <div onClick={() => { getComment(data.article_id) }}
        className="text-center mt10 mb10 c9 font14">
        查看更多评论 &gt;
      </div>
    </div>
  }

  const footerReward = () => {
    const { log_list } = state();
    if (log_list.length === 0) {
      return <NoData className='mt10' title='暂无人上榜' />
    }
    return <div className='mt20 mb20'>
      {log_list.map((item: any) => {
        return <Flex align='center' className='mt10' key={item.id}>
          <img style={{ width: '38px', height: '38px', borderRadius: '50%' }} src={item?.user_info.user_avatar} alt="" />
          <div className='ml10'>
            <p className='font14 c3 w500'> {item?.user_info.user_name} {item.desc}</p>
            <p className='mt5 c9 font11'>{item.create_time}</p>
          </div>
        </Flex>
      })}
    </div>
  }

  const lineCharts = () => {
    if (!state().winRate.currentList || state().winRate.currentList.length === 0) {
      return <NoData className='mt10 mb10'/>
    }
    let currentList = state().winRate.currentList
    const option: any = {
      xAxis: {
        type: 'category',
        data: currentList,
        axisLabel: '',
        axisTick: {
          inside: false,
          length: 0,
        }
      },
      yAxis: [{ // 纵轴标尺固定
        type: 'value',
        scale: false,
        max: 4,
        min: 0,
        splitNumber: 3,
        axisTick: {
          inside: false,
          length: 0,
        },
        axisLabel: {
          formatter: function (a: any) {
            if (~~a === 0) return '';
            if (~~a === 1) return '输';
            if (~~a === 2) return '走';
            if (~~a === 3) return '赢';
          }
        }
      }],
      series: [{
        data: currentList,
        type: 'line',
        itemStyle: {
          normal: {
            //   color: "pink",//折线点的颜色
            lineStyle: {
              color: "#b34038"//折线的颜色
            }
          }
        },
        symbol: 'circle',//折线点设置为实心点
        symbolSize: 8,   //设定实心点的大小
      }],
      visualMap: {
        show: false,
        dimension: 1,
        pieces: [
          { lte: 1, color: '#999' },
          { lte: 2, color: '#5cbf69' },
          { lte: 3, color: '#b34038' },
        ]
      },
      grid: {
        y: 10,
        y2: 20
      },
    }
    return <div>
      <ReactEchartsCore echarts={echarts} option={option} style={{ height: '100px' }} />
      <Flex align='center' justify='end'>
        <span style={{ fontSize: '11px', textAlign: 'right', marginRight: '100px' }}>{state().winRate.currentText || ''}</span>
        <span style={{ fontSize: '11px', textAlign: 'right', marginRight: '50px' }}>最新</span>
      </Flex>
    </div>
  }

  return (
    <div className='mt10' style={{marginBottom: '50px'}}>
      <Flex>
        {footer_tab_list.map(item => {
          return <div
            onClick={() => setState({ footer_tab_id: item.id })}
            key={item.id}
            className={`${styles.footer_tab} ${state().footer_tab_id === item.id ? styles.select_tab : ''}`}>
            <span className={`${styles.footer_tab_text} ${state().footer_tab_id === item.id ? styles.select_tab_text : ''}`}>{item.name}</span>
          </div>
        })}
      </Flex>
      {state().footer_tab_id === 0 ? comment() : null}
      {state().footer_tab_id === 1 ? footerReward() : null}
      {state().footer_tab_id === 2 ? lineCharts() : null}
      <footer className={styles.footer}>
        <input
          value={text}
          placeholder="请输入评论"
          onChange={e => {
            e.persist();
            setText(e.target.value)
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => { setTimeout(() => setFocus(false), 500); util.kickBack(); }}
          type="text"></input>
        <Button onClick={() => { addComment() }} className={styles.button} type="ghost" inline size="small" >回复</Button>
        {/* {focus && <Button onClick={() => { addComment() }} className={styles.button} type="ghost" inline size="small" >回复</Button>} */}
        {/* {!focus && <img onClick={() => { likes() }} src="/images/article_foot_ico_fabulous@2x.png" alt="" />} */}
      </footer>
    </div>
  )
}

export default Footer;
