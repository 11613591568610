import React from 'react';
import { Header, NoData } from '@component/index'
import { ConnectProps } from '@/react-app-env';
import { Calendar, Popover, PullToRefresh, Flex } from 'antd-mobile';
import moment from 'moment';
import { nightmareBifen } from '@/services/scoreServices';
import { useMount, useGetSetState, useDeepCompareEffect } from 'react-use';
import ScoreListCell from '@/component/Score/ScoreListCell';
import io from 'socket.io-client';
import { MatchStatus } from '@/model/constant';
import { appModel } from '@/model/app';

const Item: any = Popover.Item;

const menus = [{ name: '全部', id: -1 }, { name: '进行中', id: 1 }, { name: '已结束', id: 3 }, { name: '未开始', id: 0 }, { name: '已取消', id: 2 }];

const ScorePage = (props: ConnectProps) => {
  const [model, setModel] = appModel();
  const [state, setState] = useGetSetState({
    status: -1,
    date: '',
    Calendar: false,
    Popover: false,
    list: [],
    refreshing: false
  });
  const options = { Calendar: !state().Calendar };

  useMount(async () => {
    getList();
    const socket = io(process.env.REACT_APP_API_HOST as string, {
      path: '/ws',
      transports: ['websocket']
    });
    socket.on('connect', () => {
      console.log('连接状态');
    })
    socket.on('nightmare-update', (res: any) => {
      updateData(res)
    });
  });

  useDeepCompareEffect(() => {
    setModel({...model, showTabBar: options.Calendar})
  }, [options])

  const getList = async () => {
    const result: any = await nightmareBifen(state().status, state().date);
    if (result != null) {
      setState({
        list: result,
        refreshing: false
      });
    }
  };

  const updateData = (res: any) => {
    let list = state().list;
    for (let i = 0; i < list.length; i++) {
      let item_i: any = list[i];
      for (let j = 0; j < res.length; j++) {
        let item_j = res[j];
        if (item_i._id === item_j._id) {
          item_i = Object.assign(item_i, item_j)
        }
      }
    }
    setState({ list })
  };

  const leftContent = <Flex align='center' onClick={() => setState({ Calendar: true })}>
    <span className='font12 w600'>{state().date || '选择日期'}</span>
    <i className="iconfont iconcaret-down font14 ml5"></i>
  </Flex>;

  const rightContent = <Popover mask
    visible={state().Popover}
    placement='bottom'
    overlay={
      menus.map((item: any) => {
        return <Item className='font12' style={{ width: '80px' }} key={item.id} id={item.id}>{item.name}</Item>
      })
    }
    onSelect={data => {
      setState({ status: data.props.id });
      getList();
    }}
  >
    <div style={styles.rightButton}>
      <span className='font12 w600'>{MatchStatus[state().status]}</span>
      <i className="iconfont iconcaret-down font14 y-1 ml2 mt2"></i>
    </div>
  </Popover>;

  return (
    <div className='page bg_f8'>
      {!state().Calendar && <Header
        title='比分'
        leftContent={leftContent}
        rightContent={rightContent} />}
      <PullToRefresh
        getScrollContainer={() => {
          return ''
        }}
        distanceToRefresh={50}
        damping={100}
        style={{
          height: document.documentElement.clientHeight - 95,
          overflowY: 'auto',
        }}
        indicator={{}}
        direction={'down'}
        refreshing={state().refreshing}
        onRefresh={() => {
          setState({ refreshing: true });
          getList();
        }}
      >
        <div className='bg-f8'>
          {state().list.map((item: { _id: string }) => {
            return <ScoreListCell key={item._id} data={item} />
          })}
          {state().list.length === 0 && <NoData className='mt10 bg-white' />}
        </div>
      </PullToRefresh>
      <Calendar
        visible={state().Calendar}
        onCancel={() => setState({ Calendar: false })}
        onConfirm={(e) => {
          setState({ Calendar: false, date: moment(e).format('YYYY-MM-DD') });
          getList();
        }}
        type='one'
        // showShortcut={true}
        rowSize='xl'
      />
    </div>
  );
};

const styles = {
  main: {
    background: '#f8f8f8'
  },
  rightButton: {
    borderRadius: '20px',
    backgroundColor: '#fff',
    padding: '1px 7px',
    display: 'flex',
    alignItems: 'center',
    color: '#ec5852'
  },
};

export default ScorePage;