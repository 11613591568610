import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './router/AppRouter';
import * as serviceWorker from './serviceWorker';
import { Toast } from 'antd-mobile';
import moment from 'moment';
// import VConsole from 'vconsole';

// new VConsole();
moment.updateLocale('zh-cn', require('moment/locale/zh-cn'));
// moment.locale('zh-cn', require('moment/locale/zh-cn'));
Toast.config({ mask: false, duration: 1.5 });

ReactDOM.render(
    <AppRouter />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
